import React, { useEffect } from "react";
import useState from 'react-usestateref';
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import SignaturePad from "components/signaturePad";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsWindow from "generic-components/BuzopsWindow";
import moment from "moment";
import { Prompt, useHistory } from "react-router-dom";
import { ClassService } from "services/class/index.service";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import { Error } from "@progress/kendo-react-labels";
import {
  calculatePackageRecurrenceDate,
  checkHasTaxEnable,
  CheckUserMemberStatus,
  CustomToFixed,
  getLongDate,
} from "utils";
import images from "utils/images";
import { GetLocalStore } from "utils/storage";
import OrderSummary from "./OrderSummary";
import PaymentPanelForm from "./PaymentPanelForm";
import { CurrencyFormat } from "utils";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CouponDurationTypeEnum, CreditsErrorMessage, PackageFrequency, PackageFrequencyEnum } from "utils/form-utils";
import { useDispatch, useSelector } from 'react-redux'
import { PaymentMethodSelected, DownPaymentEntered } from '../../redux-slices/genericSlice'
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { filterBy } from "@progress/kendo-data-query";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import TermsAndConditions from "./TermsAndConditions";
import { defaultPaymentMethod } from "utils/constants";

export const addDates = (date: any, OcurrsEvery: number, unit: string) => {
  if (unit === "Daily") {
    let myDate = new Date(date);
    myDate.setDate(myDate.getDate() + OcurrsEvery);
    return myDate;
  } else {
    let myDate = new Date(date);
    myDate.setMonth(myDate.getMonth() + OcurrsEvery);
    return myDate;
  }
};
const initialCustomDialogVal={
  showDialog: false,
  Rate: 0,
}
export const initialAdditionalPayment={
  dialog:false,
  selectedPaymentProfile:null
}

const Checkout = (props: any) => {
  const ComplimentaryPermission=CheckPermissions(PermissionEnum["ComplimentaryCheckout"])
  const redirectionObj = useSelector((state:any)=>state?.redirection)
  const history = useHistory();
  let staffPage = false;
  if (props?.location?.state?.staffPage || props?.staffPage) {
    staffPage = true;
  }
  let checkoutType = props.page
    ? props.page === "package"
      ? "inhouse-package"
      : "inhouse-class"
    : props?.location?.state?.session?.length > 0
    ? "online-class"
    : props?.location?.state?.package
    ? "online-package"
    : "service";
  const providerId = props.match?.params?.providerId;

  const [guestLayout, setGuestLayout] = useState<boolean>(
    providerId ? true : false
  );
  const sessionItem = props.page
    ? props.session
      ? props.session
      : []
    : props?.location?.state?.session?.length > 0
    ? props?.location?.state?.session?.length > 0
      ? props?.location?.state?.session
      : []
    : [];
  const packageItem = props.page
    ? props.package
      ? props.package
      : null
    : props?.location?.state?.package
    ? props?.location?.state?.package
    : null;

  const dispatch = useDispatch()
  const [submit, setSubmit] = useState(false);
  const [submittedClassSessions, setSubmittedClassSessions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [packageListToUse, setPackageListToUse] = useState([]);
  const [groupSelectedDates, setGroupSelectedDates] = useState([]);
  const [PackagesData, setPackagesData] = useState<any>([]);
  const [Taxes, setTaxes,TaxesRef] = useState<any>([]);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [session, setSession] = useState(sessionItem);
  const minimumDate = new Date(moment().add(1, "day").format("L"));

  const [packag, setPackage] = useState<any>(null);

  const [packageReccurence, setPackageReccurence] = useState(true);
  const [totalClassSessions, setTotalClassSessions] = useState(0);
  const paymentGatewaytype = GetLocalStore("Configuration")?.PaymentGatewayType;
  const [paymentGatewayType, setPaymentGatewayType] =
    useState<any>(paymentGatewaytype);

  // to get the logged client details
  const userDetails = GetLocalStore("userDetails");
  // console.log(userDetails)

  //to view  signature popup
  const [visibleSignPad, setVisibleSignPad] = useState(false);

  const [confirmClientDialog, setConfirmClientDialog] = useState(false);
  const [purchaseCheckout, setPurchaseCheckout] = useState<any>({
    page:'',
    params:null
  });

  const [disable, setDisable] = useState(false);

  const [checkoutService, setCheckoutService] = useState<any>({
    showDialog: false,
    service: "",
  });
  const [CustomRateDialog, setCustomRateDialog] = useState<any>(initialCustomDialogVal);
  const [checkoutPackage, setCheckoutPackage] = useState<any>({
    showDialog: false,
    package: "",
  });

  //base64 encode signature
  const [signature, setSignature] = useState("");

  const [signatureText, setSignatureText] = useState<any>("");
  const [signatureFont, setSignatureFont] = useState<any>("");
  const [signatureType, setSignatureType] = useState<any>("");

  //dirty Check
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [IsTaxSkipped, setIsTaxSkipped] = useState<boolean>(true);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] = useState<number>(0);

  //classes terms and conditions
  const [termsandConditions, setTermsandConditions] = useState<boolean>(true);
  const [termsandConditionsDialog, setTermsandConditionsDialog] =
    useState<boolean>(false);

  //to handle agreement window
  const [visibleAgreement, setVisibleAgreement] = useState(false);

  //for buzops button loading for paynow and build agreement
  const [btnLoading, setBtnLoading] = useState(false);

  //for buzops button loading for coupon
  const [couponBtnLoading, setCouponBtnLoading] = useState(false);

  //for buzops button loading for card submit
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  //for buzops button loading for client create submit

  const [clientSubmitBtnLoading, setClientSubmitBtnLoading] = useState(false);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);

  //for buzops button loading for bank submit
  const [bankSubmitBtnLoading, setBankSubmitBtnLoading] = useState(false);
  const [customRate, setCustomRate] = useState(0);

  //to auto populate latest submitted payment profile
  const [populateProfile, setPopulateProfile] = useState<any>(null);
  let clientMemberId;
  if (
    checkoutType === "service"  ||
    checkoutType === "online-package"
  ) {
    clientMemberId =
      userDetails?.UserSession?.UserMemberId ||
      props?.location?.state?.userData?.UserMemberId;
  } else if(checkoutType === "online-class"){
    clientMemberId =props?.location?.state?.userData?.UserMemberId || userDetails?.UserSession?.UserMemberId
  }else if (props?.UserMemberId) {
    clientMemberId = props?.UserMemberId;
  }
  let userSessionUserId: any;
  if (checkoutType === "inhouse-package" || staffPage || props?.location?.state?.from ==="calender") {
    const tenantUserSession = GetLocalStore("tenantUserSession");
    userSessionUserId = tenantUserSession?.UserId;
  }

  // to get User Member Id
  const [userMemberId, setUserMemberId] = useState<any>(
    clientMemberId || undefined
  );

  //visible coupon fields
  const [visibleCouponField, setvisibleCouponField] = useState(false);

  // to set build agreement
  const [buildagreement, setBuildagreement] = useState({});

  // to get agreement template
  const [agreementTemplate, setAgreementTemplate] = useState("");

  // to show installment form
  const [showinstallmentForm, setShowinstallmentForm] = useState(false);

  // to Recurring Amount
  const [recurringAmount, setRecurringAmount] = useState(0);
  const [showAdditionalPayment, setShowAdditionalPayment] = useState<any>(initialAdditionalPayment);
  // to show payment profile type
  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod);
  // to select clientList AutoComplete
  const [clientsList, setClientsList] = useState<any>([]);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  // to select client Dropdown
  const [selectedClient, setSelectedClient] = useState<any>({
    type: "search",
    value: "",
  });

  // to expand and collpase panel bar items
  let panelbarItem = "4";
  if (checkoutType === "service" || checkoutType === "online-class" || checkoutType ==="online-package") {
    panelbarItem = "2";
  }
  const [selected, setSelected] = useState<any>(panelbarItem);

  //selected payment profile
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>({
    selectedPayment: undefined,
    AlternatePaymentProfileId:null
  });

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // entered coupon text
  const [coupon, setCoupon] = useState("");

  //captured coupon details
  const [couponDetails, setCouponDetails] = useState<any>({});

  const [appliedCoupons, setAppliedCoupons] = useState<any>([]);

  //selected coupon amount
  const [couponAmount, setCouponAmount] = useState(0);

  //selected sales person
  const [selectedSalesPerson, setSelectedSalesPerson] =
    useState<any>(undefined);

  const [salesPersons, setSalesPersons] = useState<any>([]);

  //installment table data to show recurrences
  const [tableData, setTableData] = useState<any>([]);

  const [packageTableData, setPackageTableData] = useState<any>([]);

  // selected service details
  const [serviceData, setserviceData,serviceDataRef] = useState<any>({});
  const [origserviceData, setOrigserviceData] = useState<any>({});

  //package cost after discount
  const [packageCostAfterDiscount, setPackageCostAfterDiscount] = useState(0);
  const [useCreditAmount, setUseCreditAmount] = useState(0);
  const [useComplimentaryAmount, setUseComplimentaryAmount] = useState(0);
  const [payLaterAmount, setPayLaterAmount] = useState(0);

  //purcahse cost for individual  service
  const [packageCost, setPackageCost,packageCostRef] = useState(0);

  //saved payment profiles
  const [paymentProfile, setPaymentProfile] = useState([]);
  // down payment initial form values
  const  InstallmentDate = addDates(
    new Date(),
    1,
    "Months"
  );

  const downPaymentInitialValues = {
    paidToday: 0,
    recurrenceUnit: { id: 4, text: "Months" },
    recurringStartDate: new Date(InstallmentDate),
    OcurrsEvery: 1,
    installments: 1,
  };

  const [downpaymentForm, setDownpaymentForm,downpaymentFormRef] = useState(
    downPaymentInitialValues
  );

  //  payment profile card initial form values
  const cardIntialValues = {
    MaskedCCNumber: undefined,
    CardTypeId: undefined,
    ExpiryMonthYear: undefined,
    ExpiryMonth: undefined,
    ExpiryYear: undefined,
    ZipCode: undefined,
    PaymentType: "Card",
    UserMemberId: userMemberId,
  };
  const [cardFormValues, setCardFormValues] = useState<any>(cardIntialValues);

  // credit cards image placeholder
  const [cardImageIcon, setcardImageIcon] = useState(images.placeholder);
  const [coupondata, setCouponData] = useState<any>([]);
  const [filterCoupondata, setfilterCouponData] = useState<any>([]);

  //  payment profile bank initial form values
  const bankIntialValues = {
    AccountName: undefined,
    BankAccountType: undefined,
    BankName: undefined,
    RoutingNumber: undefined,
    AccountNumber: undefined,
    UserMemberId: userMemberId,
    PaymentType: "Bank",
  };
  const [bankFormValues, setBankFormValues] = useState<any>(bankIntialValues);

  //client details
  const [clientFormValues, setClientFormValues] = useState<any>({});

  //To client details to submit
  const [clientSubmitDetails, setClientSubmitDetails] = useState<any>({});
  const tenantService = new TenantService();
  //payment profiles to submit
  const [paymentSubmitProfile, setPaymentSubmitProfile] = useState<any>({});
  useEffect(() => {
    if (checkoutType === "service") {
      const userDetails = GetLocalStore("userDetails");
      if (!userDetails) {
        handleHomeRedirection()
      }
    }
  }, [GetLocalStore("tenant")]);

  // to get all initial data while loading
  useEffect(() => {
    fetchAllApis()
  }, [props?.location]);

  useEffect(() => {
    if(groupSelectedDates?.length > 0){
      if (checkoutType === "inhouse-class") {
        fetchPackagesToUse(userMemberId);
      }
    }
  }, [userMemberId,groupSelectedDates])
  
  const handleSummaryRedirection=(obj:any={})=>{
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj
    if (firstPathValue === 'member') {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}`, obj);
    } else if(firstPathValue==="external") {
      history.push(`/${firstPathValue}/summary`, obj);
    }else{
      history.push(`/summary`, obj);
    }
  }
  const handleHomeRedirection=(obj:any={})=>{
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj
    if (firstPathValue === 'member') {
      history.push(`/${firstPathValue}/${staffuuid}/${clientMemberId}`, obj);
    } else if (firstPathValue === 'external') {
      history.push(`/${firstPathValue}`, obj);
    } else {
      history.push(`/home`, obj);
    }
  }

  
  const handleChangeCustomServiceRate = (val:any,name:string) => {
    setCustomRateDialog({...CustomRateDialog,[name]:val})
  }
  const handleShowCustomRateDialog = (val:any,name:string) => {
    setCustomRateDialog({Rate:customRate,showDialog:true})
  }

  const handleTaxCalculation=(purchaseAmt:number,TaxItems=Taxes,Prediction=false)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
    if(Prediction){
      setPredictedTaxes(TaxesArray)
      setPredictedTotalTaxAmount(taxAmt)
    }else{
      setTaxes(TaxesArray)
      setTotalTaxAmount(taxAmt)
    }
  }
  const constructTaxArray=(taxableAmt:any,TaxItems=TaxesRef?.current)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=taxableAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount;

    }, 0);

    return {taxAmt,TaxesArray}
  }
  const handleSessionTaxCalculation=(sessionData=session,TaxItems=Taxes,updatedPkListToUse=packageListToUse)=>{
    const updatedSessionData=sessionData.map((i:any)=>{
      if(i?.IsTaxSkipped || !i?.CanCollectTaxes){
        const itemValues=i?.values?.map((j:any)=>{
          return {...j,TotalTaxAmount:0,Taxes:[],TotalTaxableAmount:0}

        })
        return {...i,values:itemValues,TotalTaxAmount:0,Taxes:[],TotalTaxableAmount:0}
      }else{
        let classTaxableAmt=0
        let predictedclassTaxableAmt=0
        const itemValues=i?.values?.map((j:any)=>{
          let taxableAmt=j?.Cost
          let predictedTaxableAmt=j?.Cost
          const checkUseCreditVal = checkUseCredit(j,null,updatedPkListToUse);
          const checkComplimentary =j?.Complimentary
          if (checkUseCreditVal || checkComplimentary) {
            taxableAmt= 0;
            predictedTaxableAmt= 0;
          } else if (
            j?.PaymentOption === 2 ||
            j?.PaymentOption === 3  || j?.CanBookAsWaitlist
          ) {
            taxableAmt= 0;
            predictedTaxableAmt= j?.Cost;
          } else {
            taxableAmt= j?.Cost;
            predictedTaxableAmt= j?.Cost;
          }
   
          console.log(predictedTaxableAmt,'predictedTaxableAmt')
          console.log(taxableAmt,'taxableAmt')
          const taxableItem = constructTaxArray(taxableAmt)
          const predictedTaxableItem = constructTaxArray(predictedTaxableAmt)
          classTaxableAmt+=taxableAmt
          predictedclassTaxableAmt+=predictedTaxableAmt
          console.log(taxableItem,'taxableItem')
          console.log(predictedTaxableItem,'predictedTaxableItem')
          return {...j,TotalTaxAmount:taxableItem?.taxAmt,Taxes:taxableItem?.TaxesArray,TotalTaxableAmount:taxableAmt,PredictedTotalTaxAmount:predictedTaxableItem?.taxAmt,PredictedTaxes:predictedTaxableItem?.TaxesArray,PredictedTotalTaxableAmount:predictedTaxableAmt}
        })
        const ClassTaxableItem = constructTaxArray(classTaxableAmt)
        const PredictedClassTaxableItem = constructTaxArray(predictedclassTaxableAmt)
        return {...i,values:itemValues,TotalTaxAmount:ClassTaxableItem?.taxAmt,Taxes:ClassTaxableItem?.TaxesArray,TotalTaxableAmount:classTaxableAmt,PredictedTotalTaxAmount:PredictedClassTaxableItem?.taxAmt,PredictedTaxes:PredictedClassTaxableItem?.TaxesArray,PredictedTotalTaxableAmount:predictedclassTaxableAmt}
      }
    })
    console.log(updatedSessionData,'updatedSessionData')
    setSession(updatedSessionData)
  }

  const calculateTotalSessionTax = (prediction=false) => {
    const taxAmt = session?.reduce((acc: number, currentValue: any) => {
      console.log(currentValue,'currentValue')
      if(currentValue?.IsTaxSkipped){
        return acc
      }
      if(!prediction){
        return acc + CustomToFixed(currentValue?.TotalTaxAmount,2);

      }else{
        return acc + CustomToFixed(currentValue?.PredictedTotalTaxAmount,2);
      }
    }, 0);
    console.log(taxAmt,'taxAmt')
    return taxAmt
  }

  const getAllSubMembers = async (memberId: any) => {
    let selectedUserMemberId=memberId
    let parentMemberId=memberId
    if(props?.location?.state?.userData?.PrimaryMemberId){
      parentMemberId=props?.location?.state?.userData?.PrimaryMemberId
    }
    const res = await tenantService.GetSubMembersWithParent(parentMemberId);
    setAllClientsList(res);
    if(!staffPage && userDetails && !sessionItem){
      selectedUserMemberId=parseInt(userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId || memberId)
    }
    const selUserData = getCurrentClientData(res, selectedUserMemberId)
    updateSelectedClientData(selUserData)
    return selUserData?.ParentId || selUserData?.UserMemberId
  }
  const fetchAllApis = async () => {
    let packItem=packag
    if(checkoutType==="inhouse-package" || checkoutType==="online-package"){

      let ExpiryDate=minimumDate
      let ExpireValue=0
      if(packageItem?.ExpireOn=== 8){
        ExpireValue=8
        ExpiryDate=packageItem?.ExpiryDate
      }
      packItem={...packageItem,
        StartDate:new Date(),
        Expire: ExpireValue,
        ExpiryDate: ExpiryDate,
        ClientCanSkipPayment:false,
        SignupDate:new Date(),
        BillingType:packageItem?.Frequency === PackageFrequencyEnum.MONTHLY && packageItem?.RepeatFrequency===1 ? packageItem?.CanProrate ? 3 : 2 : 2,
        BillingDay: packageItem?.BillingDay ? packageItem?.BillingDay:1,  
        ProratedSchedules:packageItem?.Schedules,
        ProratedCost:0,
        DiscountedCost:packageItem?.Cost,
        OptedSchedules:packageItem?.Schedules,
        OptedMaxRollOverSchedules:packageItem?.MaxRollOverSchedules,
      }
    }
    setLoading(true);
    let memberId = userMemberId;
    let primaryMemberId = userMemberId;
    if(memberId){
      const res = await getAllSubMembers(memberId);
      primaryMemberId=res
    }
    if (staffPage) {
      await fetchSalesPerson();
    }
    if (checkoutType === "service") {
      const data = await fetchServiceData(true);
      if(data?.CanCollectTaxes && checkHasTaxEnable()){
        const result=await fetchTaxes()
        setIsTaxSkipped(false)
        handleTaxCalculation(data?.DownPayment,result)
        handleTaxCalculation(data?.packageCostAfterDiscount,result,true)
      }
      await fetchPaymentProfiles(primaryMemberId);
      if(staffPage){
        await fetchCoupons(primaryMemberId,'service',data?.PrivateServiceId);
      }else{
        if(data?.CouponDescription){
          await handlePromotionCoupon(data?.CouponDescription)
        }
      }
    } else if (checkoutType === "online-class") {
      const result=await fetchTaxes()
      await fetchClassData(session,true,result);
      if(props?.location?.state?.from !=="calender"){
        await fetchPaymentProfiles(primaryMemberId);
      }
    } else if (checkoutType === "inhouse-package") {
      if (memberId) {
        await fetchPaymentProfiles(primaryMemberId);
      }
      await fetchSalesPerson();
      const taxData=await fetchTaxes()
      await fetchPackageData(taxData,packItem);
    } else if (checkoutType === "online-package") {
      if(props?.location?.state?.from !=="calender"){
        await fetchPaymentProfiles(primaryMemberId);
      }
      const taxData=await fetchTaxes()
      if(packag?.CouponDescription){
        await handlePromotionCoupon(packag?.CouponDescription,taxData)
      }else{
        await fetchPackageData(taxData,packItem);
      }
      if(staffPage){
        await fetchCoupons(primaryMemberId,'package',packag?.PackageId);
      }
    } else {
      const result=await fetchTaxes()
      await fetchClassData(session,true,result);
      if(!userMemberId){
        setSelected("1")
      }
      if (primaryMemberId) {
        await fetchPaymentProfiles(primaryMemberId);
      }
    }
    setLoading(false);
  };


  const fetchPackagesToUse = async (memberId: any,dates=groupSelectedDates,sessionItem=session) => {

    const startDate = moment(dates[0]).format("YYYY-MM-DD");
  
    const FromDate = moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
    const ToDate = moment(dates[dates?.length - 1]).format("YYYY-MM-DD") + "T23:59:59";

    const IsPastDate = moment(FromDate).isBefore(moment(), 'day');
    const req = {
      UserMemberId: memberId,
      FromDate,
      ToDate,
      PackageType:"Class",
      IsPastDate,
    };

    const service = new PackageService();
    const result = await service.packageInstanceToUse(req);

    if(result?.length > 0){
      const resultItem = result?.map((j: any) => {
        let PackageExpireOn=j?.PackageExpireOn
        if(PackageExpireOn){
          PackageExpireOn = moment(PackageExpireOn, "MM/DD/YYYY hh:mm:ss A").subtract(1, 'days').format("MM/DD/YYYY hh:mm:ss A");
        }
        return { ...j,PackageExpireOn };
      });

      const newArray = result?.map((j: any) => {
        let PackageExpireOn=j?.PackageExpireOn
        if(PackageExpireOn){
          PackageExpireOn = moment(PackageExpireOn, "MM/DD/YYYY hh:mm:ss A").subtract(1, 'days').format("MM/DD/YYYY hh:mm:ss A");
        }
        return { ...j, ClassSessions: [],PackageExpireOn };
      });
  
      const newSessionArray = sessionItem?.map((item: any, index: number) => {
        const SessionCount = item.values.length;
        let IsTaxSkipped=true
        const modifiedValues=item?.values.map((j:any)=>{
          
          
          const PackagesList = result.filter((i: any) => {
            const billingPeriod = findBillingPeriod(startDate, i.BillingPeriods);
            const IsPastDate = moment(startDate).isBefore(moment(), 'day');

            const { ExpireFrom, ExpireOn } = IsPastDate && billingPeriod !== undefined
              ? billingPeriod
              : i;
            if ((i.ClassIds.includes(j?.ClassId) || i.ClassIds.includes(-1)) && moment(j?.ClassSessionStartDate).format('YYYY-MM-DD') >= moment(ExpireFrom).format('YYYY-MM-DD') && moment(j?.ClassSessionStartDate).format('YYYY-MM-DD') <= moment(ExpireOn).format('YYYY-MM-DD') && j?.Cost > 0) {
              return true;
            }
          });
          IsTaxSkipped=true
          if(j?.CanCollectTaxes && checkHasTaxEnable()){
            IsTaxSkipped=false
          }
          
          return {...j,SelectedPackage:j?.SelectedPackage ? j?.SelectedPackage : null,PackagesList,Complimentary:false,IsTaxSkipped}
        })
        return { ...item, SessionCount, values:modifiedValues,IsTaxSkipped};
      });
      handleSessionTaxCalculation(newSessionArray)
      setPackageListToUse(newArray);
 
      setPackagesData(resultItem || []);
      if(checkoutType === "inhouse-class" && newArray?.length >0){
        await handleAutoselectPackage(newSessionArray,newArray)
      }
    }else{
      const newSessionArray = sessionItem?.map((item: any, index: number) => {
        const SessionCount = item.values.length;
        let IsTaxSkipped=true
        const modifiedValues=item?.values.map((j:any)=>{
          const PackagesList:any = []
          IsTaxSkipped=true
          if(j?.CanCollectTaxes && checkHasTaxEnable()){
            IsTaxSkipped=false
          }
          
          return {...j,SelectedPackage: null,PackagesList,Complimentary:false,IsTaxSkipped}
        })
        return { ...item, SessionCount, values:modifiedValues,IsTaxSkipped};
      });
      handleSessionTaxCalculation(newSessionArray)
      setPackageListToUse([]);
      setPackagesData([]);
    }
  };

  const handleIsSessionTaxSkipped=(value:boolean,dataItem:any)=>{
    const updatedSessionData=session.map((i:any)=>{
      if(i?.ClassId===dataItem?.ClassId){
        return {...i,IsTaxSkipped:value}
      }
      return {...i}
    })
    handleSessionTaxCalculation(updatedSessionData)
  }
  const fetchSalesPerson = async () => {
    const service = new TenantService();
    const includeAffiliates=staffPage ? false : true
    const res = await service.SalesPerson(includeAffiliates);
    setSalesPersons(res || []);
    if (userSessionUserId) {
      const salesPersonRecord = res?.find(
        (item: any) => item?.UserId === userSessionUserId
      );
      if (salesPersonRecord) {
        setSelectedSalesPerson(salesPersonRecord);
      }
    }
  };

  // after submission of card or bank need to update payment profile with latest one
  useEffect(() => {
    const { selectedPayment } = selectedPaymentProfile;
    const { bank, card } = paymentMethod;

    if (
      populateProfile &&
      !selectedPayment &&
      paymentProfile &&
      !bank &&
      !card && paymentProfile?.length > 0
    ) {
      const index = paymentProfile.findIndex((i:any)=> i?.PaymentGatewayPaymentProfileID === populateProfile?.PaymentGatewayPaymentProfileID)
      if (index !== -1) {
        const selectPayment: any = paymentProfile[index];
        setSelectedPaymentProfile({
          ...selectedPaymentProfile,
          selectedPayment: selectPayment,
        });
        generatePaymentProfileObj(selectPayment);
      }
    }
  }, [paymentProfile]);

  // to handle Apply Coupon text and button hide and show
  const handleVisibleCouponField = () => {
    setvisibleCouponField(!visibleCouponField);
  };

  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };

  // handle client selection for class
  const handleClientSelecton = (e: any) => {
    if(e.value.isButton){
      return;
    }

    if(e.value === ""){
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
      updateSelectedClientData(e.value);
      //setClientpopup(false);
      return;
    }
    if (e.syntheticEvent?.key === "Enter") {
      if (clientsList?.length > 0) {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: clientsList[0],
        });
      }
      return true;
    }
    setSelectedClient({
      ...selectedClient,
      type: "search",
      value: e.value,
    });
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      autoCompleteClients(e.value);
    }
    if (e.syntheticEvent?.type === "click") {
      handleChangeClient()
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
      updateSelectedClientData(e.value);
      if (selected === "0") {
        setSelected(undefined);
      } else {
        setSelected("2");
      }
    }
  };
  async function autoCompleteClients(str: string) {
    const req = {
      Where: {
        SearchKeyword: str,
        IsGloablSearch: true
      },
    };
    setClientSearchLoading(true);
    const clientservice = new ClassService();
    const result = await clientservice.autoCompleteClients(req);
    setClientSearchLoading(false);
    setClientsList(result);
  }

  // to fetch selected service to checkout
  async function fetchServiceData(initial=false) {
    let serData:any=null;
    let req: any;
    if (guestLayout) {
      req = {
        PrivateServiceInternalId:
          props.location.state.selectedService.InternalDataId,
      };
    } else {
      if (!props.match.params?.id) {
        if(redirectionObj?.firstPathValue !==""){
          if(redirectionObj?.firstPathValue ==="external"){
            history.push(`/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`);
          }else{
            history.push(`/${redirectionObj?.firstPathValue}`);
          }
        }else{
          history.push(`/`);
        }
        return true;
      }
      req = {
        PrivateServiceInternalId: props.match.params?.id,
      };
    }
    if (
      props?.location?.state?.staffPage &&
      props?.location?.state?.customService
    ) {
      
      const data = props?.location?.state?.customService;
      let RateOfAppt= data?.Rate
      if(!initial){
        RateOfAppt= customRate
      }
      const totalCost = data.NumberOfSessions * RateOfAppt;
      const serviceDetails = {
        PrivateServiceId: null,
        NewService: true,
        PrivateServiceType: 1,
        ServiceRateId: data?.ServiceRateId,
        Name: data?.Name,
        Description: "",
        NumberOfSessions: data?.NumberOfSessions,
        ShortCode: data?.ShortCode,
        Rate: RateOfAppt,
        RateAfterDiscount: RateOfAppt,
        Duration: data?.DurationInMinutes,
        IsUseWithoutPurchase: false,
        PayByRecurring: true,
        CanRecurrenceOverride: true,
        DownPayment: totalCost,
        OcurrsEvery: 0,
        RepeatFrequency: 0,
        NoOfInstallments: 0,
        CanClientOverrideRenewalOption: false,
        RenewalType: 0,
        CanRenew: false,
        CanCollectTaxes: checkHasTaxEnable(),
        packageCostAfterDiscount:totalCost
      };
      setserviceData(serviceDetails);
      setOrigserviceData(serviceDetails);
      const  InstallmentDate = addDates(
        new Date(),
        1,
        "Months"
      );
      const recurringStartDate= new Date(InstallmentDate)
      setPackageCostAfterDiscount(totalCost);
      setPackageCost(totalCost);
      setDownpaymentForm({
        ...downpaymentForm,
        paidToday: totalCost,
        recurringStartDate: new Date(recurringStartDate),
      });
      
      serData=serviceDetails
      if(initial){
        setCustomRate(data.Rate)
      }
    } else {
      const service = new TenantService();

      const servicedet = await service.getServiceDetails(req);
      const result = servicedet.Result;

      if (result.length > 0) {
        const data = result[0];

        const totalCost = data.NumberOfSessions * data.Rate;
        let renewTypeValue = data?.RenewalType
        if (renewTypeValue !== 0) {
          if (data?.PayByRecurring) {
            renewTypeValue = data?.RenewalType
          } else {
            renewTypeValue = 2
          }
        }
        const serviceDetails = {
          PrivateServiceId: data?.PrivateServiceId,
          CanCollectTaxes: data?.CanCollectTaxes,
          PrivateServiceType: data?.PrivateServiceType,
          ServiceRateId: 0,
          ClubId: result?.ClubId,
          Name: data?.Name,
          Description: "",
          NumberOfSessions: data?.NumberOfSessions,
          ShortCode: data?.ShortCode,
          Rate: data?.Rate,
          RateAfterDiscount: data?.Rate,
          Duration: data?.DurationInMinutes,
          IsUseWithoutPurchase: false,
          PayByRecurring: data?.PayByRecurring,
          CanRecurrenceOverride: data?.CanRecurrenceOverride,
          DownPayment:
            data?.DownPayment > totalCost ? totalCost : data?.DownPayment,
          Color: data?.Color,
          OcurrsEvery: "0",
          RepeatFrequency: "0",
          NoOfInstallments: "0",
          CanClientOverrideRenewalOption: data?.CanClientOverrideRenewalOption,
          RenewalType: renewTypeValue,
          CanRenew: data?.RenewalType !== 0 ? true : false,
          packageCostAfterDiscount:totalCost,
          AffiliateId: props.location.state?.selectedService?.AffiliateId,
          AffiliateUserId: props.location.state?.selectedService?.AffiliateUserId,
          PromotionId:props.location.state?.selectedService?.PromotionId,
          CouponId: props.location.state?.selectedService?.CouponId,
          CouponDescription: props.location.state?.selectedService?.CouponDescription
        };
        setserviceData(serviceDetails);
        setOrigserviceData(data);
        setPackageCostAfterDiscount(totalCost);
        setPackageCost(totalCost);
        const tommorrow = moment().add(1, "days").format("L");
        if (data?.PayByRecurring) {
          let recurrenceUnitval = { id: 4, text: "Months" };
          if (data.RepeatFrequency === 2) {
            recurrenceUnitval = { id: 2, text: "Days" };
          }
          const  InstallmentDate = addDates(
            new Date(),
            data.OcurrsEvery,
            recurrenceUnitval?.text
          );
          setDownpaymentForm({
            ...downpaymentForm,
            paidToday:
              data?.DownPayment > totalCost ? totalCost : data?.DownPayment,
            recurrenceUnit: recurrenceUnitval,
            OcurrsEvery: data.OcurrsEvery,
            installments: data.NoOfInstallments,
            recurringStartDate: new Date(InstallmentDate),
          });
          serData=serviceDetails
        } else {
          setDownpaymentForm({
            ...downpaymentForm,
            paidToday: totalCost,
            recurringStartDate: new Date(tommorrow),
          });
          serData={...serviceDetails,DownPayment:totalCost}
        }
      } else {
        if(redirectionObj?.firstPathValue !==""){
          if(redirectionObj?.firstPathValue ==="external"){
            history.push(`/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`);
          }else{
            history.push(`/${redirectionObj?.firstPathValue}`);
          }
        }else{
          history.push(`/`);
        }
        return true;
      }
    }
    return serData
  }

  const calculateCost = (arrayItem: any, check: boolean,ignore=false) => {
    const cost = arrayItem?.reduce((acc: Number, currentValue: any) => {
      if (check) {
        return acc + currentValue?.Cost;
      } else {
        const checkUseCreditVal = checkUseCredit(currentValue);
        const checkComplimentary = currentValue?.Complimentary
        if (!ignore  && (checkUseCreditVal || checkComplimentary)) {
          return acc;
        } else if (
          currentValue?.PaymentOption === 2 ||
          currentValue?.PaymentOption === 3  || currentValue?.CanBookAsWaitlist
        ) {
          return acc;
        } else {
          return acc + currentValue?.Cost;
        }
      }
    }, 0);
    return cost;
  };
  const calculatePayLaterCost = (arrayItem: any,ignore=false) => {
    const cost = arrayItem?.reduce((acc: Number, currentValue: any) => {
      const checkComplimentary =currentValue?.Complimentary
      const checkUseCreditVal = checkUseCredit(currentValue)

      if (!ignore && (checkUseCreditVal || checkComplimentary)) {
        return acc;
      } else {
        if (
          currentValue?.PaymentOption === 2 ||
          currentValue?.PaymentOption === 3 || currentValue?.CanBookAsWaitlist
        ) {
          return acc + currentValue?.Cost;
        } else {
          return acc;
        }
      }
    }, 0);
    return cost;
  };
  const calculateSessionCount = (arrayItem: any) => {
    const cost = arrayItem?.reduce((acc: number, currentValue: any) => {
      return acc + 1;
    }, 0);
    return cost;
  };

  const handleRemoveSession = async (dataItem: any,) => {
    const classList = dataItem;
    let oldClassData = JSON.parse(JSON.stringify(session));
    const totalSession = oldClassData.reduce(
      (acc: Number, currentValue: any) => {
        return acc + calculateSessionCount(currentValue.values);
      },
      0
    );
    if (totalSession > 1) {
      unCheckRedeem(dataItem) // added to unredeem the package and below to remove session
      if (classList) {
        if (oldClassData.length > 0) {
          const classIndex = oldClassData.findIndex(
            (val1: any) => val1?.className == classList?.ClassName
          );
          if (classIndex !== -1) {
            const classValueIndex = oldClassData[classIndex].values.findIndex(
              (val2: any) => val2.ClassSessionId === classList.ClassSessionId
            );
            if (classValueIndex !== -1) {
              oldClassData[classIndex].values.splice(classValueIndex, 1);
              if (oldClassData[classIndex].values.length === 0) {
                oldClassData.splice(classIndex, 1);
              }
            }
          }
        }
      }
      handleSessionTaxCalculation(oldClassData)
      await fetchClassData(oldClassData,false,Taxes);
    } else {
      const errorMsg = "Minimum one appointment is required to purchase";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
  };

  // to fetch selected service to checkout
  const fetchClassData =async (session: any,initial=true,taxes=Taxes,memberId=userMemberId) => {
    let totalCost = 0;
    let allsessionCost = 0;
    let totalSessions = 0;
    let payLaterCost = 0;

    totalCost = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, true,initial);
    }, 0);
    allsessionCost = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, false,initial);
    }, 0);
    payLaterCost = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculatePayLaterCost(currentValue.values,initial);
    }, 0);
    totalSessions = session.reduce((acc: Number, currentValue: any) => {
      return acc + calculateSessionCount(currentValue.values);
    }, 0);

    const dates: any = []
    session.forEach((i: any) => {
      if (i?.values?.length > 0) {
        i?.values?.forEach((j: any) => {
          dates.push(moment(j?.ClassSessionStartDate).format('YYYY-MM-DD'))
        })
      }
    })

    setTotalClassSessions(totalSessions);
    setPackageCost(totalCost);
    setPayLaterAmount(payLaterCost);
    setPackageCostAfterDiscount(allsessionCost);
    setDownpaymentForm({ ...downpaymentForm, paidToday: allsessionCost });
    if(initial){
      setGroupSelectedDates(dates?.sort())
      setUseCreditAmount(0)
      setUseComplimentaryAmount(0)
      await fetchPackagesToUse(memberId,dates?.sort(),session)
    }
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setTaxes(res);
    return res
  };

  const fetchPackageData = async (taxData=Taxes,dataItem = packag, isNewCall = true,coupons=appliedCoupons) => {
    let pros=0
    let ProratedSchedules= dataItem?.ProratedSchedules || dataItem?.Schedules
    const currentDate = new Date(dataItem?.StartDate);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonthLastDate = new Date(
      currentYear,
      currentMonth,
      0
    ).getDate();
    let packCost=dataItem?.Cost
    if(staffPage){
      packCost=dataItem?.DiscountedCost
    }
    let proratedLimitedZeroDollar=false
    let canProrateValue=dataItem?.CanProrate
    if ((currentDate.getDate() === dataItem.BillingDay) || !dataItem.BillingDay) {
      pros = 0;
      canProrateValue=false
    }else if(dataItem?.BillingType === 3){
      if(dataItem?.SessionType===2){
        if(dataItem?.Frequency === PackageFrequencyEnum.MONTHLY && dataItem?.RepeatFrequency===1){
          let package_per_day_cost = 0
          let daysLeftInMonth = 0
          if(dataItem.BillingDay > currentDate.getDate()){
            const totalDaysInMonth = new Date(
              currentYear,
              currentMonth-1,
              0
            ).getDate();
            daysLeftInMonth= dataItem.BillingDay - currentDate.getDate()
            package_per_day_cost = packCost / totalDaysInMonth;
          }else{
            const totalDaysInMonth = new Date(
              currentYear,
              currentMonth,
              0
            ).getDate();
            daysLeftInMonth= (currentMonthLastDate - currentDate.getDate() + 1) + dataItem.BillingDay-1
            package_per_day_cost = packCost / totalDaysInMonth;
          }
          pros = daysLeftInMonth * package_per_day_cost;
        }
      }else{
        if(dataItem?.Frequency === PackageFrequencyEnum.MONTHLY && dataItem?.RepeatFrequency ===1){
          let totalSchedules=dataItem?.Schedules
          if(staffPage){
            totalSchedules=dataItem?.OptedSchedules
          }
          const singleApptCost=packCost / totalSchedules
          pros = singleApptCost*ProratedSchedules
          if(dataItem?.DiscountedCost===0){
            proratedLimitedZeroDollar=true
          }
        }
      }
    }
    pros=CustomToFixed(pros,2);
    if(pros > 0 || proratedLimitedZeroDollar){
      canProrateValue=true
    }else{
      canProrateValue=false
    }
    if(!staffPage && !dataItem?.CanProrate){
      canProrateValue=false
    }
    if(dataItem?.CanCollectTaxes && checkHasTaxEnable() && isNewCall){
      setIsTaxSkipped(false)
    }
    const today = moment(dataItem?.StartDate).format("L");
    const mainDate=dataItem?.BillingType ===3 ? moment(dataItem?.StartDate).date(dataItem?.BillingDay).format("L")  : today
    let NextRecurringDate = null;
    if (dataItem?.Frequency !== PackageFrequencyEnum.ONETIME) {
      const item = PackageFrequency?.filter((i: any) => i?.id === dataItem?.Frequency)?.[0]
      const repeatfreqValue = item?.shortValue
      let nextMonth = calculatePackageRecurrenceDate(mainDate, dataItem?.RepeatFrequency, repeatfreqValue);
      if(moment(dataItem?.StartDate).date() < dataItem?.BillingDay){
        nextMonth=mainDate
      }
      NextRecurringDate=nextMonth
    }
    const packageItemVal={...dataItem,ProratedCost:pros,IsProrated:canProrateValue,NextRecurringDate: NextRecurringDate}
    handlePackageCoupon(coupons,packageItemVal,taxData)
    setPackage(packageItemVal)
    // CalculatePackageRecurrence(packageItemVal)
  };
  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
  }

  // to get client details from api
  async function fetchClientData(userMemberId: any) {
    const clientservice = new TenantService();
    const result = await clientservice.getClient(userMemberId);
    if (result) {
      const clientIntialValues = {
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PhoneNumber: result?.User?.Address?.Phone,
        Email: result?.User?.Email,
      };
      const clientSubmitDetails = {
        Email: result?.User?.Email,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PrimaryMemberId: result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
      };
      setUserMemberId(result?.UserMemberId);
      setClientFormValues(clientIntialValues);
      setClientSubmitDetails(clientSubmitDetails);
    }
  }
  // to get client details from api
  async function updateSelectedClientData(userData: any) {
    const result = userData;
    if (result) {
      const clientIntialValues = {
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PhoneNumber: result?.Phone,
        Email: result?.Email,
        UserMemberId: result?.UserMemberId,
      };
      const clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PrimaryMemberId: result?.ParentId || result?.MainMemberId || result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        Status: result?.UserMemberStatus || result?.Status
      };
      setUserMemberId(result?.UserMemberId);
      setClientFormValues(clientIntialValues);
      setClientSubmitDetails(clientSubmitDetails);
      await fetchClassData(sessionItem,true,Taxes,result?.UserMemberId);
      await fetchPaymentProfiles(clientSubmitDetails?.PrimaryMemberId);
      setCardFormValues({ ...cardFormValues, UserMemberId: clientSubmitDetails?.PrimaryMemberId });
      setBankFormValues({ ...bankFormValues, UserMemberId: clientSubmitDetails?.PrimaryMemberId });
    }else{
      handleChangeClient()
    }
  }
  const filterCouponChange = (e: any) => {
    const filteredData = filterData(e.filter, coupondata);
    setfilterCouponData(filteredData);
  };

  const handlePackageCoupon=(value=appliedCoupons,dataItem=packag,taxData=Taxes)=>{
    let final_coup_data: any[] = [];
    const couponsList = value
    const couponFixed = couponsList.filter(
      (res: any) => res?.TypeDescription === "Fixed"
    );
    if (couponFixed.length > 0) {
      final_coup_data.push(couponFixed[couponFixed.length-1]);
    }
    const couponPercentage = couponsList.filter(
      (res: any) => res?.TypeDescription === "Percentage"
    );
    if (couponPercentage.length > 0) {
      final_coup_data.push(couponPercentage[couponPercentage.length-1]);
    }
    let totalCost=dataItem?.Cost
    let acceptLimitedCoupon = true
    if(staffPage){
      totalCost=dataItem?.DiscountedCost
    }
    if(dataItem?.IsProrated){
      totalCost=dataItem?.ProratedCost
      acceptLimitedCoupon=false
    }
    let offerAmt = 0;
    let cost = totalCost;
    let downpayment = totalCost;
    if (final_coup_data.length > 0) {
        final_coup_data =final_coup_data.map((item: any) => {
        if (item.TypeDescription === "Fixed") {
          let coupAmt=item.Amount
          if (item?.DurationType === CouponDurationTypeEnum.LIMITED && !acceptLimitedCoupon) {
            coupAmt=0
          }
          cost = cost <= coupAmt ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return {...item,couponAmt:coupAmt}
        } else if (item.TypeDescription === "Percentage") {
          const coupvalue=cost * (item.Amount / 100)
          let coupAmt=parseFloat(coupvalue.toFixed(2))
          if (item?.DurationType === CouponDurationTypeEnum.LIMITED && !acceptLimitedCoupon) {
            coupAmt=0
          }
          cost = item.Amount === 100 ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return {...item,couponAmt:coupAmt}
        }
      });
      downpayment= downpayment <= offerAmt ? 0 : downpayment - offerAmt;
    }
    const packagecostAfterDiscount = CustomToFixed(cost,2);
    setAppliedCoupons(final_coup_data)
    setCouponAmount(offerAmt);
    setPackageCostAfterDiscount(packagecostAfterDiscount);
    if(dataItem?.ClientCanSkipPayment){
      setDownpaymentForm({
        ...downpaymentForm,
        paidToday: 0,
      });
      handleTaxCalculation(packagecostAfterDiscount,taxData,true)
      handleTaxCalculation(0,taxData)
    }else{
      setDownpaymentForm({
        ...downpaymentForm,
        paidToday: CustomToFixed(downpayment,2),
      });
      handleTaxCalculation(downpayment,taxData)
      handleTaxCalculation(downpayment,taxData,true)

    }
    setCouponDetails({
      AppliedCoupons: final_coup_data,
    });
  }
  const handleOneonOneCoupon=async(value=appliedCoupons)=>{
    let final_coup_data: any[] = [];
    const couponsList = value
    const couponFixed = couponsList.filter(
      (res: any) => res?.TypeDescription === "Fixed"
    );
    if (couponFixed.length > 0) {
      final_coup_data.push(couponFixed[couponFixed.length-1]);
    }
    const couponPercentage = couponsList.filter(
      (res: any) => res?.TypeDescription === "Percentage"
    );
    if (couponPercentage.length > 0) {
      final_coup_data.push(couponPercentage[couponPercentage.length-1]);
    }
    let offerAmt = 0;
    let cost = packageCostRef?.current
    let downpayment = downpaymentFormRef?.current?.paidToday;
    if (final_coup_data.length > 0) {
        final_coup_data =final_coup_data.map((item: any) => {
        if (item.TypeDescription === "Fixed") {
          const coupAmt=item.Amount
          cost = cost <= coupAmt ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return {...item,couponAmt:coupAmt}
        } else if (item.TypeDescription === "Percentage") {
          const coupvalue=cost * (item.Amount / 100)
          const coupAmt=parseFloat(coupvalue.toFixed(2))
          cost = item.Amount === 100 ? 0 : cost - coupAmt;
          offerAmt += coupAmt;
          return {...item,couponAmt:coupAmt}
        }
      });
      downpayment= downpayment <= offerAmt ? 0 : downpayment - offerAmt;
      let packagecostAfterDiscount = 0;
      const rateAfterDiscount =
        cost === 0 ? 0 : cost / serviceDataRef?.current?.NumberOfSessions;
      packagecostAfterDiscount =
        rateAfterDiscount * serviceDataRef?.current?.NumberOfSessions;
      setAppliedCoupons(final_coup_data)
    setCouponAmount(offerAmt);
    setPackageCostAfterDiscount(packagecostAfterDiscount);
    const downPayData={...downpaymentFormRef?.current,paidToday: downpayment,}
    setDownpaymentForm(downPayData);
    handleTaxCalculation(downpayment,Taxes)
    handleTaxCalculation(packagecostAfterDiscount,Taxes,true)
    
    setCouponDetails({
      AppliedCoupons: final_coup_data,
    });
  } else {
      const data = await fetchServiceData();
      if(data?.CanCollectTaxes && checkHasTaxEnable()){
        setIsTaxSkipped(false)
        handleTaxCalculation(data?.DownPayment)
        handleTaxCalculation(data?.packageCostAfterDiscount,Taxes,true)
      }
      
    setCouponDetails({
      AppliedCoupons:final_coup_data,
    });
    setAppliedCoupons(final_coup_data)
  }

  }

  const filterData = (filter: any, itemData: any) => {
    const data = itemData.slice();
    return filterBy(data, filter);
  };

  const handleAddPaymentMode=async(description:string)=> {
    setPaymentMethod(defaultPaymentMethod);
    await fetchPaymentProfiles(clientSubmitDetails?.PrimaryMemberId || userMemberId,false,description)
  }


  //to get the payment profiles
  async function fetchPaymentProfiles(userMemberId: any,clearAdditionPP=false,modeVal="") {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if(modeVal){
        const record=result?.find((i:any)=> i?.CardTypeId ===-1)
        if(record){
          const data={
            ...record,
            CardTypeId: -2,
            MaskedCCNumber: modeVal,
            CardDescription: modeVal,
            CardDescriptionWithoutExpiryDate: modeVal,
          };
          const res=[...result]
          res.push(data)
          setPaymentProfile(JSON.parse(JSON.stringify(res)))
          handleChangeSavedCard(data);
        }
      }else {
        setPaymentProfile(result);

        let selectedCard;
        const cardType1 = result.find((card:any) => card.CardTypeId >= 1 && card.CardTypeId < 8);
        const cardType2 = result.find((card:any) => card.CardTypeId === 0);
        const cardType3 = result.find((card: any) => card.CardTypeId === 8 && card.Credit > 0);

        if (cardType1) {
          selectedCard = cardType1;
        } else if (cardType2) {
          selectedCard = cardType2;
        } else if (cardType3) {
          selectedCard = cardType3;
        } else {
          selectedCard = result.find((card:any) => card.CardTypeId === -1);
        }

        if (selectedCard) {
          handleChangeSavedCard(selectedCard);
        }
      }
    }
    if(clearAdditionPP){
      return result;
    }
    setTermsandConditions(true);
  }
  //to get the package Coupons
  async function fetchCoupons(userMemberId: any,type:string,EntityId:any) {
    const service = new TenantService();
    let req;
    if(type==="package"){
      req = {
        UserMemberId: userMemberId,
        EntityType: 7,
        EntityId: EntityId,
        CouponType:1
      };
    }else{
      req = {
        UserMemberId: userMemberId,
        EntityId: EntityId ? EntityId:0,
        EntityType: 6,
        CouponType:1
      };
    }
    const result = await service.AvailableCouponsToApply(req);
    if (result) {
      setCouponData(result);
      setfilterCouponData(result);
    }
  }

  // to handle credit card image icon
  const handleCardImageIcon = (imageUrl: any) => {
    setcardImageIcon(imageUrl);
  };


  //to saving signature
  const generatedImage = (
    imageString: string,
    text?: string,
    font?: string,
    Type?:string
  ) => {
    setIsFormChanged(true);
    setSignature(imageString);
    setSignatureText(text);
    setSignatureFont(font);
    setSignatureType(Type || "");
  };

  //handle signature pad popup
  const handleSignaturePad = () => {
    let check = false;
    if (checkoutType === "service") {
      check = checkoutValidation(false);
    } else if (
      checkoutType === "inhouse-package" ||
      checkoutType === "online-package"
    ) {
      check = checkoutPackageValidation(false);
    }
    if (check) {
      return;
    }
    setVisibleSignPad(true);
  };

  //to update client details
  const handleClientSubmit = async (dataItem: any) => {
    setClientFormValues(dataItem);
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("2");
    }
  };

  //to update client details
  const handleChangeClient = () => {
    setClientFormValues({});
    setClientsList([]);
    setClientSubmitDetails({});
    setPaymentProfile([]);
    setPaymentSubmitProfile({});
    setSelectedPaymentProfile({ selectedPayment: undefined,AlternatePaymentProfileId:null });
    setSelectedClient({
      type: "search",
      value: "",
    });
    setUserMemberId(null);
    setPackagesData([]);
    setPackageListToUse([]);
    let totalCost = 0;
    let allsessionCost = 0;
    let totalSessions = 0;
    let payLaterCost = 0;
    totalCost = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, true,true);
    }, 0);
    allsessionCost = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculateCost(currentValue.values, false,true);
    }, 0);
    payLaterCost = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculatePayLaterCost(currentValue.values,true);
    }, 0);
    totalSessions = sessionItem.reduce((acc: Number, currentValue: any) => {
      return acc + calculateSessionCount(currentValue.values);
    }, 0);

    const dates: any = []
    sessionItem.forEach((i: any) => {
      if (i?.values?.length > 0) {
        i?.values?.forEach((j: any) => {
          dates.push(moment(j?.ClassSessionStartDate).format('YYYY-MM-DD'))
        })
      }
    })

    setTotalClassSessions(totalSessions);
    setPackageCost(totalCost);
    setPayLaterAmount(payLaterCost);
    setPackageCostAfterDiscount(allsessionCost);
    setDownpaymentForm({ ...downpaymentForm, paidToday: allsessionCost });
    setUseCreditAmount(0)
    setUseComplimentaryAmount(0)

  };

  const handleAddClient = async (dataItem: any) => {
    const req = {
      FirstName: dataItem?.FirstName,
      LastName: dataItem?.LastName,
      Phone: dataItem?.PhoneNumber,
      Email: dataItem?.Email,
      SalesPersonId: 0,
      UserId: dataItem?.UserId,
      ReferredFrom: dataItem?.ReferredFrom?.ReferredFrom,
      SpecifyOthers: dataItem?.ReferredFrom?.ReferredFrom==="Other"? dataItem?.SpecifyOthers:null
    };
    setClientSubmitBtnLoading(true);
    const checkoutClass = new ClassService();
    const result = await checkoutClass.addClient(req);
    if (result?.ResponseCode === 100) {
      const userMemberId = result?.Item?.UserMemberId;
      await fetchClientData(userMemberId);
      await fetchPaymentProfiles(userMemberId);
      const addedClient = {
        Email: result?.Item.Email,
        MemberNumber: result?.Item.MemberNumber,
        FullName: `${dataItem.FirstName} ${dataItem.LastName}`,
        MainMemberId: result?.Item.UserMemberId,
        UserId: result?.Item.UserId,
        UserMemberId: result?.Item.UserMemberId,
        FirstName: result?.Item.FirstName,
        LastName: result?.Item.LastName,
      };
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: addedClient,
      });
      setCardFormValues({ ...cardFormValues, UserMemberId: userMemberId });
      setBankFormValues({ ...bankFormValues, UserMemberId: userMemberId });
      const successMsg = "Client Added Successfully";
      props?.handleNotificationMessage(successMsg, "success");
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
    setClientSubmitBtnLoading(false);
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("2");
    }
  };

  // handle payment profile submit
  const handleSubmit = (dataItem: any = null) => {
    if (selected === "0") {
      setSelected(undefined);
    } else {
      setSelected("3");
    }
  };
  // handle UseCreditSubmit submit
  const handleUseCreditSubmit = () => {
    if (downpaymentForm?.paidToday > 0) {
      setSelected("2");
    } else {
      setSelected("3");
    }
  };
  const handleSalesPersonSelection = (dataItem: any) => {
    setSelectedSalesPerson(dataItem);
  };

  const handleTermsConditions = (val:boolean) => {
    setTermsandConditions(val);
    setTermsandConditionsDialog(false);
  };


  const handleIsTaxSkipped=()=>{
    setIsTaxSkipped(!IsTaxSkipped)
  }
  const handlePackageReccurence = (name: string, value: any) => {
    let packageItemVal = {...packag}
    if (name === "StartDate") {
      const today1 = new Date();
      const sixMonths = new Date(today1);
      sixMonths.setMonth(sixMonths.getMonth() + 6);
      if(packag?.Frequency===PackageFrequencyEnum.ONETIME && packag?.ExpireOn===8){
        if(moment(value).format("YYYY-MM-DD") < moment(packag?.ExpiryDate).format("YYYY-MM-DD") && moment(value).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
          packageItemVal={
            ...packag, [name]: value
          }
        }
      }else if(moment(value).format("YYYY-MM-DD") <= moment(sixMonths).format("YYYY-MM-DD") && moment(value).format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")){
        if(moment(value).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
          packageItemVal={
            ...packag, [name]: value, ClientCanSkipPayment: false
          }
        }else{
            packageItemVal={
              ...packag, [name]: value
            }
        }
        }
    } else if(name ==="SignupDate"){
      packageItemVal={
        ...packag, [name]: value, StartDate: value
      }
    } else if(name==="Frequency"){
      if(value===PackageFrequencyEnum.MONTHLY){
        packageItemVal={
          ...packag, [name]: value, RepeatFrequency: !packag?.RepeatFrequency ? 1 : packag?.RepeatFrequency,FrequencyEndType: !packag?.FrequencyEndType ? 0 : 1,Occurrence: !packag?.Occurrence ? 2 : packag?.Occurrence
        }
      }else if(value === PackageFrequencyEnum.ONETIME){
        packageItemVal={
          ...packag, [name]: value,   Expire:packageItem?.ExpireOn === 8 ? 8 : 0,
          ExpireOn:packageItem?.ExpireOn,
          ExpiryDate:packageItem?.ExpireOn === 8 ? packageItem?.ExpiryDate : null,
          ExpireAfterDaysOnPurchase: packageItem?.ExpireAfterDaysOnPurchase, BillingType: 2,BillingDay:moment(packag?.StartDate).date()
        }
      }else{
        packageItemVal={
          ...packag, [name]: value, BillingType: 2,BillingDay:moment(packag?.StartDate).date(),RepeatFrequency: !packag?.RepeatFrequency ? 1 : packag?.RepeatFrequency,FrequencyEndType: !packag?.FrequencyEndType ? 0 : 1,Occurrence: !packag?.Occurrence ? 2 : packag?.Occurrence
        }
      }
    }  else if(name==="RepeatFrequency"){
        if(value===1){
          packageItemVal={
            ...packag, [name]: value
          }
        }else{
          packageItemVal={
            ...packag, [name]: value, BillingType: 2,BillingDay:moment(packag?.StartDate).date()
          }
        }
    }  else if(name==="FrequencyEndType"){
        if(value===1){
          packageItemVal={
            ...packag, [name]: value,Occurrence:2
          }
        }else{
          packageItemVal={
            ...packag, [name]: value,
          }
        }
  } else if(name==="OptedSchedules"){
        let optedScheduleValue=value
        if(value < 1){
          optedScheduleValue = 1
        }
        if(packag?.ProratedSchedules > optedScheduleValue){
          packageItemVal={
            ...packag, [name]: optedScheduleValue, ProratedSchedules:optedScheduleValue
          }
        }else{
          packageItemVal={
            ...packag, [name]: optedScheduleValue
          }
        }
    }else if(name==="Expire"){
      if(value===0){
        packageItemVal={
          ...packag,
          [name]: value,
          ExpireOn:packageItem?.ExpireOn ===8 ?  2: packageItem?.ExpireOn,
          ExpireAfterDaysOnPurchase: packageItem?.ExpireAfterDaysOnPurchase,
        }
      }else{
        packageItemVal={
          ...packag,
          [name]: value,
          ExpireOn:8,
          ExpiryDate:packageItem?.ExpiryDate || minimumDate,
        }
      }
  }else {
      packageItemVal={
        ...packag, [name]: value
      }
    }
    fetchPackageData(Taxes,packageItemVal, false)
  };
  const handleRenewal = (val: boolean) => {
    setserviceData({ ...serviceData, CanRenew: val });
  };

  const handleClientSelectionSubmit = async (e: any) => {
    e.preventDefault();
    if (selectedClient?.type === "select") {
      setClientSubmitBtnLoading(true);
      const userMemberId = selectedClient?.value?.UserMemberId;
      const mainMemberId = selectedClient?.value?.MainMemberId;
      await fetchClientData(userMemberId);
      await fetchClassData(sessionItem,true,Taxes,userMemberId);
      await fetchPaymentProfiles(mainMemberId);
      await setClientSubmitBtnLoading(false);
      setCardFormValues({ ...cardFormValues, UserMemberId: userMemberId });
      setBankFormValues({ ...bankFormValues, UserMemberId: userMemberId });
      if (selected === "0") {
        setSelected(undefined);
      } else {
        setSelected("2");
      }
    } else {
      const errorMsg = "Please Pick a Client";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // handle bank form submit
  const handleBankSubmit = async (dataItem: any) => {
    setIsFormChanged(true);
    setBankSubmitBtnLoading(true);
    const saveBankDetails = new TenantService();
    const req={...dataItem,BankAccountType:dataItem?.BankAccountType?.id}
    const result = await saveBankDetails.createPaymentProfile(req);
    setBankSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      const successMsg = "Payment creation successfully done";
      props?.handleNotificationMessage(successMsg, "success");
      setPaymentMethod(defaultPaymentMethod);
      fetchPaymentProfiles(dataItem?.UserMemberId);
      handleSubmit("text");
    } else {
      setBankFormValues(dataItem);
      setCardFormValues(cardIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // to handle panel bar expand and collpase
  const onSelect = (e: any) => {
    setSelected(e?.target?.props?.id);
  };

  // handle card form submit
  const handleCardSubmit = async (dataItem: any) => {
    setIsFormChanged(true);
    setCardSubmitBtnLoading(true);
    const saveCardDetails = new TenantService();
    if (paymentGatewayType === 1) {
      dataItem.MaskedCCNumber = dataItem.MaskedCCNumber.split(" ").join("");
    }
    const result = await saveCardDetails.createPaymentProfile(dataItem);
    setCardSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPopulateProfile(result?.Item);
      setSelected("0");
      props?.handleNotificationMessage(
        `Payment creation successfully done`,
        "success"
      );
      setPaymentMethod(defaultPaymentMethod);
      setcardImageIcon(images.placeholder);
      fetchPaymentProfiles(dataItem?.UserMemberId);
      handleSubmit("text");
    } else {
      setCardFormValues(dataItem);
      setBankFormValues(bankIntialValues);
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  // to save entered coupon to state
  const handleChangeCoupon = (val: any) => {
    setIsFormChanged(true);
    setCoupon(val);
  };

  //to remove the applied coupon
  const handleRemoveCoupon = (index: number) => {
    const array = [...appliedCoupons];
    if (index !== -1) {
      array.splice(index, 1);
    }
    if(checkoutType==="online-package"){
      handlePackageCoupon(array)
      if(!staffPage){
        setvisibleCouponField(true);
      }
    }else if(checkoutType==="service"){
      handleOneonOneCoupon(array)
      if (array.length === 0) {
        setvisibleCouponField(true);
      }
    }else{
      if (array.length === 0) {
        setvisibleCouponField(true);
      }
      setAppliedCoupons(array);
    }
  };

  // to select the payment profile
  const handleChangeSavedCard = (val: any,dataItem:any=null) => {
    dispatch(PaymentMethodSelected(val?.CardTypeId))
    setIsFormChanged(true);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: val,
      AlternatePaymentProfileId:null
    });
    setCardFormValues(cardIntialValues);
    setBankFormValues(bankIntialValues);
    generatePaymentProfileObj(val,dataItem);
    setPaymentMethod(defaultPaymentMethod);
    // if (selected === "0") {
    //   setSelected(undefined);
    // } else {
    //   setSelected("3");
    // }
  };

  //payment profile object to send while build agreement and purchase
  const generatePaymentProfileObj = (val: any,dataItem:any=null) => {
    let paymentDetails:any = {
      CustomerProfileID: val?.CustomerProfileID,
      PaymentProfileID: val?.PaymentProfileID,
      PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      CardDescription: val?.CardDescription,
      BankAccountType: val?.BankAccountType,
      Credit: val?.Credit,
    };
    if(dataItem){
      paymentDetails={
        ...paymentDetails,Reference:dataItem?.Reference,
        ReferrenceDocument:dataItem?.ReferrenceDocument
      }
    }
    setPaymentSubmitProfile(paymentDetails);
    setPopulateProfile(null)
  };

  // handle switching between saved payment  & card & bank forms
  const handleForm = (form: string) => {
    setcardImageIcon(images.placeholder);
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: undefined,
      AlternatePaymentProfileId:null
    });
    setPaymentSubmitProfile({});
    setPaymentMethod({ ...defaultPaymentMethod, [form]: true });
  };

  //handle downpayment form while entering less amount than purchase amt

  //go to calculate installments with condition
  useEffect(() => {
    const { installments, recurrenceUnit, recurringStartDate, OcurrsEvery } =
      downpaymentForm;
    if (
      installments &&
      installments > 0 &&
      recurrenceUnit != undefined &&
      recurringStartDate != undefined &&
      OcurrsEvery >= 0
    ) {
      CalculateRecurrence(true);
    } else {
      CalculateRecurrence(false);
    }
  }, [downpaymentForm]);

  // saving applied coupons in coupon details to submit buildagreement and purchase
  



  const handleCustomRate=()=>{
    const value=CustomRateDialog?.Rate
    if (appliedCoupons.length > 0) {
      let offerAmt = 0;
      let cost = serviceData.NumberOfSessions*value;
      let downpayment = cost;
      appliedCoupons.forEach((item: any) => {
        if (item.TypeDescription === "Fixed") {
          cost = cost <= item.Amount ? 0 : cost - item.Amount;
          downpayment =
            downpayment <= item.Amount ? 0 : downpayment - item.Amount;

          offerAmt += item.Amount;
        } else if (item.TypeDescription === "Percentage") {
          cost = item.Amount === 100 ? 0 : cost - cost * (item.Amount / 100);
          downpayment =
            item.Amount === 100
              ? 0
              : downpayment - downpayment * (item.Amount / 100);
          offerAmt += packageCost * (item.Amount / 100);
        }
      });
      let packagecostAfterDiscount = 0;
      const rateAfterDiscount =
        cost === 0 ? 0 : cost / serviceData.NumberOfSessions;
      packagecostAfterDiscount =
        rateAfterDiscount * serviceData.NumberOfSessions;
      setserviceData({
        ...serviceData,
        RateAfterDiscount: rateAfterDiscount,
        Rate:value
      });
      setCouponAmount(offerAmt);
      setPackageCostAfterDiscount(packagecostAfterDiscount);
      setPackageCost(serviceData.NumberOfSessions*value)
      setDownpaymentForm({
        ...downpaymentForm,
        paidToday: downpayment,
      });
      setCustomRate(value)
      handleTaxCalculation(downpayment,Taxes)
      handleTaxCalculation(packagecostAfterDiscount,Taxes,true)
      setCustomRateDialog(initialCustomDialogVal)
    } else {
      const packagecostAfterDiscount = value * serviceData.NumberOfSessions;;
      setserviceData({
        ...serviceData,
        RateAfterDiscount: value,
        Rate:value
      });
      setCustomRate(value)
      setPackageCostAfterDiscount(packagecostAfterDiscount);
      setPackageCost(packagecostAfterDiscount)
      setDownpaymentForm({
        ...downpaymentForm,
        paidToday: packagecostAfterDiscount,
      });
      handleTaxCalculation(packagecostAfterDiscount,Taxes)
      handleTaxCalculation(packagecostAfterDiscount,Taxes,true)
      setCustomRateDialog(initialCustomDialogVal)
    }
  }
  //recurrences calculations
  const CalculateRecurrence = (check: boolean) => {
    const data: any = [];
    if (
      selectedPaymentProfile?.selectedPayment?.CardTypeId === -1
    ) {
      setTableData(data);
      return;
    }
    if (check) {
      if (
        packageCostAfterDiscount > 0 &&
        downpaymentForm.paidToday >= 0 &&
        downpaymentForm.recurrenceUnit != undefined &&
        downpaymentForm.installments > 0 &&
        downpaymentForm.recurringStartDate != undefined
      ) {
        if (packageCostAfterDiscount >= downpaymentForm.paidToday) {
          let BalanceAmount =
            packageCostAfterDiscount - downpaymentForm.paidToday;
          let NoOfInstallments =
            downpaymentForm.installments > 999
              ? 999
              : downpaymentForm.installments;
          if (BalanceAmount > 0) {
            let InstallmentAmount = BalanceAmount / NoOfInstallments;
            InstallmentAmount = parseFloat(InstallmentAmount.toFixed(2));
            setRecurringAmount(InstallmentAmount);
            let unit = downpaymentForm.recurrenceUnit.text;
            let InstallmentDate = downpaymentForm.recurringStartDate; //.add(that.Order.Service.OcurrsEvery, unit);
            let totalAmt = 0;
            for (let i = 0; i < NoOfInstallments; i++) {
              totalAmt = totalAmt + InstallmentAmount;
              if (BalanceAmount !== totalAmt && i === NoOfInstallments - 1) {
                InstallmentAmount =
                  InstallmentAmount + (BalanceAmount - totalAmt);
              }
              data.push({
                Date: InstallmentDate,
                DisplayDate: getLongDate(InstallmentDate),
                InstallmentAmount: InstallmentAmount,
              });
              InstallmentDate = addDates(
                InstallmentDate,
                downpaymentForm.OcurrsEvery,
                unit
              );
            }
          }
        }
      }
    }
    setTableData(data);
  };

  useEffect(() => {
    const { paidToday } = downpaymentForm;
    if (
      paidToday !== null &&
      paidToday !== undefined &&
      paidToday >= 0 &&
      paidToday < packageCostAfterDiscount
    ) {
      
        setShowinstallmentForm(true);
     
    } else {
      setShowinstallmentForm(false);
    }
  }, [downpaymentForm.paidToday, packageCostAfterDiscount]);

  const handleDownPayment = (val: any, name: string) => {
    dispatch(DownPaymentEntered(val))
    setIsFormChanged(true);
    if (name === "paidToday") {
      let paidTodayVal = val;
      if (val > packageCostAfterDiscount) {
        paidTodayVal = packageCostAfterDiscount;
      } else if (val <= 0) {
        paidTodayVal = 0;
      } else if (val === undefined || val === null) {
        paidTodayVal = null;
      }
      setDownpaymentForm({ ...downpaymentForm, [name]: paidTodayVal });
      handleTaxCalculation(paidTodayVal)
      handleTaxCalculation(packageCostAfterDiscount,Taxes,true)
    } else if (name === "installments") {
      let installmentVaue = val;
      if (val > 999) {
        installmentVaue = 999;
      }
      if (val <= 0) {
        installmentVaue = 1;
      }
      setDownpaymentForm({ ...downpaymentForm, [name]: installmentVaue });
    } else if (name === "OcurrsEvery") {
      let ocurrsEveryValue = val;
      const recurrUnit = downpaymentForm.recurrenceUnit.id;
      if (recurrUnit === 2) {
        if (val > 30) {
          ocurrsEveryValue = 30;
        }
      } else {
        if (val > 12) {
          ocurrsEveryValue = 12;
        }
      }
      if (val <= 0) {
        ocurrsEveryValue = 1;
      }
      const  InstallmentDate = addDates(
        new Date(),
        ocurrsEveryValue,
        downpaymentForm?.recurrenceUnit?.text
        
      );
      setDownpaymentForm({ ...downpaymentForm, [name]: ocurrsEveryValue,recurringStartDate:InstallmentDate });
    } else if (name === "recurrenceUnit") {
      const recurrUnit = val.id;
      let ocurrsEveryValue = downpaymentForm.OcurrsEvery;
      if (recurrUnit === 2) {
        if (ocurrsEveryValue > 30) {
          ocurrsEveryValue = 30;
        }
      } else {
        if (ocurrsEveryValue > 12) {
          ocurrsEveryValue = 12;
        }
      }
      const  InstallmentDate = addDates(
        new Date(),
        ocurrsEveryValue,
        val?.text
      );
      setDownpaymentForm({
        ...downpaymentForm,
        [name]: val,
        OcurrsEvery: ocurrsEveryValue,
        recurringStartDate:InstallmentDate 
      });
    }else if(name==="recurringStartDate"){
      if(moment(val).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')){
        setDownpaymentForm({ ...downpaymentForm, [name]: val });
      }
    } else {
      setDownpaymentForm({ ...downpaymentForm, [name]: val });
    }
  };

  //to handle coupon validation and api call to filter coupon
  const handleCoupon = async () => {
    if (coupon !== "" && coupon !== undefined) {
      if (appliedCoupons.length >= 1) {
        const check = appliedCoupons.filter(
          (item: any) =>
            item.CouponInformation.toLowerCase() === coupon.toLowerCase()
        );
        if (check.length > 0) {
          const errorMsg = "Coupon already added";
          props?.handleNotificationMessage(errorMsg, "error");
          return;
        }
      }
      setCouponBtnLoading(true);
      const couponService = new TenantService();
      let req = {
        UserMemberId: userMemberId,
        EntityId: serviceData?.PrivateServiceId,
        EntityType: 6,
        CouponName: coupon,
      };
      if(checkoutType==="online-package"){
        req = {
          UserMemberId: userMemberId,
          EntityId: packag?.PackageId,
          EntityType: 7,
          CouponName: coupon,
        };
      }
      const result = await couponService.findCoupon(req);
      setCouponBtnLoading(false);
      if (result?.ResponseCode === 100) {
        if (result.Items.length === 0) {
          const errorMsg = "Please enter valid coupon";
          props?.handleNotificationMessage(errorMsg, "error");
        } else {
          const res = result.Items[0];
          const data = {
            Amount: res?.Amount,
            CouponId: res?.CouponId,
            CouponInformation: res?.CouponInformation,
            Description: res?.CouponInformation,
            Type: res?.Type,
            TypeDescription: res?.TypeDescription,
            AvailableCount: res?.AvailableCount,
            CouponAppliedCount: res?.CouponAppliedCount,
            DurationType: res?.DurationType,
          };
          const appCoupon=[...appliedCoupons, data]
          setAppliedCoupons(appCoupon);
          setCoupon("");
          setvisibleCouponField(false);
          if(checkoutType==="service"){
            handleOneonOneCoupon(appCoupon)
          }else{
            handlePackageCoupon(appCoupon)
          }
          const successMsg = "Coupon Applied Successfully";
          props?.handleNotificationMessage(successMsg, "success");
        }
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      const errorMsg = "Please enter Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handlePromotionCoupon = async (promoCoupon=coupon,taxes=Taxes) => {
    if (promoCoupon !== "" && promoCoupon !== undefined) {
      setCouponBtnLoading(true);
      const couponService = new TenantService();
      let req = {
        UserMemberId: userMemberId,
        EntityId: serviceData?.PrivateServiceId,
        EntityType: 6,
        CouponName: promoCoupon,
        CouponType: 2,
      };
      if(checkoutType==="online-package"){
        req = {
          UserMemberId: userMemberId,
          EntityId: packag?.PackageId,
          EntityType: 7,
          CouponName: promoCoupon,
          CouponType: 2,
        };
      }
      const result = await couponService.findCoupon(req);
      setCouponBtnLoading(false);
      if (result?.ResponseCode === 100) {
        if (result.Items.length === 0) {

        } else {
          const res = result.Items[0];
          const data = {
            Amount: res?.Amount,
            CouponId: res?.CouponId,
            CouponInformation: res?.CouponInformation,
            Description: res?.CouponInformation,
            Type: res?.Type,
            TypeDescription: res?.TypeDescription,
            AvailableCount: res?.AvailableCount,
            CouponAppliedCount: res?.CouponAppliedCount,
            DurationType: res?.DurationType,
          };
          const appCoupon=[...appliedCoupons, data]
          setAppliedCoupons(appCoupon);
          setCoupon("");
          setvisibleCouponField(false);
          if(checkoutType==="service"){
            handleOneonOneCoupon(appCoupon)
          }else{
            // handlePackageCoupon(appCoupon)
            await fetchPackageData(taxes,undefined,undefined,appCoupon)
          }
        }
      }
    }
  };

  // purchase and build agreement validation
  const checkoutValidation = (checkSign = true) => {
    setSelected("0");
    const {
      paidToday,
      installments,
      OcurrsEvery,
      recurringStartDate,
      recurrenceUnit,
    } = downpaymentForm;
    if (Object.keys(paymentSubmitProfile).length === 0) {
      setPaymentMethod(defaultPaymentMethod);
      setSelected("2");
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }else{
      let purchaseAmt= !IsTaxSkipped ? paidToday + TotalTaxAmount : paidToday
      purchaseAmt=CustomToFixed(purchaseAmt,2)
      if(paymentSubmitProfile?.CardTypeId === 8 && purchaseAmt > paymentSubmitProfile?.Credit && !selectedPaymentProfile?.AlternatePaymentProfileId){
        let defaultPaymentProfile=null
        const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
        if(existedPP?.length > 0){
          defaultPaymentProfile=existedPP[0]
        }
        setShowAdditionalPayment({
          dialog:true,
          selectedPaymentProfile:defaultPaymentProfile
        })
        return true;
      }
    }
    if (paidToday < 0) {
      setSelected("3");
      return true;
    }
    if (showinstallmentForm) {
      if (
        paidToday < 0 ||
        installments <= 0 ||
        OcurrsEvery <= 0 ||
        !recurringStartDate ||
        !recurrenceUnit
      ) {
        setSelected("3");
        return true;
      }
    }
    if(staffPage && paidToday < packageCostAfterDiscount && selectedPaymentProfile?.selectedPayment?.CardTypeId === -1){
      const errormsg = "Can't use cash payments with recurrence service";
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if (staffPage && !selectedSalesPerson) {
      const errorMsg = "Please select Sales Person";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (checkSign && signature === "") {
      const errorMsg = "Please provide signature";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    return false;
  };
  // purchase and build agreement validation
  const checkoutPackageValidation = (checkSign = true) => {
    setSelected("0");
    if (Object.keys(clientSubmitDetails).length === 0) {
      setSelected("1");
      const errorMsg = "Please pick/create Client";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (packag?.SessionType === 1 && packag?.BillingType ===3 &&
      packag?.Frequency === PackageFrequencyEnum.MONTHLY &&
      packag?.RepeatFrequency === 1 && (packag?.ProratedSchedules < 1 || packag?.ProratedSchedules > packag?.OptedSchedules) && moment(packag?.StartDate).date() !== packag?.BillingDay) {
      const errorMsg = `Appointments should not exceed the available allocation, i.e ${packag?.OptedSchedules}`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (staffPage && packag?.SessionType === 1 && packag?.Frequency !== PackageFrequencyEnum.ONETIME && (packag?.OptedMaxRollOverSchedules < 0 || packag?.OptedMaxRollOverSchedules > 100)) {
      const errorMsg = `Max Rollover appointments should between 0 and 100`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (packag?.DiscountedCost > 0 && Object.keys(paymentSubmitProfile).length === 0) {
      setPaymentMethod(defaultPaymentMethod);
      setSelected("2");
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }else{
       if(paymentSubmitProfile?.CardTypeId === 8 && packageCostAfterDiscount > paymentSubmitProfile?.Credit){
        const errorMsg = CreditsErrorMessage
        props?.handleNotificationMessage(errorMsg, "error");
        return true;
      }
    }
    if (
      selectedPaymentProfile?.selectedPayment?.CardTypeId === -1 && packag?.ClientCanSkipPayment
    ) {
      const errormsg = "Can't use cash/wallet payments with Pay Later Option";
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if (
      selectedPaymentProfile?.selectedPayment?.CardTypeId === -1  && (packag?.SessionType === 2 || packag?.SessionType === 1) && packag?.Frequency !== PackageFrequencyEnum.ONETIME
    ) {
      const errormsg = "Can't use cash/wallet payments with Recurring packages";
      props?.handleNotificationMessage(errormsg, "error");
      return true;
    }
    if ((staffPage || packag?.CanClientOverrideOccurence) && (packag?.SessionType === 2 || packag?.SessionType === 1)) {
      if (packag?.Frequency !== PackageFrequencyEnum.ONETIME && packag?.RepeatFrequency < 1) {
        setSelected("3");
        return true;
      }
      if (packag?.FrequencyEndType === 1 && packag?.Occurrence < 2) {
        setSelected("3");
        return true;
      }
      if (packag?.Frequency === PackageFrequencyEnum.ONETIME){
        if(packag?.Expire=== 0 && packag?.ExpireAfterDaysOnPurchase <=0){
        setSelected("3");
          return true;
        }
        const ExpiryDate = moment(packag?.ExpiryDate).format("YYYY-MM-DD");
        if(ExpiryDate === "Invalid date" || moment(ExpiryDate).format("YYYY-MM-DD") < moment(minimumDate).format('YYYY-MM-DD')){
        setSelected("3");
          return true;
        }
      }
    }
    if (checkoutType === "inhouse-package" || staffPage) {
      if (!selectedSalesPerson) {
        const errorMsg = "Please select Sales person";
        props?.handleNotificationMessage(errorMsg, "error");
        return true;
      }
    }
    if (checkSign && signature === "") {
      const errorMsg = "Please provide signature";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    return false;
  };
  // purchase Class validation
  const checkoutClassValidation = (checkTerms = true) => {
    setSelected("6");
    if (Object.keys(clientSubmitDetails).length === 0) {
      setSelected("1");
      const errorMsg = "Please pick/create Client";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      downpaymentForm?.paidToday > 0 ||
      (downpaymentForm?.paidToday === 0 && !checkPaymentProfileNotRequired(true))
    ) {
      if (Object.keys(paymentSubmitProfile).length === 0) {
        setPaymentMethod(defaultPaymentMethod);
        setSelected("2");
        const errorMsg = "Please select/add Payment profile";
        props?.handleNotificationMessage(errorMsg, "error");
        return true;
      }else{
        let purchaseAmt= !IsTaxSkipped ? downpaymentForm?.paidToday + TotalTaxAmount : downpaymentForm?.paidToday
        purchaseAmt=CustomToFixed(purchaseAmt,2)
        if(paymentSubmitProfile?.CardTypeId === 8 && purchaseAmt > paymentSubmitProfile?.Credit && !selectedPaymentProfile?.AlternatePaymentProfileId){
          let defaultPaymentProfile=null
          const existedPP=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)
          if(existedPP?.length > 0){
            defaultPaymentProfile=existedPP[0]
          }
          setShowAdditionalPayment({
            dialog:true,
            selectedPaymentProfile:defaultPaymentProfile
          })
          return true;
        }
      }
    }
   
    if (staffPage && !selectedSalesPerson) {
      const errorMsg = "Please select Sales Person";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (checkTerms && !termsandConditions) {
      const errorMsg = "Please check the Terms & Conditions";
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    return false;
  };

  //to handle build agreement
  const handleCheckoutPage = (page: string, cbParams:any=null) => {
    setConfirmClientDialog(false);
    if (!staffPage && !CheckUserMemberStatus(clientSubmitDetails)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (staffPage && !CheckUserMemberStatus(clientSubmitDetails)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (checkoutType === "service") {
      const { installments, OcurrsEvery, recurrenceUnit } = downpaymentForm;
      const RepeatFrequency = recurrenceUnit.id;
      let serviceDet = serviceData;
      // check form validations
      let check = false;
      if (page === "agreement") {
        check = checkoutValidation(false);
      } else {
        check = checkoutValidation();
      }
      if (check) {
        return;
      }
      if (showinstallmentForm) {
        serviceDet = {
          ...serviceData,
          Duration: parseInt(serviceData.Duration),
          OcurrsEvery: OcurrsEvery,
          RepeatFrequency: RepeatFrequency,
          NoOfInstallments: installments,
          // RenewalType: !serviceData?.CanRenew ? 0 : serviceData?.RenewalType,
          // RenewalType: !serviceData?.CanRenew ? 0 : cbParams?.renewalType,
          RenewalType: cbParams?.renewalType,
          CanRenew: cbParams?.canRenew,
          UserMemberId:clientSubmitDetails?.UserMemberId
        };
      } else {
        serviceDet = {
          ...serviceData,
          Duration: parseInt(serviceData.Duration),
          OcurrsEvery: 0,
          RepeatFrequency: 0,
          NoOfInstallments: 0,
          // RenewalType: !serviceData?.CanRenew ? 0 : 2,
          // RenewalType: !serviceData?.CanRenew ? 0 : cbParams?.renewalType,
          RenewalType: cbParams?.renewalType,
          CanRenew: cbParams?.canRenew,
          UserMemberId:clientSubmitDetails?.UserMemberId
        };
      }
      // console.log(serviceDet)
      setserviceData(serviceDet);
      createAgreementObj(serviceDet, page);
    } else if (
      checkoutType === "inhouse-package" ||
      checkoutType === "online-package"
    ) {
      let check = false;
      if (page === "agreement") {
        check = checkoutPackageValidation(false);
      } else {
        check = checkoutPackageValidation();
      }
      if (check) {
        return;
      }
      createPackageAgreementObj(page);
    } else {
      const check = checkoutClassValidation();
      if (check) {
        return;
      }
      handleClassPurchase();
    }
  };
  const handleClassPurchase = async () => {
    let sessionIds: any = [];
    session.forEach((item: any) =>
      item?.values.forEach((item1: any) =>
        sessionIds.push({
          ClassId: item1.ClassId,
          ClassSessionId: item1.ClassSessionId,
          ClassType: item1.ClassType,
          Cost: item1.Cost,
          CanBookAsWaitlist:item1.CanBookAsWaitlist,
          Complimentary:item1.Complimentary,
          CanCollectTaxes:checkHasTaxEnable() ? !item.IsTaxSkipped :false,
          Taxes:checkHasTaxEnable() ? item1.Taxes :[],
          TotalTaxAmount: checkHasTaxEnable() ? item1.TotalTaxAmount : 0,
        })
      )
    );
    let ClassSessionDetails: any = [];
    sessionIds.forEach((k: any) => {
      const selectedPackageForSessionId: any = packageListToUse.find(
        (val: any) =>
          val.ClassSessions.find(
            (val1: any) => val1?.ClassSessionId === k?.ClassSessionId
          )
      );
      const checkSkipPaymentStatus = checkSkipPayment(k?.ClassSessionId);
      const { ClassId, ClassSessionId, ClassType, Cost, TotalTaxAmount,Taxes,CanCollectTaxes,CanBookAsWaitlist } = k;
      if (selectedPackageForSessionId) {
        const { PackageInstanceId, Name } = selectedPackageForSessionId;
        ClassSessionDetails.push({
          ClassId,
          ClassSessionId,
          EntityName: Name,
          PayBy: 1,
          PaymentSourceId: PackageInstanceId,
          TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist,
        });
      }else if(k?.Complimentary){
        ClassSessionDetails.push({
          ClassId,
          ClassSessionId,
          PayBy: 5,
          PaymentSourceId:paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
          TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist
        });
      }
      // else if(CanBookAsWaitlist){
      //   ClassSessionDetails.push({
      //     ClassId,
      //     ClassSessionId,
      //     PayBy: 0,
      //     PaymentSourceId: paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
      //     TotalTaxAmount,
      //     Taxes,
      //     CanCollectTaxes,
      //     Cost,
      //     CanBookAsWaitlist
      //   });
      // } 
      else if (checkSkipPaymentStatus) {
        if (ClassType === 3 || Cost <= 0) {
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 3,
            PaymentSourceId:
              paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
              TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist
          });
        } else {
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 2,
            PaymentSourceId:
              paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
              TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist
          });
        }
      } else {
        ClassSessionDetails.push({
          ClassId,
          ClassSessionId,
          PayBy: 0,
          PaymentSourceId: paymentSubmitProfile?.PaymentGatewayPaymentProfileID,
          TotalTaxAmount,
          Taxes,
          CanCollectTaxes,
          Cost,
          CanBookAsWaitlist
        });
      }
    });

    const startDate = moment(groupSelectedDates[0]).format("YYYY-MM-DD");
    const IsPastDate = moment(startDate).isBefore(moment(), 'day');

    let req: any = {
      DownPayment: packageCostAfterDiscount || 0,
      // IsTransactionSucced: false,
      // Coupon: {},
      Client: clientSubmitDetails,
      ClassSessionDetails,
      HasTermsAndConditionAccepted: checkoutType ==="inhouse-class"? true : termsandConditions,
      IsPastDate
    };
    req = { ...req, PaymentProfile: paymentSubmitProfile };
    const taxAmt = calculateTotalSessionTax();
    let purchaseAmt= !IsTaxSkipped ? downpaymentForm?.paidToday : downpaymentForm?.paidToday + taxAmt
    if (paymentSubmitProfile?.CardTypeId === 8 && purchaseAmt > paymentSubmitProfile?.Credit) {
      const AlternatePaymentProfileId = selectedPaymentProfile?.AlternatePaymentProfileId
      const AmountChargeByAlternatePaymentProfile = CustomToFixed(purchaseAmt - paymentSubmitProfile?.Credit, 2)
      req = {
        ...req,
        PaymentProfile: {
          ...paymentSubmitProfile,
          AlternatePaymentProfiles: [
            {
              AlternatePaymentProfileId: AlternatePaymentProfileId,
              AmountChargeByAlternatePaymentProfile: AmountChargeByAlternatePaymentProfile
            }
          ]
        }
      };
    }else{
      req = {
        ...req,
        PaymentProfile: {
          ...paymentSubmitProfile,
          AlternatePaymentProfiles: [
            {
              AlternatePaymentProfileId: 0,
              AmountChargeByAlternatePaymentProfile: 0
            }
          ]
        }
      };
    }
   
    let PaymentObject={...req?.paymentSubmitProfile}
    let paymentAttributes = null;
    if (paymentSubmitProfile?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: paymentSubmitProfile?.Reference,
        OfflinePaymentDescription: paymentSubmitProfile?.MaskedCCNumber,
        ReferrenceDocumentName: paymentSubmitProfile?.ReferrenceDocument?.FileName || "",
        ReferrenceDocumentContent: paymentSubmitProfile?.ReferrenceDocument?.ImageAsBase64 || "",
      };
      PaymentObject={...paymentSubmitProfile,CardTypeId:-1}
    }else if(selectedPaymentProfile?.CardTypeId === -1){
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
      PaymentObject={...paymentSubmitProfile,CardTypeId:-1}
    }
    req= {
      ...req,
      PaymentAttributes:paymentAttributes,PaymentProfile: PaymentObject
    }
    setBtnLoading(true);
    setDisable(true);
    const checkoutClass = new ClassService();
    try {
      const result = await checkoutClass.purchaseClass(req);
      setBtnLoading(false);
      setDisable(false);

      if (result?.ResponseCode === 100) {
        setIsFormChanged(false);
        setSubmit(true);
        setSubmittedClassSessions(ClassSessionDetails)
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const createPackageAgreementObj = (page: string) => {
    const today = moment(packag?.StartDate).format("L");
    const mainDate=packag?.BillingType ===3 ? moment(packag?.StartDate).date(packag?.BillingDay).format("L")  : today
    let recurringDetail = null;
    let expiryafterDays = packag?.ExpireAfterDaysOnPurchase
    let ExpiryDate =  moment(minimumDate).format('L')
    let ExpireOn=packag?.ExpireOn
    if (packag?.Frequency !== PackageFrequencyEnum.ONETIME) {
   
      const item = PackageFrequency?.filter((i: any) => i?.id === packag?.Frequency)?.[0]
      const repeatfreqValue = item?.shortValue
      let nextMonth = calculatePackageRecurrenceDate(mainDate, packag?.RepeatFrequency, repeatfreqValue);
      if(moment(packag?.StartDate).date() < packag?.BillingDay){
        nextMonth=mainDate
      }

      expiryafterDays = 0
      recurringDetail = {
        FrequencyEndType: packag?.FrequencyEndType,
        RepeatFrequency: packag?.RepeatFrequency,
        Frequency: packag?.Frequency,
        Occurrence: packag?.FrequencyEndType === 0 ? 0 : packag?.Occurrence,
        RecurringAmount: packageCostAfterDiscount,
        NextRecurringDate: nextMonth,
      };
    }
    else if (packag?.Frequency === PackageFrequencyEnum.ONETIME) {
      recurringDetail = {
        FrequencyEndType: 1,
        RepeatFrequency: 1,
        Frequency: packag?.Frequency,
        Occurrence: 1,
        RecurringAmount: 0,
        NextRecurringDate: null,
      };
      if(packag?.Expire=== 0){
        ExpireOn = packag?.ExpireOn;
      }else{
        ExpiryDate = moment(packag?.ExpiryDate).format('L')
        ExpireOn = 8;
      }
    }

    const packageObj = {
      PackageId: packag?.PackageId,
      ExpireAfterDaysOnPurchase: expiryafterDays,
      ExpiryDate: ExpiryDate,
      ExpireOn: ExpireOn,
      Name: packag?.Name,
      Schedules: packag?.Schedules,
      SessionType: packag?.SessionType,
      Cost: packag?.Cost,
      CanProrate: packag?.IsProrated,
      ProratedCost:packag?.IsProrated ? packag?.ProratedCost : undefined,
      ProratedSchedules:packag?.IsProrated ? packag?.ProratedSchedules : undefined,
      DiscountedCost:staffPage ? packag?.DiscountedCost:packag?.Cost,
      OptedSchedules: packag?.SessionType === 1 ?  staffPage ? packag?.OptedSchedules: packag?.Schedules : undefined,
      OptedMaxRollOverSchedules :packag?.SessionType === 1 ?  staffPage ? packag?.OptedMaxRollOverSchedules: packag?.MaxRollOverSchedules : undefined,
      MaxRollOverSchedules:packag?.MaxRollOverSchedules,
    }
    let clientCanSkipPay=packag?.ClientCanSkipPayment
    if(packag?.Cost <=0){
      clientCanSkipPay = false
      if(recurringDetail){
        recurringDetail = {...recurringDetail,RecurringAmount:0}
      }
    }
    let buildagreement:any = {
      Client: clientSubmitDetails,
      ClientCanSkipPayment: clientCanSkipPay,
      DownPayment: downpaymentForm.paidToday,
      HasTermsAndConditionAccepted: true,
      PaymentProfile: paymentSubmitProfile,
      Package: packageObj,
      Coupons:couponDetails?.AppliedCoupons || [],
      Signature: signature,
      SalesPersonId:
        checkoutType === "inhouse-package" || staffPage ? selectedSalesPerson?.UserId : 0,
      PackageCostAfterDiscount: packageCostAfterDiscount,
      RecurringDetail: recurringDetail,
      StartDate: moment(packag?.StartDate).format("L"),
      Taxes:checkHasTaxEnable() && !IsTaxSkipped ? Taxes : [],
      CanCollectTaxes :checkHasTaxEnable() ? !IsTaxSkipped : false,
      TotalTaxAmount: checkHasTaxEnable() && !IsTaxSkipped ? TotalTaxAmount : 0,
      // new fields
      BillingDay:packag?.BillingType ===3 ? packag?.BillingDay : moment(packag?.StartDate).date(),
      SignupDate:moment(packag?.SignupDate).format("L"),
    };
    if(packag?.PromotionId){
      buildagreement={...buildagreement,
        PromotionId:packag?.PromotionId,
        SalesPersonId:packag?.AffiliateUserId,
        AffiliateId:packag?.AffiliateId
      }
    }
    setBuildagreement(buildagreement);
    if (page === "agreement") {
      handleBuildAgreement(buildagreement);
    } else {
      if (agreementTemplate === "") {
        handlePayNow(buildagreement);
      } else {
        const data = {
          ...buildagreement,
          AgreementTemplate: agreementTemplate,
          TermsAndConditionAccepted: true,
        };
        handlePayNow(data);
      }
    }
  };
  const createAgreementObj = (serviceDet: any, page: string) => {
    const { recurringStartDate, paidToday } = downpaymentForm;
    let recurDate: any = recurringStartDate;
    if (showinstallmentForm && tableData.length > 0) {
      recurDate = moment(tableData[0].Date).format("L");
    }
    if (!showinstallmentForm || serviceData?.PayByRecurring === false) {
      recurDate = "";
    }
    let buildagreement:any = {
      DownPayment: CustomToFixed(paidToday,2),
      NextRecurringDate: recurDate,
      IsTransactionSucced: false,
      Coupon: couponDetails,
      Client: clientSubmitDetails,
      PaymentProfile: paymentSubmitProfile,
      PaymentGatewayTransactionHistoryId: 0,
      PrivateServiceInstanceId: 0,
      MemberRecurringDetailId: 0,
      RecurringAmount: recurringAmount,
      SalesPersonId: staffPage ? selectedSalesPerson?.UserId : 0,
      Service: serviceDet,
      Signature: signature,
      SignatureText: signatureText,
      SignatureFont: signatureFont,
      AgreementTemplate: "",
      TermsAndConditionAccepted: false,
      Taxes:checkHasTaxEnable() && !IsTaxSkipped ? Taxes : [],
      CanCollectTaxes :checkHasTaxEnable() ? !IsTaxSkipped : false,
      TotalTaxAmount: checkHasTaxEnable() && !IsTaxSkipped ? TotalTaxAmount : 0
    };
    let purchaseAmt= !IsTaxSkipped ? paidToday + TotalTaxAmount : paidToday
    purchaseAmt=CustomToFixed(purchaseAmt,2)
    if(paymentSubmitProfile?.CardTypeId === 8 && purchaseAmt > paymentSubmitProfile?.Credit){
      const AlternatePaymentProfileId=selectedPaymentProfile?.AlternatePaymentProfileId
      const AmountChargeByAlternatePaymentProfile=CustomToFixed(purchaseAmt-paymentSubmitProfile?.Credit,2)
      buildagreement.AlternatePaymentProfileId=AlternatePaymentProfileId
      buildagreement.AmountChargeByAlternatePaymentProfile=AmountChargeByAlternatePaymentProfile
   }

    if(serviceData?.PromotionId){
      buildagreement={...buildagreement,PromotionId:serviceData?.PromotionId,
        SalesPersonId:serviceData?.AffiliateUserId,
        AffiliateId:serviceData?.AffiliateId
      }
    }

    setBuildagreement(buildagreement);
    if (page === "agreement") {
      handleBuildAgreement(buildagreement);
    } else {
      if (agreementTemplate === "") {
        handlePayNow(buildagreement);
      } else {
        const data = {
          ...buildagreement,
          AgreementTemplate: agreementTemplate,
          TermsAndConditionAccepted: true,
        };
        handlePayNow(data);
      }
    }
  };
  const handleBuildAgreement = async (dataItem: any) => {
    setDisable(true);
    let result = null;
    if (checkoutType === "service") {
      const buildservice = new TenantService();
      result = await buildservice.buildAgreement(dataItem);
    } else {
      const buildservice = new PackageService();
      result = await buildservice.buildAgreement(dataItem);
    }
    setDisable(false);
    let template = "";
    if (result?.ResponseCode === 100) {
      if (checkoutType === "service") {
        template = result?.Item?.AgreementTemplate;
      } else {
        template = result?.Item;
      }
      setBuildagreement({
        ...buildagreement,
        AgreementTemplate: template,
        TermsAndConditionAccepted: true,
      });
      setAgreementTemplate(template);
      setVisibleAgreement(true);
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const rendercheckoutDialog = () => {
    const { Name, NumberOfSessions, Duration } = checkoutService.service;
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Ready to Book Appointment"}
        onClose={() => toggleCheckoutDialog(checkoutService.service)}
        width={"35%"}
      >
        <div className="ready-to-book-appt-dialog">
          <div className="column-1-section">
            One-on-One<span className="purchased-dots">:</span>
          </div>
          <div className="column-2-section">{Name}</div>
          <div className="column-1-section">
            Available Appointments<span className="purchased-dots">:</span>
          </div>
          <div className="column-2-section">{NumberOfSessions}</div>
          <div className="column-1-section">
            One-on-One Duration<span className="purchased-dots">:</span>
          </div>
          <div className="column-2-section">{Duration} Min</div>
        </div>
        <div className="column-button-section book-appt-btns">
        {!guestLayout && (
            <Button primary={false} onClick={() => handleNotNowRedirection()}>
              Not Now
            </Button>
          )}
          <BuzopsButton
            label={"Continue >>>"}
            onClick={() => toggleCheckoutDialog(checkoutService.service)}
          />
        </div>
      </Dialog>
    );
  };
  const handleChangeAdditionalPaymentProfile = async (value: any) => {
    setShowAdditionalPayment({
      ...showAdditionalPayment,selectedPaymentProfile:value
    })
  }


  const renderCustomRateChange = () => {
    const { Name, NumberOfSessions, Duration } = serviceData;
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Modify the Per Appointment Rate"}
        onClose={() => setCustomRateDialog(initialCustomDialogVal)}
        width={"35%"}
      >
        <div className="ready-to-book-appt-dialog">
          <div className="column-1-section">
            One-on-One<span className="purchased-dots">:</span>
          </div>
          <div className="column-2-section">{Name}</div>
          <div className="column-1-section">
            Available Appointments<span className="purchased-dots">:</span>
          </div>
          <div className="column-2-section">{NumberOfSessions}</div>
          <div className="column-1-section">
            One-on-One Duration<span className="purchased-dots">:</span>
          </div>
          <div className="column-2-section">{Duration} Min</div>
          <div className="column-1-section">
          Per Appointment Rate<span className="purchased-dots">:</span>
          </div>
          <div className="column-2-section"> <NumericTextBox
              min={0}
              id={"Rate"}
              name={"Rate"}
              placeholder={"Per Appointment Rate"}
              className="k-dropdown-width-100"
              value={CustomRateDialog?.Rate}
              width={"100%"}
              format={"c2"}
              onChange={(e: any) =>
                handleChangeCustomServiceRate(
                  e.target.value,"Rate"
                )
              }
            />
            {CustomRateDialog?.Rate <= 0 && (
              <Error>{"Please enter Per Appointment Rate"}</Error>
            )}</div>
        </div>
        <div className="column-button-section book-appt-btns">
          <Button primary={false} onClick={() =>  setCustomRateDialog(initialCustomDialogVal)}>
            Cancel
          </Button>
          <BuzopsButton
            label={"Update"}
            onClick={() => handleCustomRate()}
          />
        </div>
      </Dialog>
    );
  };
  

  const handleAddAdditionalPayment=()=>{
    if(!showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID){
      const errorMsg = "Please select/add Payment profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return
    }
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,AlternatePaymentProfileId:showAdditionalPayment?.selectedPaymentProfile?.PaymentGatewayPaymentProfileID
    })
    setShowAdditionalPayment(initialAdditionalPayment)
  }

  const handleClientConfirmation=(page:string,cbParams:any=null)=>{
    if (checkoutType === "service") {
      const check = checkoutValidation();
      if (check) {
        return;
      }
    } else if (
      checkoutType === "inhouse-package" ||
      checkoutType === "online-package"
    ) {
      const check = checkoutPackageValidation();
      if (check) {
        return;
      }
    } else {
      const check = checkoutClassValidation();
      if (check) {
        return;
      }
    }
    if (AllClientsList?.length > 1 && checkoutType !== "online-class") {
      // const selectedClientInfo=AllClientsList?.filter(
      //   (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
      // )[0]
      // if(selectedClientInfo?.MemberType===0){
        setPurchaseCheckout({
          ...purchaseCheckout,page:page,params:cbParams
        })
        toggleClientConfirmDialog()
        return
      // }else{
      //   handleCheckoutPage(page,cbParams)
      // }
    }else{
      handleCheckoutPage(page,cbParams)
    }
  }

  const renderClientConfirmationDialog = () => {
    const selectedClientInfo=AllClientsList?.filter(
      (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
    )[0]
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}
      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
          <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton
              label={"Yes"}
              onClick={() =>
                handleCheckoutPage(
                  purchaseCheckout?.page,
                  purchaseCheckout?.params
                )
              }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  const handleCloseClassCheckout = () => {
    if(props?.location?.state?.from ==="calender"){
      props.handleWindow();
      return true;
    }
    if (checkoutType === "inhouse-class") {
      if (props?.type === "bulk") {
        props?.handleParentWindow();
      } else {
        props?.handleWindow();
      }
    } else {
      if (!staffPage) {
        handleSummaryRedirection()
      } else {
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.PrimaryMemberId}/gsappointments`;
        history.push(urlRedirection);
      }
    }
  };

  const renderClassCheckoutDialog = () => {
    const classSessionData=submittedClassSessions?.filter((i:any)=> i?.CanCollectTaxes)
    const checkCanBookAsWaitlist=submittedClassSessions?.filter((i:any)=> i?.CanBookAsWaitlist)
    let taxEnabled=false
    if(classSessionData?.length > 0 && checkHasTaxEnable()){
      taxEnabled=true
    }
    let CanBookAsWaitlist=false
    if(checkCanBookAsWaitlist?.length > 0){
      CanBookAsWaitlist=true
    }
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-purchaseClassSessionSuccess bz-groupEvent-success bz-colon-centered"
        title={"Purchased Group Successfully"}
        onClose={() => handleCloseClassCheckout()}
      >
        <div>
        {CanBookAsWaitlist && <div
          className="alert alert-warning fade show"
          role="alert"
        >{payLaterAmount > 0  ? `Thank you for joining the waitlist! Your spot is reserved, and payment will be collected once your booking is confirmed. You will receive an email once confirmed.`:`Thank you for joining the waitlist! Your spot is reserved. You will receive an email once confirmed.`}</div>}
        <div className="ready-to-book-appt-dialog">
          <div className="column-1-section">Client</div>
          <span>:</span>
          <div className="column-2-section">
            {`${clientSubmitDetails?.FirstName} ${clientSubmitDetails?.LastName}`}
          </div>
          <div className="column-1-section">Total Group Enrolled</div>
          <span>:</span>
          <div className="column-2-section">{totalClassSessions}</div>
     
          {useCreditAmount > 0 && (
            <>
              <div className="column-1-section">Redeemed via Package</div>
              <span>:</span>
              <div className="column-2-section">
                {CurrencyFormat(useCreditAmount)}
              </div>
            </>
          )}
          {useComplimentaryAmount > 0 && (
            <>
              <div className="column-1-section">Redeemed via Complimentary</div>
              <span>:</span>
              <div className="column-2-section">
                {CurrencyFormat(useComplimentaryAmount)}
              </div>
            </>
          )}
          {payLaterAmount > 0 && (
            <>
            <div className="column-1-section">Pay at Class</div>
            <span>:</span>
            <div className="column-2-section">
            {CurrencyFormat(
                payLaterAmount + calculateTotalSessionTax(true) - calculateTotalSessionTax()
              )}
            </div>
            </>
          )}
          {taxEnabled ? (<>
            <div className="column-1-section">Pay Today (Inclusion of Surcharges)</div>
          <span>:</span>
          <div className="column-2-section">
            {CurrencyFormat(downpaymentForm.paidToday + calculateTotalSessionTax())}
          </div>
          </>):(<><div className="column-1-section">Pay Today</div>
          <span>:</span>
          <div className="column-2-section">
            {CurrencyFormat(packageCostAfterDiscount)}
          </div></>)}
          <div className="dialog-btn-blk">
            <BuzopsButton
              className={"float-right mt-3 mr-0"}
              label={"Okay"}
              onClick={() => handleCloseClassCheckout()}
              />
          </div>
        </div>
        </div>
      </Dialog>
    );
  };
  // const handleTermsConditionDialog = () => {
  //   const check = checkoutClassValidation(false);
  //   if (check) {
  //     return;
  //   }
  //   setTermsandConditionsDialog(!termsandConditionsDialog);
  // };

  const handleTermsConditionDialog = (val:any) => {
    const check = checkoutClassValidation(false);
    if (check) {
      return;
    }
    if(val === true || val === false){
      setTermsandConditions(val);
    }else{
      setTermsandConditionsDialog(!termsandConditionsDialog);
    }
    
  };


  const toggleCheckoutDialog = (service: any) => {
    setCheckoutService({ ...checkoutService, showDialog: false });
    if (guestLayout) {
      if(redirectionObj?.firstPathValue === "external"){
        history.push(`/${redirectionObj?.firstPathValue}/${providerId}/mybooking`,{
          provider: props.location.state.provider,
          selectedService: service,
          staffPage: false,
        });
      }else{
        history.push(`/${providerId}/mybooking`, {
          provider: props.location.state.provider,
          selectedService: service,
          staffPage: false,
        });
      }

    } else {
      if (!staffPage) {
        if(redirectionObj?.firstPathValue === "external"){
          history.push(`/${redirectionObj?.firstPathValue}/mybooking`, { selectedService: service,staffPage:false });
        }else{
          history.push(`/mybooking`, { selectedService: service,staffPage:false });
        }
      } else {
        history.push(
          `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}/mybooking`,
          {
            selectedService: service,
            staffPage: true,
            userData: props?.location?.state?.userData,
            uuid: props?.location?.state?.uuid,
          }
        );
      }
    }
  };
  
  const toggleRedeemPackage = (packageDetails: any) => {
    if(staffPage){
      if(props?.location?.state?.from==="calender"){
        props?.handleClose(packageDetails)
        return 
      }
      const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
      history.push(urlRedirection, {
        redeemPackage: true,
        packageDetails: packageDetails,
      });
    }else{
      handleSummaryRedirection({
        redeemPackage: true,
        packageDetails: packageDetails,
      })
    }
  };

  const togglePackageCheckoutDialog = (PucrhasedPackage:any=null) => {
    setCheckoutPackage({ ...checkoutPackage, showDialog: false });
    if (checkoutType === "inhouse-package") {
      props.handleWindow();
    } else {
      if (!staffPage) {
        handleSummaryRedirection()
      } else {
        if(props?.location?.state?.from==="calender"){
          props?.handleClose(PucrhasedPackage)
          return 
        }
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
        history.push(urlRedirection);
      }
    }
  };

  const handleAddMoreClasses = () => {
    const AddMoreClasses=  session?.map((item: any, index: number) => {
      const modifiedValues=item?.values.map((j:any)=>{
        return {...j,Complimentary:false}
      })
      return { ...item, values:modifiedValues};
    });
    if (
      checkoutType === "inhouse-class" ||
      checkoutType === "inhouse-package"
    ) {
      props.handleWindow();
    } else if (
      checkoutType === "online-class" ||
      checkoutType === "online-package"
    ) {
      if (!staffPage) {
        handleSummaryRedirection({ AddMoreClasses: AddMoreClasses, selectedUserMemberId: props?.location?.state?.userData?.UserMemberId })
      } else {
        if(props?.location?.state?.from==="calender"){
          props?.handleClose(AddMoreClasses)
          return 
        }
        const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.PrimaryMemberId || props?.location?.state?.userData?.UserMemberId}`;
        history.push(urlRedirection, { AddMoreClasses: AddMoreClasses, selectedUserMemberId: props?.location?.state?.userData?.UserMemberId });
      }
    }
  };
  const handleNotNowRedirection = () => {
    if (guestLayout) {
      if(redirectionObj?.firstPathValue === "external"){
        history.push(`/${redirectionObj?.firstPathValue}/${providerId}`);
      }else if(redirectionObj?.secondRoute !== ""){
        history.push(`/${redirectionObj?.firstPathValue}/${redirectionObj?.secondRoute}`);
      }else{
        history.push(`/${providerId}`);
      }
    } else {
      if (!staffPage) {
        if (checkoutType === "online-class") {
          handleSummaryRedirection({ AddMoreClasses: session, selectedUserMemberId: props?.location?.state?.userData?.UserMemberId })
        } else if (checkoutType === "online-package") {
          handleSummaryRedirection({ purchasepackage: true })
        } else if (checkoutType === "service") {
          handleSummaryRedirection({ purchaseoneonone: true })
        } else {
          handleSummaryRedirection()
        }
      } else {
        if(props?.location?.state?.from ==="calender"){
          props?.handleClose(session)
          return
        }
        if (checkoutType === "online-class") {
          const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.PrimaryMemberId || props?.location?.state?.userData?.UserMemberId}`;
          history.push(urlRedirection, { AddMoreClasses: session, selectedUserMemberId: props?.location?.state?.userData?.UserMemberId });
        } else if (checkoutType === "online-package") {
          const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
          history.push(urlRedirection, { purchasepackage: true });
        } else if (checkoutType === "service") {
          const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
          history.push(urlRedirection, { purchaseoneonone: true });
        } else {
          const urlRedirection = `/member/${props?.location?.state?.uuid}/${props?.location?.state?.userData?.UserMemberId}`;
          history.push(urlRedirection);
        }
      }
    }
  };

  const renderPackageCheckoutDialog = () => {
    const { Name} = checkoutPackage.package;
    const filterClass = checkoutPackage?.package?.Classes?.filter(
      (i: any) => i.ClassId === null
    );
    let AvailbleGS: any = checkoutPackage?.package?.Classes?.length || 0;
    if (filterClass?.length > 0) {
      AvailbleGS = "All";
    }
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-purchaseClassSessionSuccess bz-groupEvent-success bz-colon-centered"
        title={"Purchased Packages Successfully"}
        onClose={() => togglePackageCheckoutDialog(checkoutPackage?.package)}
      ><>
        <div className="ready-to-book-appt-dialog">
          <div className="column-1-section"> Package Name</div>
          <span>:</span>
          <div className="column-2-section">
          {Name}
          </div>
          <div className="column-1-section">Available Group</div>
          <span>:</span>
          {/* <div className="column-2-section">{AvailbleGS}</div> */}
          <div className="column-2-section">{checkoutPackage?.package?.SessionType === 2 ? "Unlimited" : checkoutPackage?.package?.Schedules}</div>
          {(checkHasTaxEnable() && !IsTaxSkipped && Taxes?.length >0) ? (<><div className="column-1-section">Pay Today 
       
          </div>
            <span>:</span>
            <div className="column-2-section">
              {CurrencyFormat(downpaymentForm.paidToday + TotalTaxAmount)}
            </div></>) : (<><div className="column-1-section">Pay Today</div>
              <span>:</span>
              <div className="column-2-section">
                {CurrencyFormat(downpaymentForm.paidToday)}
              </div></>)}
          {packag?.ClientCanSkipPayment && (
            <>
              <div className="column-1-section">{`Pay On ${moment(packag?.StartDate).format("MM/DD/YYYY")}`}</div>
              <span>:</span>
              <div className="column-2-section">
                {CurrencyFormat(!IsTaxSkipped ? packageCostAfterDiscount + PredictedTotalTaxAmount: packageCostAfterDiscount)}
              </div>
            </>
          )}
            </div>
            <div className="d-flex justify-content-center flex-column align-items-center">
                <div className={"text-center mr-0 mt-3 mt-sm-0 mt-lg-3"}>
                Appointments available for redemption with this Group Package
              </div>
                <div className="text-center">
              <a
                  className="btn btn-link text-primary mt-2"
                  onClick={() => toggleRedeemPackage(checkoutPackage?.package)}
                >
                  <u>Click here to Enroll</u>
                </a>
              </div>
            {props?.location?.state?.from==="calender" ? null: (<>
              <div className="text-center">
                <i>(or)</i>
              </div>
              <div className="text-center">

                <a
                  className={"btn btn-link  mr-0 text-primary "}
                  onClick={() => togglePackageCheckoutDialog(checkoutPackage?.package)}
                >
                  <u>Access Dashboard</u>
                </a>
              </div>
            </>)}
            </div>
        </>
      </Dialog>
    );
  };

  const checkBillingPeriods= (packageItem:any,date:any)=> {
    if(packageItem?.SessionType===1){
      const BillingPeriods=packageItem?.BillingPeriods
      return BillingPeriods.some((i:any) => {
          return (
            moment(date).format('YYYY-MM-DD') >= moment(i?.ExpireFrom).format('YYYY-MM-DD') && moment(date).format('YYYY-MM-DD') <= moment(i?.ExpireOn).format('YYYY-MM-DD') && 
              i.SchedulesToUse > 0
          );
      });
    }else {
      return true
    }
  }

  const getExactBillingPeriod=(packageItem:any,date:any)=> {
    const BillingPeriods=packageItem?.BillingPeriods
    const dataItem= BillingPeriods.find((i:any) => {
            if(moment(date).format('YYYY-MM-DD') >= moment(i?.ExpireFrom).format('YYYY-MM-DD') && moment(date).format('YYYY-MM-DD') <= moment(i?.ExpireOn).format('YYYY-MM-DD')){
                return true
              }
              return false
      });
      return dataItem
  }
  const checkUseCredit = (item: any, selectedPackage: any = null,packListToUse=packageListToUse) => {
    if (!selectedPackage) {
      const test = packListToUse.findIndex((val: any) =>
        val.ClassSessions.find(
          (val1: any) => val1.ClassSessionId === item.ClassSessionId 
        )
      );
      // if(test !==-1){
      //   const selectedPack=packListToUse[test]
      //   if(checkBillingPeriods(selectedPack,item?.ClassSessionStartDate)){
      //     return true
      //   }
      //   return false
      // }
      return test !== -1;
    } else {
      const test = packListToUse.findIndex(
        (val: any) =>
          selectedPackage?.PackageInstanceId === val?.PackageInstanceId &&
          val.ClassSessions.find(
            (val1: any) => val1.ClassSessionId === item.ClassSessionId
          ) && checkBillingPeriods(selectedPackage,item?.ClassSessionStartDate)
      );
      return test !== -1;
    }
  };
  const checkSkipPayment = (sessionId: any) => {
    let sessions: any = [];
    session.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    const sessionIds = sessions?.findIndex(
      (kkk: any) =>
        kkk?.ClassSessionId === sessionId &&
        (kkk?.PaymentOption === 2 || kkk?.PaymentOption === 3 || kkk?.ClassType !== 1)
    );
    return sessionIds !== -1;
  };
  const checkPaymentProfileNotRequired = (submit=false) => {
    let sessions: any = [];
    session.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    let check = sessions.every((item: any) => item?.PaymentOption === 3);
    // console.log(sessions,'sessions')

    if (!check) {
      if (downpaymentForm?.paidToday === 0 &&  packageCost > 0 && packageCost === useCreditAmount + useComplimentaryAmount) {
        // console.log("hello",'hello')
        check = true;
      }
    }
    // console.log(check,'Check')
    return check;
  };
  const checkOfflineReferenceRequired = () => {
    let sessions: any = [];
    session.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    let check = sessions.every((item: any) => item?.PaymentOption === 3 || item?.PaymentOption === 2);

    if (!check) {
      if (downpaymentForm?.paidToday === 0 &&  packageCost > 0 && packageCost === useCreditAmount + useComplimentaryAmount) {
        check = true;
      }
    }
    return check;
  };

  function findBillingPeriod(ClassSessionStartDate:any, BillingPeriods:any) {
    const sessionDate = new Date(ClassSessionStartDate);
  
    return BillingPeriods.find((period:any) => {
      const expireFrom = new Date(period.ExpireFrom);
      const expireOn = new Date(period.ExpireOn);
      expireOn.setHours(23);
      expireOn.setMinutes(59);
      expireOn.setSeconds(0);
  
      return sessionDate >= expireFrom && sessionDate <= expireOn;
    });
  }

  const CheckExactPackageItem = (dataItem: any,sessionItem:any,packageList=packageListToUse) => {
    const IsPastDate = moment(sessionItem?.ClassSessionStartDate).isBefore(moment(), 'day');
    const packList=sessionItem?.PackagesList?.filter((i:any)=>{
      const billingPeriod = findBillingPeriod(sessionItem?.ClassSessionStartDate, i?.BillingPeriods);
      const IsPastDate = moment(sessionItem?.ClassSessionStartDate).isBefore(moment(), 'day');

      const { ExpireFrom, ExpireOn } = IsPastDate && billingPeriod !== undefined
        ? billingPeriod
        : i;
      if(moment(sessionItem?.ClassSessionStartDate).format('YYYY-MM-DD') >= moment(ExpireFrom).format('YYYY-MM-DD') && moment(sessionItem?.ClassSessionStartDate).format('YYYY-MM-DD') <= moment(ExpireOn).format('YYYY-MM-DD')){
        return i
      }
    })
    const UnlimitedPackages = packList.filter(
      (i: any) => i?.SessionType === 2
    );
    const LimitedPackages = packList.filter(
      (i: any) => i?.SessionType === 1
    );

    if (UnlimitedPackages?.length === 1) {
      return UnlimitedPackages[0];
    }
    if (UnlimitedPackages?.length > 1) {
      const selectedMinExpireOnPackage = UnlimitedPackages.reduce(
        (min: any, item: any) => {
          return new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min;
        },
        UnlimitedPackages[0]
      );
      return selectedMinExpireOnPackage;
    }
    const checkSchedulestoUse=packageList?.filter((i:any)=>i?.SessionType === 1 && i?.SchedulesToUse >0)
    const updatedLimitedPackage= LimitedPackages?.filter((j:any)=>{
      const check = checkSchedulestoUse.findIndex((k:any)=>k?.PackageInstanceId===j?.PackageInstanceId);
      if(check ===-1){
        return false
      }
      return true
    })

    if (updatedLimitedPackage?.length > 0) {
      const selectedMinExpireOnPackage = updatedLimitedPackage.reduce(
        (min: any, item: any) => {
          const minExpireOn=min?.PackageExpireOn ?  min?.PackageExpireOn : min?.ExpireOn
          const itemExpireOn=item?.PackageExpireOn ?  item?.PackageExpireOn : item?.ExpireOn

          return new Date(minExpireOn) > new Date(itemExpireOn) ? item : min;
        },
        updatedLimitedPackage[0]
      );
      return selectedMinExpireOnPackage;
    }
    return null;
  };

  async function handleAutoselectPackage (sessionItems=session,packageToUse=packageListToUse) {
    let sessionArray:any=sessionItems
    let updatedPackageListToUse:any=packageToUse
    let amount=packageCostAfterDiscount
    let payLaterAmt=payLaterAmount
    let complimentaryAmt=useComplimentaryAmount
    let packageRedeemAmt=useCreditAmount
    for (const item of sessionItems) {
      if(item?.values?.length > 0){
        for (const sessionItem of item?.values) {
          if(sessionItem?.PackagesList && sessionItem?.PackagesList?.length > 0){
            const selectedPackage=sessionItem?.SelectedPackage || CheckExactPackageItem(item,sessionItem,updatedPackageListToUse)
            if(selectedPackage){
                const updatedSession:any=await handlePackageSelectionForClass(selectedPackage,sessionItem,sessionArray,amount,payLaterAmt,updatedPackageListToUse,packageRedeemAmt,complimentaryAmt)
                sessionArray=updatedSession?.SessionArray
                amount=updatedSession?.amount
                payLaterAmt=updatedSession?.payLaterAmt
                updatedPackageListToUse=updatedSession?.updatedPackageListToUse
                packageRedeemAmt=updatedSession?.packageRedeemAmt
                complimentaryAmt=updatedSession?.complimentaryAmt
            }
          }
        }
      }
    }
  }

  const handlePackageSelectionForClass = (event: any, item: any,mainSession=session,amnt=packageCostAfterDiscount,payLatAmt=payLaterAmount,packListToUse=packageListToUse,packageRedeem=useCreditAmount,complimentaryamtVal=useComplimentaryAmount) => {
    setIsFormChanged(true);
    const e = event;
    let amount = amnt;
    let payLaterAmt = payLatAmt;
    let complimentaryAmt=complimentaryamtVal
    let packageRedeemAmt=packageRedeem
    const SessionArray = mainSession.map((j: any) => {
      if (j.ClassId === item?.ClassId) {
        const modifiedValues=j.values?.map((sch:any)=>{
          if (sch.ClassSessionId === item?.ClassSessionId) {
            if(sch?.Complimentary){
              if (sch?.PaymentOption <= 1 && !sch?.CanBookAsWaitlist) {
                amount = amount + sch?.Cost;
              } else {
                payLaterAmt = payLaterAmt + sch?.Cost;
              }
              complimentaryAmt = complimentaryAmt - sch?.Cost;
            }
            return { ...sch,SelectedPackage:e,Complimentary:false };
          }
          return { ...sch };
        })
        return { ...j, values: modifiedValues };
      } else {
        return { ...j };
      }
    });
    let sessions: any = [];
    session.forEach((j: any) => {
      if (j.ClassId === item?.ClassId) {
        j?.values?.forEach((k: any) => {
          sessions.push(k);
        });
      }
    });
    const sessionIds = sessions?.map((kkk: any) => kkk?.ClassSessionId);
    

    const updatedPackageListToUse: any = packListToUse.map((i: any) => {
      let previousSessions = i.ClassSessions;
      let sessionCount = i.SchedulesToUse;
      const exactBillingPeriod=getExactBillingPeriod(i,item?.ClassSessionStartDate)
      if(i?.SessionType===1){

        sessionCount=exactBillingPeriod?.SchedulesToUse 
      }

      if (i.PackageInstanceId===e?.PackageInstanceId) {
        sessions.forEach((kk: any) => {
          if (!checkUseCredit(kk,e,packListToUse) && item?.ClassSessionId ===kk?.ClassSessionId) {
            const billingPeriod = findBillingPeriod(kk?.ClassSessionStartDate, i.BillingPeriods);
            const IsPastDate = moment(kk?.ClassSessionStartDate).isBefore(moment(), 'day');
            
            let { ExpireFrom, ExpireOn } = IsPastDate && billingPeriod !== undefined
              ? billingPeriod
              : i;

            sessionCount = IsPastDate ? billingPeriod?.SchedulesToUse  : i.SchedulesToUse;
             
            if (
              moment(ExpireOn).format('YYYY-MM-DD') >=
              moment(kk?.ClassSessionStartDate).format('YYYY-MM-DD') && moment(ExpireFrom).format('YYYY-MM-DD') <=
              moment(kk?.ClassSessionStartDate).format('YYYY-MM-DD')
            ) {
              if (
                (i?.SessionType === 1 && sessionCount > 0) ||
                i?.SessionType === 2
              ) {
                previousSessions.push(kk);
                if (i?.SessionType === 1) {
                  sessionCount = sessionCount - 1;
                }

                if (kk?.PaymentOption <= 1 && !kk?.CanBookAsWaitlist) {
                  amount = amount - kk?.Cost;
                } else {
                  payLaterAmt = payLaterAmt - kk?.Cost;
                }
                packageRedeemAmt=packageRedeemAmt+kk?.Cost
              }
            }
          }
        });
        if(i?.SessionType===1){
          const billingPeriods=i?.BillingPeriods?.map((i:any)=> {
            if(exactBillingPeriod?.ExpireFrom ===i?.ExpireFrom && exactBillingPeriod?.ExpireTo ===i?.ExpireTo && exactBillingPeriod?.PackageInstanceId ===i?.PackageInstanceId){
              return {...i,SchedulesToUse:sessionCount}
            }
            return {...i}
          })
          return {
            ...i,
            ClassSessions: previousSessions,
            SchedulesToUse: sessionCount,
            BillingPeriods:billingPeriods
          };
        }else{
          return {
            ...i,
            ClassSessions: previousSessions,
            SchedulesToUse: sessionCount,
          };
        }
      } else {
        // let previousSessions=i.ClassSessions
        // let sessionCount=i.SchedulesRemaining
        if (sessionIds.length > 0 && i.ClassSessions.length > 0) {
          previousSessions = i.ClassSessions.filter((j: any) => {
            if (j.ClassSessionId===item?.ClassSessionId) {
              if (i?.SessionType === 1) {
                sessionCount = sessionCount + 1;
              }

              if (j?.PaymentOption <= 1 && !j?.CanBookAsWaitlist) {
                amount = amount + j?.Cost;
              } else {
                payLaterAmt = payLaterAmt + j?.Cost;
              }
              packageRedeemAmt=packageRedeemAmt-j?.Cost
              return false;
            } else {
              return true;
            }
          });
          if(i?.SessionType===1){
            const billingPeriods=i?.BillingPeriods?.map((i:any)=> {
              if(exactBillingPeriod?.ExpireFrom ===i?.ExpireFrom && exactBillingPeriod?.ExpireTo ===i?.ExpireTo && exactBillingPeriod?.PackageInstanceId ===i?.PackageInstanceId){
                return {...i,SchedulesToUse:sessionCount}
              }
              return {...i}
            })
            return {
              ...i,
              ClassSessions: previousSessions,
              SchedulesToUse: sessionCount,
              BillingPeriods:billingPeriods
            };
          }else{
            return {
              ...i,
              ClassSessions: previousSessions,
              SchedulesToUse: sessionCount,
            };
          }
        } else {
          return { ...i };
        }
      }
    });
    setPackageCostAfterDiscount(amount);
    // setPackageCost(amount)
    setPayLaterAmount(payLaterAmt);
    setUseCreditAmount(packageRedeemAmt)
    setUseComplimentaryAmount(complimentaryAmt)
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setPackageListToUse(updatedPackageListToUse);
    handleSessionTaxCalculation(SessionArray,Taxes,updatedPackageListToUse)
    console.log(updatedPackageListToUse,'updatedPackageListToUse')
    return {SessionArray,amount,payLaterAmt,updatedPackageListToUse,packageRedeemAmt,complimentaryAmt}
  };


  const handleComplimentary = (val: any, sessionItem: any) => {
    let complimentaryAmt=useComplimentaryAmount
    let packageRedeemAmt=useCreditAmount
    let amount = packageCostAfterDiscount;
    let payLaterAmt = payLaterAmount;
    let mainSession=[...session]
    let updatedPkListToUse:any=packageListToUse;
    if(val){
      if(checkUseCredit(sessionItem)){
        updatedPkListToUse = packageListToUse?.map((i: any) => {
          if (sessionItem?.SelectedPackage?.PackageInstanceId === i.PackageInstanceId) {
            let sessions = i.ClassSessions;
            let sessionCount = i.SchedulesToUse || 0;
            const exactBillingPeriod=getExactBillingPeriod(i,sessionItem?.ClassSessionStartDate)
            if(i?.SessionType===1){
              sessionCount=exactBillingPeriod?.SchedulesToUse || 0 
            }
              sessions = sessions.filter((j: any) => {
                if (j.ClassSessionId === sessionItem.ClassSessionId) {
                  if (i?.SessionType === 1) {
                    sessionCount = sessionCount + 1;
                  }
                  if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                    amount = amount + sessionItem?.Cost;
                  } else {
                    payLaterAmt = payLaterAmt + sessionItem?.Cost;
                  }
                  packageRedeemAmt = packageRedeemAmt - sessionItem?.Cost;
                  return false;
                }
                return true;
              });
            // return { ...i, ClassSessions: sessions, SchedulesToUse: sessionCount };

            if(i?.SessionType===1){
              const billingPeriods=i?.BillingPeriods?.map((i:any)=> {
                if(exactBillingPeriod?.ExpireFrom ===i?.ExpireFrom && exactBillingPeriod?.ExpireTo ===i?.ExpireTo && exactBillingPeriod?.PackageInstanceId ===i?.PackageInstanceId){
                  return {...i,SchedulesToUse:sessionCount}
                }
                return {...i}
              })
              return {
                ...i,
                ClassSessions: sessions,
                SchedulesToUse: sessionCount,
                BillingPeriods:billingPeriods
              };
            }else{
              return {
                ...i,
                ClassSessions: sessions,
                SchedulesToUse: sessionCount,
              };
            }
          } else {
            return { ...i };
          }
        });
      }
      mainSession = mainSession.map((j: any) => {
        if (j.ClassId === sessionItem?.ClassId) {
          const modifiedValues=j.values?.map((sch:any)=>{
            if (sch.ClassSessionId === sessionItem?.ClassSessionId) {
              if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                amount = amount - sessionItem?.Cost;
              } else {
                payLaterAmt = payLaterAmt - sessionItem?.Cost;
              }
              complimentaryAmt = complimentaryAmt + sessionItem?.Cost;
              return { ...sch,Complimentary:val };
            }
            return { ...sch };
          })
          return { ...j, values: modifiedValues };
        } else {
          return { ...j };
        }
      });
    }else{
      mainSession = mainSession.map((j: any) => {
        if (j.ClassId === sessionItem?.ClassId) {
          const modifiedValues=j.values?.map((sch:any)=>{
            if (sch.ClassSessionId === sessionItem?.ClassSessionId) {
                if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                  amount = amount + sessionItem?.Cost;
                } else {
                  payLaterAmt = payLaterAmt + sessionItem?.Cost;
                }
                complimentaryAmt = complimentaryAmt - sessionItem?.Cost;
              return { ...sch,Complimentary:val };
            }
            return { ...sch };
          })
          return { ...j, values: modifiedValues };
        } else {
          return { ...j };
        }
      });
    }
    // setSession(mainSession)
    handleSessionTaxCalculation(mainSession)
    setPackageCostAfterDiscount(amount);
    setPayLaterAmount(payLaterAmt);
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setUseCreditAmount(packageRedeemAmt)
    setUseComplimentaryAmount(complimentaryAmt)
    setPackageListToUse(updatedPkListToUse);

  }



  const handleUseCredit=(val: any, sessionItem: any)=>{
    let check = true;
    let amount = packageCostAfterDiscount;
    let payLaterAmt = payLaterAmount;
    let complimentaryAmt=useComplimentaryAmount
    let packageRedeemAmt=useCreditAmount
    let updatedPkListToUse:any=packageListToUse;
    let mainSession=[...session]
    if(val){
      if(sessionItem?.Complimentary){
        mainSession = mainSession.map((j: any) => {
          if (j.ClassId === sessionItem?.ClassId) {
            const modifiedValues=j.values?.map((sch:any)=>{
              if (sch.ClassSessionId === sessionItem?.ClassSessionId) {
                  if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                    amount = amount + sessionItem?.Cost;
                  } else {
                    payLaterAmt = payLaterAmt + sessionItem?.Cost;
                  }
                  complimentaryAmt=complimentaryAmt-sessionItem?.Cost
                return { ...sch,Complimentary:false };
              }
              return { ...sch };
            })
            return { ...j, values: modifiedValues };
          } else {
            return { ...j };
          }
        });
      }
      updatedPkListToUse = packageListToUse?.map((i: any) => {
        if (sessionItem?.SelectedPackage?.PackageInstanceId === i.PackageInstanceId) {
          let sessions = i.ClassSessions;
          let sessionCount = i.SchedulesToUse;
          const exactBillingPeriod=getExactBillingPeriod(i,sessionItem?.ClassSessionStartDate)
            if(i?.SessionType===1){
              sessionCount=exactBillingPeriod?.SchedulesToUse 
            }
            const expireOn=i?.PackageExpireOn ?  i?.PackageExpireOn : i?.ExpireOn
            if (
              moment(expireOn).format('YYYY-MM-DD') >=
              moment(sessionItem?.ClassSessionStartDate).format('YYYY-MM-DD') && moment(i?.ExpireFrom).format('YYYY-MM-DD') <=
              moment(sessionItem?.ClassSessionStartDate).format('YYYY-MM-DD')
            ) {
              if (
                (i?.SessionType === 1 && sessionCount > 0) ||
                i?.SessionType === 2
              ) {
                sessions.push(sessionItem);
                if (i?.SessionType === 1) {
                  sessionCount = sessionCount - 1;
                }
                if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                  amount = amount - sessionItem?.Cost;
                } else {
                  payLaterAmt = payLaterAmt - sessionItem?.Cost;
                }
                packageRedeemAmt=packageRedeemAmt+sessionItem?.Cost
                check = true;
              } else {
                check = false;
              }
            } else {
              check = false;
            }
          // return { ...i, ClassSessions: sessions, SchedulesToUse: sessionCount };

          if(i?.SessionType===1){
            const billingPeriods=i?.BillingPeriods?.map((i:any)=> {
              if(exactBillingPeriod?.ExpireFrom ===i?.ExpireFrom && exactBillingPeriod?.ExpireTo ===i?.ExpireTo && exactBillingPeriod?.PackageInstanceId ===i?.PackageInstanceId){
                return {...i,SchedulesToUse:sessionCount}
              }
              return {...i}
            })
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
              BillingPeriods:billingPeriods
            };
          }else{
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
            };
          }
        } else {
          return { ...i };
        }
      });
    }else{
      updatedPkListToUse = packageListToUse?.map((i: any) => {
        if (sessionItem?.SelectedPackage?.PackageInstanceId === i.PackageInstanceId) {
          let sessions = i.ClassSessions;
          let sessionCount = i.SchedulesToUse;
          const exactBillingPeriod=getExactBillingPeriod(i,sessionItem?.ClassSessionStartDate)
          if(i?.SessionType===1){
            sessionCount=exactBillingPeriod?.SchedulesToUse 
          }
            sessions = sessions.filter((j: any) => {
              if (j.ClassSessionId === sessionItem.ClassSessionId) {
                if (i?.SessionType === 1) {
                  sessionCount = sessionCount + 1;
                }
                if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                  amount = amount + sessionItem?.Cost;
                } else {
                  payLaterAmt = payLaterAmt + sessionItem?.Cost;
                }
                packageRedeemAmt=packageRedeemAmt-sessionItem?.Cost
                check = true;
                return false;
              }
              return true;
            });
          if(i?.SessionType===1){
            const billingPeriods=i?.BillingPeriods?.map((i:any)=> {
              if(exactBillingPeriod?.ExpireFrom ===i?.ExpireFrom && exactBillingPeriod?.ExpireTo ===i?.ExpireTo && exactBillingPeriod?.PackageInstanceId ===i?.PackageInstanceId){
                return {...i,SchedulesToUse:sessionCount}
              }
              return {...i}
            })
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
              BillingPeriods:billingPeriods
            };
          }else{
            return {
              ...i,
              ClassSessions: sessions,
              SchedulesToUse: sessionCount,
            };
          }
        } else {
          return { ...i };
        }
      });
    }
    // setSession(mainSession)
    handleSessionTaxCalculation(mainSession,Taxes,updatedPkListToUse)
    setPackageCostAfterDiscount(amount);
    setPayLaterAmount(payLaterAmt);
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setUseCreditAmount(packageRedeemAmt)
    setUseComplimentaryAmount(complimentaryAmt)
    setPackageListToUse(updatedPkListToUse);

    if (!check) {
      props?.handleNotificationMessage(
        "Appointments are beyond the expiry date or reached the maximum count",
        "error"
      );
    }
  }

  const unCheckRedeem=(sessionItem: any)=>{
    let amount = packageCostAfterDiscount;
    let payLaterAmt = payLaterAmount;
    let complimentaryAmt=useComplimentaryAmount
    let packageRedeemAmt=useCreditAmount
    const mainSession=[...session]
    const updatedPkListToUse:any = packageListToUse?.map((i: any) => {
      if (sessionItem?.SelectedPackage?.PackageInstanceId === i.PackageInstanceId) {
        let sessions = i.ClassSessions;
        let sessionCount = i.SchedulesToUse;
          sessions = sessions.filter((j: any) => {
            if (j.ClassSessionId === sessionItem.ClassSessionId) {
              if (i?.SessionType === 1) {
                sessionCount = sessionCount + 1;
              }
              if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                amount = amount + sessionItem?.Cost;
              } else {
                payLaterAmt = payLaterAmt + sessionItem?.Cost;
              }
              packageRedeemAmt=packageRedeemAmt-sessionItem?.Cost
              return false;
            }
            return true;
          });
        return { ...i, ClassSessions: sessions, SchedulesToUse: sessionCount };
      } else {
        return { ...i };
      }
    });
   const sessionData = mainSession.map((j: any) => {
      if (j.ClassId === sessionItem?.ClassId) {
        const modifiedValues=j.values?.map((sch:any)=>{
          if (sch.ClassSessionId === sessionItem?.ClassSessionId && sessionItem?.Complimentary) {
              if (sessionItem?.PaymentOption <= 1 && !sessionItem?.CanBookAsWaitlist) {
                amount = amount + sessionItem?.Cost;
              } else {
                payLaterAmt = payLaterAmt + sessionItem?.Cost;
              }
              complimentaryAmt=complimentaryAmt-sessionItem?.Cost
            return { ...sch,Complimentary:false };
          }
          return { ...sch };
        })
        return { ...j, values: modifiedValues };
      } else {
        return { ...j };
      }
    });
    setPackageCostAfterDiscount(amount);
    setPayLaterAmount(payLaterAmt);
    setDownpaymentForm({ ...downpaymentForm, paidToday: amount });
    setUseCreditAmount(packageRedeemAmt)
    setUseComplimentaryAmount(complimentaryAmt)
    setPackageListToUse(updatedPkListToUse);

  }
  const BackButtonComponent = (props: any) => {
    return (
      <button
        type="button"
        className="btn btn-primary buz-back-btn btn-sm mb-2"
        onClick={() => props?.onClick()}
      >
        <i className="fas fa-arrow-left"></i>
      </button>
    );
  };

  const handlePayNow = async (dataItem: any) => {
    setBtnLoading(true);
    setDisable(true);
    let result = null;
    if (checkoutType === "service") {
      const checkoutservice = new TenantService();
      result = await checkoutservice.purchaseService(dataItem);
    } else {
      const checkoutPurchase = new PackageService();
      result = await checkoutPurchase.purchasePackage(dataItem);
    }
    setBtnLoading(false);
    setDisable(false);
    if (result?.ResponseCode === 100) {
      let successMsg = "";
      if (checkoutType === "service") {
        successMsg = `One-on-One Purchased Successfully`;
      } else {
        successMsg = `Packages Purchased Successfully`;
      }
      props?.handleNotificationMessage(successMsg, "success");
      setIsFormChanged(false);
      if (checkoutType === "service") {
        const serviceItem = {
          ...dataItem.Service,
          PrivateServiceInstanceId: result?.Item?.PrivateServiceInstanceId,
          UserMemberId: clientSubmitDetails?.UserMemberId
        };
        setCheckoutService({
          ...checkoutService,
          showDialog: true,
          service: serviceItem,
        });
      } else {
        setCheckoutPackage({
          ...checkoutPackage,
          showDialog: true,
          package: {...packag,PackageInstanceId:parseInt(result?.Items[0]),UserMemberId: clientSubmitDetails?.UserMemberId},
        });
      }
    } else {
      const errorMsg = result?.ErrorMessages?.[0];
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const widthOfDevice = window.innerWidth;
  if (loading) {
    return (
      <>
        <BuzopsLoader type="list" />
      </>
    );
  }
  console.log(session,"Session")
  const inHouseGroup = checkoutType === "inhouse-class" || checkoutType === "inhouse-package" ? "bz-inhouse-order-summary" : "bz-online-order-summary"
  return (
    <div className={`col-12 bz-checkout-page bz-checkout-${checkoutType}`}>
      <div className="checkout-page row d-flex justify-content-between custom-grid-box">
        <div className="payment-panel-form col-xs-12 col-sm-12 col-md-12 col-lg-8 px-0 mb-3 mb-lg-0">
          {!staffPage && (
            <Prompt
              when={isFormChanged}
              message={
                "You have unsaved changes, Are you sure you want to leave?"
              }
            />
          )}
          {checkoutType !== "inhouse-package" &&
            checkoutType !== "inhouse-class" && (
              <BackButtonComponent onClick={() => handleNotNowRedirection()} />
            )}
          <PaymentPanelForm
            serviceData={serviceData}
            IsFromCalendar={props?.location?.state?.from ==="calender" ? true : false}
            packageTableData={packageTableData}
            staffPage={staffPage}
            origserviceData={origserviceData}
            PackagesData={PackagesData}
            UserMemberId={userMemberId}
            handleAddPaymentMode={handleAddPaymentMode}
            handleSubmit={handleSubmit}
            // handlePackageSelection={handlePackageSelection}
            handleForm={(val: string) => handleForm(val)}
            handleRemoveSession={(item: any) => handleRemoveSession(item)}
            paymentMethod={paymentMethod}
            selected={selected}
            totalClassSessions={totalClassSessions}
            session={session}
            page={checkoutType}
            selectedClassesType={props?.type}
            paymentGatewayType={paymentGatewayType}
            bankFormValues={bankFormValues}
            cardImageIcon={cardImageIcon}
            clientFormValues={clientFormValues}
            packageCostAfterDiscount={packageCostAfterDiscount}
            packageCost={packageCost}
            clientSubmitDetails={clientSubmitDetails}
            selectedClient={selectedClient}
            selectedPaymentProfile={selectedPaymentProfile}
            handleBankSubmit={handleBankSubmit}
            handleClientSubmit={handleClientSubmit}
            handleAddClient={handleAddClient}
            handleClientSelectionSubmit={handleClientSelectionSubmit}
            handleUseCreditSubmit={handleUseCreditSubmit}
            cardFormValues={cardFormValues}
            paymentProfile={paymentProfile}
            clientsList={clientsList}
            checkPaymentProfileNotRequired={checkPaymentProfileNotRequired}
            handleCardSubmit={handleCardSubmit}
            cardSubmitBtnLoading={cardSubmitBtnLoading}
            bankSubmitBtnLoading={bankSubmitBtnLoading}
            handleChangeClient={handleChangeClient}
            tableData={tableData}
            package={packag}
            IsTaxSkipped={IsTaxSkipped}
            checkOfflineReferenceRequired={checkOfflineReferenceRequired}
            originalPackage={packageItem}
            // packageToUse={packageToUse}
            packageListToUse={packageListToUse}
            paymentSubmitProfile={paymentSubmitProfile}
            // selectedPackageToUse={selectedPackageToUse}
            // selectedValue={selectedValue}
            // handlePickPackageOrCard={(val: string) => handlePickPackageOrCard(val)}
            showinstallmentForm={showinstallmentForm}
            downpaymentForm={downpaymentForm}
            clientSubmitBtnLoading={clientSubmitBtnLoading}
            clientSearchLoading={clientSearchLoading}
            handleAddMoreClasses={handleAddMoreClasses}
            handleDownPayment={(val: any, name: string) =>
              handleDownPayment(val, name)
            }
            handlePackageSelectionForClass={(val: any, item: any) =>
              handlePackageSelectionForClass(val, item)
            }
            handleAutoselectPackage={() =>
              handleAutoselectPackage()
            }
            handleUseCredit={(val: any, sessionItem: any) =>
              handleUseCredit(val, sessionItem)
            }
            handleComplimentary={(val: any, sessionItem: any) =>
              handleComplimentary(val, sessionItem)
            }
            handlePackageReccurence={(name: string, value: any) => handlePackageReccurence(name, value)}
            handleClientSelecton={(val: any) => handleClientSelecton(val)}
            handleChangeSavedCard={(val: any,dataItem=null) => handleChangeSavedCard(val,dataItem)}
            handleCardImageIcon={(url: any) => handleCardImageIcon(url)}
            onSelect={(e: any) => onSelect(e)}
            checkUseCredit={(e: any) => checkUseCredit(e)}
            ComplimentaryPermission={ComplimentaryPermission}
            updateSelectedClientData={updateSelectedClientData}
            fetchClientData={fetchClientData}
          />
        </div>
        <div className={`order-summary-form groupordersummary col-xs-12 col-sm-12 col-md-12 col-lg-4 ${inHouseGroup}`}>
          <OrderSummary
            serviceData={serviceData}
            IsFromCalendar={props?.location?.state?.from ==="calender" ? true : false}
            session={session}
            page={checkoutType}
            disable={disable}
            Taxes={Taxes}
            TotalTaxAmount={TotalTaxAmount}
            PredictedTaxes={PredictedTaxes}
            PredictedTotalTaxAmount={PredictedTotalTaxAmount}
            filterCoupondata={filterCoupondata}
            filterCouponChange={(e:any) => filterCouponChange(e)}
            handlePackageCoupon={(val:any) => handlePackageCoupon(val)}
            handleOneonOneCoupon={(val:any) => handleOneonOneCoupon(val)}
            packageCost={packageCost}
            packageCostAfterDiscount={packageCostAfterDiscount}
            couponAmount={couponAmount}
            useCreditAmount={useCreditAmount}
            useComplimentaryAmount={useComplimentaryAmount}
            payLaterAmount={payLaterAmount}
            package={packag}
            termsandConditions={termsandConditions}
            handleIsTaxSkipped={handleIsTaxSkipped}
            packageReccurence={packageReccurence}
            handleIsSessionTaxSkipped={handleIsSessionTaxSkipped}
            appliedCoupons={appliedCoupons}
            btnLoading={btnLoading}
            salesPersons={salesPersons}
            handleShowCustomRateDialog={handleShowCustomRateDialog}
            customRate={customRate}
            IsCustomService={props?.location?.state?.customService ? true : false}
            selectedSalesPerson={selectedSalesPerson}
            selectedPaymentProfile={selectedPaymentProfile}
            downpaymentForm={downpaymentForm}
            clientFormValues={clientFormValues}
            IsTaxSkipped={IsTaxSkipped}
            visibleCouponField={visibleCouponField}
            handleCoupon={() => handleCoupon()}
            handleSalesPersonSelection={(data: any) =>
              handleSalesPersonSelection(data)
            }
            handleRenewal={(val: boolean) => handleRenewal(val)}
            handlePackageReccurence={(name: string, value: any) => handlePackageReccurence(name, value)}
            handleTermsConditionDialog={(value:any) => handleTermsConditionDialog(value)}
            handleVisibleCouponField={() => handleVisibleCouponField()}
            handleRemoveCoupon={(index: number) => handleRemoveCoupon(index)}
            handleAgreement={(params: Object) => handleCheckoutPage("agreement", params)}
            handleSignaturePad={() => handleSignaturePad()}
            visibleSignPad={visibleSignPad}
            signature={signature}
            signatureText={signatureText}
            showinstallmentForm={showinstallmentForm}
            signatureFont={signatureFont}
            signatureType={signatureType}
            coupon={coupon}
            staffPage={staffPage}
            couponBtnLoading={couponBtnLoading}
            handleChangeCoupon={(val: string) => handleChangeCoupon(val)}
            handlePayNow={(params: Object) => handleClientConfirmation('paynow', params)}
            allClientsList={AllClientsList}
            calculateTotalSessionTax={calculateTotalSessionTax}
            updateSelectedClientData={(params: Object) => updateSelectedClientData(params)}
            selectedClassesType={props?.type}
          />
        </div>
        {visibleAgreement && <div className="k-overlay"></div>}
        <BuzopsWindow
          key={visibleAgreement.toString()}
          reduceWidth={widthOfDevice < 800 ? 10 : 430}
          onWindowClose={() => setVisibleAgreement(false)}
          title={"Agreement"}
          className={"bzo-window bzo-agreement-window"}
          visibility={visibleAgreement}
        >
          <div
            className="bzo-agreement"
            dangerouslySetInnerHTML={{ __html: agreementTemplate }}
          />
        </BuzopsWindow>
        {visibleSignPad && (
          <SignaturePad
            staffPage={staffPage}
            generatedImage={(imageString, text, font,typeVal) =>
              generatedImage(imageString, text, font,typeVal)
            }
            key={visibleSignPad.toString()}
            onClose={() => setVisibleSignPad(false)}
          />
        )}
        {checkoutService.showDialog && rendercheckoutDialog()}
        {confirmClientDialog && renderClientConfirmationDialog()}
        {checkoutPackage.showDialog && renderPackageCheckoutDialog()}
        {CustomRateDialog?.showDialog && renderCustomRateChange()}
        {showAdditionalPayment?.dialog && 
        <AdditionalPayment 
            fetchPaymentProfiles={fetchPaymentProfiles}
            userMemberId={userMemberId}
            setShowAdditionalPayment={setShowAdditionalPayment}
            paymentProfile={paymentProfile}
            PurchaseAmount={!IsTaxSkipped ? downpaymentForm?.paidToday : downpaymentForm?.paidToday + calculateTotalSessionTax()}
            CreditAmount={paymentSubmitProfile?.Credit}
            showAdditionalPayment={showAdditionalPayment}
            handleChangeAdditionalPaymentProfile={handleChangeAdditionalPaymentProfile}
            handleAddAdditionalPayment={handleAddAdditionalPayment}
        />}
        {termsandConditionsDialog && <TermsAndConditions handleTermsConditionDialog={handleTermsConditionDialog} handleTermsConditions={handleTermsConditions} />}
        {submit && renderClassCheckoutDialog()}
      </div>
    </div>
  );
};

export default withNotification(Checkout);
