import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import {
  CurrencyFormat,
  CustomToFixed,
  checkIfUserAddPaymentGateway,
  checkIfUserCanAddBankAccount,
  getFullImageUrl,
  getTimeZoneById,
  getUserTimeZoneLong,
  paymentDropdownItems,
} from "utils";
import { PaymentMethodItems } from "utils/form-utils";
import {  Hint } from "@progress/kendo-react-labels";
import { useApiClient } from "services/axios-service-utils";
import withNotification from "components/Hoc/withNotification";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { TenantService } from "services/tenant/index.service";
import { Input, Switch } from "@progress/kendo-react-inputs";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import Location from "components/Location/Location";
import moment from "moment";
import { GetLocalStore } from "utils/storage";
import { Upload } from "@progress/kendo-react-upload";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

const CancelAppointment = (props: any) => {
  console.log(props?.appointment,'props?.appointmentprops?.appointmentprops?.appointment')
  const ChargeTypeItems = [
    {
      text: "Charge",
      id: 1,
    },
    {
      text: "Refund",
      id: 2,
    },
    {
      text: "No Charge & No Refund",
      id: 3,
    },
  ];

  const { axiosRequest } = useApiClient();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [CancelDetail, setCancelDetail] = useState<any>(null);
  const [OriginalCancelDetail, setOriginalCancelDetail] = useState<any>(null);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [showRecurrence, setShowRecurrence] = useState(false);
  const [paymentProfile, setPaymentProfile] = useState([]);
  const [selectedPaymentProfile, setSelectedPaymentProfile] = useState<any>({
    selectedPayment: undefined,
  });
  const [submit, setSubmit] = useState(false);

  const [addNewItem, setAddNewItem] = useState<any>(null);

  const [taxes, setTaxes] = useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<any>(0);
  const tenantDataValues = GetLocalStore("tenantUserSession");
  const defaultTimezone = getTimeZoneById(tenantDataValues?.TimeZone?.Id);
  const [tz, setTz] = useState<any>({
    timezoneValue: defaultTimezone,
    initial: true,
  });

  
  
  //upload
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  //


  useEffect(() => {
    fetchPaymentProfiles();
    fetchApptCancellationDetails();
  }, []);

  useEffect(() => {
    if (submit) {
      let timer = 2000;
      let timer1 = setTimeout(() => {
        props?.handleWindow();
      }, timer);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [submit]);

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(false);
    fetchPaymentProfiles(modeVal);
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            addNewItem !== "addOther"
              ? checkIfUserCanAddBankAccount()
                ? "Add Bank or Card"
                : "Add Card"
              : "Add Payment Mode"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={handleSuccessClosePaymethodDialog}
            UserMemberId={props?.userDetails?.PrimaryMemberId}
          />
        </Dialog>
      </>
    );
  };
  async function fetchPaymentProfiles(modeVal=null) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(
      props?.userDetails?.PrimaryMemberId
    );
    if (result.length > 0) {
      if(modeVal){
        const record=result?.find((i:any)=> i?.CardTypeId ===-1)
        if(record){
          const data={
            ...record,
            CardTypeId: -2,
            MaskedCCNumber: modeVal,
            CardDescription: modeVal,
            CardDescriptionWithoutExpiryDate: modeVal,
          };
          const res=[...result]
          res.push(data)
          setPaymentProfile(JSON.parse(JSON.stringify(res)))
          handleChangeSavedCard(data)
        }
      }else {
        setPaymentProfile(result);
      }
    }
  }

  const handleChangeSavedCard = (val: any) => {
    const paymentDetails = {
      CustomerProfileID: val?.CustomerProfileID,
      PaymentProfileID: val?.PaymentProfileID,
      PaymentGatewayPaymentProfileID: val?.PaymentGatewayPaymentProfileID,
      MaskedCCNumber: val?.MaskedCCNumber,
      CardTypeId: val?.CardTypeId,
      CardDescription: val?.CardDescription,
      BankAccountType: val?.BankAccountType,
      CardDescriptionWithoutExpiryDate: val?.CardDescriptionWithoutExpiryDate,
      Credit: val?.Credit,
    };
    setSelectedPaymentProfile({
      ...selectedPaymentProfile,
      selectedPayment: paymentDetails,
    });
  };

  const handleSubmit = async () => {
    if(!CancelDetail?.chargeType?.id){
      const errorMsg = "Please Select Cancellation Type";
      props?.handleNotificationMessage(errorMsg, "error");
      return 
    }
    if(CancelDetail?.chargeType?.id === 1 && !selectedPaymentProfile?.selectedPayment){
      const errorMsg = "Please Select Payment Profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return 
    }
    setBtnLoading(true);
    const tenantService = new TenantService();
    let schedules=""
    if(!showRecurrence){
      schedules= `${moment(props?.appointment?.FromTime).format("MM/DD/YYYY")} ${moment(
        props?.appointment?.FromTime
      ).format("hh:mm A")}-${moment(props?.appointment?.ToTime).format(
        "hh:mm A"
      )}`
    }else{
      const records=CancelDetail?.RecurrenceSchedules.map((i:any)=> {
          return `${moment(i?.FromTime).format("MM/DD/YYYY")} ${moment(
            i?.FromTime
          ).format("hh:mm A")}-${moment(i?.ToTime).format(
            "hh:mm A"
          )}`
      })
      schedules=records.join(',')
    }
    let paymentAttributes = null;
    if (selectedPaymentProfile?.selectedPayment?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: CancelDetail.refNumber,
        OfflinePaymentDescription: selectedPaymentProfile?.selectedPayment?.MaskedCCNumber,
        ReferrenceDocumentName: files[0]?.FileName || "",
        ReferrenceDocumentContent: files[0]?.ImageAsBase64 || "",
      };
    }else if(selectedPaymentProfile?.selectedPayment?.CardTypeId === -1){
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
    }

    const req = {
      
      ScheduleId: props?.appointment.ScheduleId,
      ClientEmail: props?.appointment.ClientEmail,
      IsDeleteRecurrenceSeries: showRecurrence ? true : false,
      CancelationRequest: {
        Charge:
          CancelDetail?.chargeType?.id === 1 ? CancelDetail?.charge : null,
        MemberNumber: props?.userDetails?.MemberNumber,
        PaymentProfile:
          CancelDetail?.chargeType?.id === 1
            ? {
                CustomerProfileId:
                  selectedPaymentProfile?.selectedPayment?.CustomerProfileID,
                PaymentGatewayPaymentProfileId:
                  selectedPaymentProfile?.selectedPayment
                    ?.PaymentGatewayPaymentProfileID,
                PaymentProfileId:
                  selectedPaymentProfile?.selectedPayment?.PaymentProfileID,
              }
            : null,
        TransactionId: CancelDetail?.TransactionID,
        PrivateServiceInstanceId: props?.appointment?.EntityId,
        UserMemberId: props?.appointment?.UserMemberId,
        Refund: CancelDetail?.chargeType?.id === 2 ? CancelDetail?.refund : 0,
        Schedules: schedules,
        PrivateServiceType:props?.appointment?.PrivateServiceType,
        PaymentAttributes:paymentAttributes
      },
    };
    const cancelReq = await tenantService.cancelUpcomingAppointments(req);
    setBtnLoading(false);
    if (cancelReq.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Appointment cancelled Successfully`,
        "success"
      );
      setSubmit(true)
    } else {
      if (cancelReq?.ErrorMessages) {
        const errorMsg = cancelReq?.ErrorMessages[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };
  const handleChange = (val: any, name: string) => {
    setCancelDetail({
      ...CancelDetail,
      [name]: val,
    });
  };

  const handleCalculateSurcharges = (refundAmt: any, TaxItems: any) => {
    const TaxesArray = TaxItems?.map((i: any) => {
      const percentage = parseFloat(i?.split(":")[1]);
      const amount = (refundAmt * percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        Description: i?.split(":")[0],
        Percentage: percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setTaxes(TaxesArray);
    setTotalTaxAmount(taxAmt);
  };

  const fetchApptCancellationDetails = async () => {
    setLoading(true);
    let tzvalueName;
    if(props?.calendarpage){
      tzvalueName = tz.timezoneValue?.value ?? tz.timezoneValue;
    }else{
      tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    
    let tzlabelVal;
    tzlabelVal = getUserTimeZoneLong(tzvalueName);

    await axiosRequest.get(
      `Appointments/CancelDetail?scheduleId=${props?.appointment?.ScheduleId}&privateServiceInstanceId=${props?.appointment?.EntityId}&UserTimeZoneId=${tzlabelVal}`,
      {
        successCallBack: (response: any) => {
          console.log(response?.data);
          let data = { ...response.data.Item };
          let recurrenceItems=data?.RecurrenceSchedules
          if(recurrenceItems?.length > 0){
            recurrenceItems.push({
              FromTime:props?.appointment?.FromTime,
              ToTime:props?.appointment?.ToTime,
            })
          }
          let chargeTypeValue = {};
          let charge = 0;
          let Rate=data?.Rate
          let refund:any = 0;
          if (data?.CancellationFee === data?.Rate) {
            chargeTypeValue = ChargeTypeItems[2];
          } else if (data?.CancellationFee > data?.Rate) {
            chargeTypeValue = ChargeTypeItems[0];
            charge = data?.CancellationFee - data?.Rate;
          } else if (data?.CancellationFee < data?.Rate) {
            let AmountAvailableToRefund=data?.AmountAvailableToRefund-data?.TaxAvailableToRefund
            chargeTypeValue = ChargeTypeItems[1];
            if(Rate > AmountAvailableToRefund){
              Rate = AmountAvailableToRefund
            }
            refund = data ? CustomToFixed(Rate - data.CancellationFee, 2) : 0;
            handleCalculateSurcharges(refund, data?.Taxes);
          }
          data = {
            ...data,
            Rate:Rate,
            chargeType: chargeTypeValue,
            refund,
            charge,
            RecurrenceSchedules:recurrenceItems
          };
          setOriginalCancelDetail({...response.data.Item,RecurrenceSchedules:recurrenceItems});
          setCancelDetail(data);
       
        },
        errorCallBack: (response: any) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error",
            "error"
          );
        },
      }
    );
    setLoading(false);
  };

  const handleChangeRecurrence = () => {
    setShowRecurrence(!showRecurrence);
    const data = { ...OriginalCancelDetail };

    const sortedRecurrenceSchedules = OriginalCancelDetail?.RecurrenceSchedules?.slice().sort((a:any, b:any) => new Date(a.FromTime) - new Date(b.FromTime));
    let Rate = OriginalCancelDetail?.Rate;
    let CancellationFee = OriginalCancelDetail?.CancellationFee;
    if (!showRecurrence) {
      const count=OriginalCancelDetail?.RecurrenceSchedules?.length
      Rate = OriginalCancelDetail?.Rate * count;
      CancellationFee = OriginalCancelDetail?.CancellationFee * count;
    }
    let chargeTypeValue = {};
    let charge = 0;
    let refund = 0;
    if (CancellationFee === Rate) {
      chargeTypeValue = ChargeTypeItems[2];
    } else if (CancellationFee > Rate) {
      chargeTypeValue = ChargeTypeItems[0];
      charge = CancellationFee - Rate;
    } else if (CancellationFee < Rate) {
      chargeTypeValue = ChargeTypeItems[1];
      let AmountAvailableToRefund=OriginalCancelDetail?.AmountAvailableToRefund-OriginalCancelDetail?.TaxAvailableToRefund
      if(!showRecurrence && Rate > AmountAvailableToRefund){
        Rate=AmountAvailableToRefund
      }else if(showRecurrence && Rate > AmountAvailableToRefund){
        Rate=AmountAvailableToRefund
      }
      refund =CustomToFixed(Rate - CancellationFee,2);
      handleCalculateSurcharges(refund, OriginalCancelDetail?.Taxes);
    }



    setCancelDetail({
      ...data,
      Rate: Rate,
      CancellationFee: CancellationFee,
      chargeType: chargeTypeValue,
      refund,
      charge,
      RecurrenceSchedules:sortedRecurrenceSchedules,
    });
  };

  const item = props?.appointment;
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  const refundAmountSelected =
    CancelDetail?.chargeType?.id === 2 &&
    CancelDetail?.refund > 0 &&
    props?.appointment?.CancollectTaxes &&
    TotalTaxAmount > 0
      ? "refund-amount"
      : "";
  return (
    <div>
      <Card
        className={`m-0 bz-attendeesList-container bz-cancellation-page bz-cancellation-page-new `}
      >
        {!props?.calendarpage &&(
        <CardHeader className="d-flex justify-content-between px-0 pt-1 pb-0">
          <h5 className="mb-1 bz_fs_1_5">
            <button
              className="btn btn-primary bz-back-btn btn-sm"
              onClick={() => props.handleWindow()}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
            &nbsp;Cancel Appointment
          </h5>
        </CardHeader>
        )}
        <CardBody className="px-0">
          <div className="card bzc-sessions-list-card bz-sessionRoaster-card bz-default-cursor mb-3 p-2">
            {OriginalCancelDetail?.RecurrenceSchedules?.length > 0 ? (
              <div className="d-flex cancelrecurrenceappts">
                <span style={{ marginRight: "10px" }}>
                  {" "}
                  {"Cancel this and recurrence appointments"}
                </span>
                <Switch
                  id="recurrence"
                  checked={showRecurrence}
                  onChange={() => handleChangeRecurrence()}
                />
              </div>
            ) : null}
            <div className="bzc-sessions-list-row">
              <div className="bzc-date-sec d-flex">
                <div className="media">
                  <img
                    alt=""
                    className="bz-user-img align-self-center mr-2"
                    style={{ width: "45px" }}
                    src={
                      item.TrainerPhoto.trim()
                        ? getFullImageUrl(item.TrainerPhoto)
                        : "/images/user.png"
                    }
                  />
                </div>
                <div className="bzc-date-sec">
                  <h6 className="mt-0 mb-0">{`${item.TrainerName}`}</h6>
                  <div className="d-flex">
                    {`${item.ServiceName}`}
                    {item?.Attributes &&
                      item?.Attributes !== "" &&
                      item?.Attributes !== "null" && (
                        <Location
                          Attributes={item?.Attributes}
                          showDetails={true}
                          VirtualMeetingLink={item?.VirtualMeetingLink}
                          clientPhnNum={item?.Phone}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="bzc-date-sec">
                {showRecurrence ? (
                  <div className="recurrencceappt">
                    {CancelDetail?.RecurrenceSchedules?.map((k: any) => {
                      return (
                        
                          <div className="cancelappttimedate">
                        <span className={""}>
                          {moment(k.FromTime).format("MM/DD/YYYY")}{" "}<br/>
                          <span className="time"> 
                          <i className="far fa-clock mr-1"></i>
                          {`${moment(k.FromTime).format("hh:mm A")}`} -{" "}
                          {`${moment(k.ToTime).format("hh:mm A")}`}
                          </span>
                        </span>
                        </div>
                        
                      );
                    })}
                  </div>
                ) : (
                  <div className="cancelappttimedate">
                  <span className={""} >
                    {moment(item.FromTime).format("MM/DD/YYYY")}{" "}<br/>
                 <span className="time">
                 <i className="far fa-clock mr-1"></i>
                    {`${moment(item.FromTime).format("hh:mm A")}`} -{" "}
                    {`${moment(item.ToTime).format("hh:mm A")}`}
                 </span>
                  </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={
              "card bzc-attndees-card bz-shadow-class bz-default-cursor"
            }
          >
            <div className="card-body">
              <div>
                <div
                  className={
                    CancelDetail?.chargeType?.id === 1 ||
                    CancelDetail?.chargeType?.id === 2
                      ? CancelDetail?.chargeType?.id === 1 &&
                        CancelDetail?.charge > 0
                        ? `bz-cancellation-card-row bz-charge-amount`
                        : `bz-cancellation-card-row clientdetials charge-dropdown ${refundAmountSelected}`
                      : `bz-cancellation-card-row`
                  }
                >
                  <div className="column-1">
                  <div className="bzc-date-sec">
                    {" "}
                    <small>Client Name</small>
                    <p> <ClientNameWithMemberType
                      memberType={props?.userDetails?.MemberType}
                      clientName={props?.userDetails?.FullName}
                    /></p>
                    </div>
                  </div>

                  <div className="column-2">
                    <div className="bzc-date-sec">
                      <small>Price</small>
                      <h5 className="text-capitalize">
                        {CurrencyFormat(CancelDetail?.Rate)}
                      </h5>
                    </div>
                  </div>
                  <div className="column-3">
                    <div className="bzc-time-sec">
                      <small>Cancellation Fee</small>
                      <h5 className="text-capitalize">
                        {CurrencyFormat(CancelDetail?.CancellationFee)}
                      </h5>
                    </div>
                  </div>

                  <div className="column-4">
                    <div className="bzc-provider-name">
                      <small>Cancellation Type</small>
                      <div className="bzc-time-sec">
                        <DropDownList
                          id={`chargeType`}
                          name={`chargeType`}
                          data={ChargeTypeItems}
                          textField="text"
                          dataItemKey="id"
                          value={CancelDetail?.chargeType}
                          onChange={(e) => handleChange(e.value, "chargeType")}
                          disabled={props?.isClient}
                        />
                      </div>
                    </div>
                  </div>

                  {CancelDetail?.chargeType?.id === 1 && (
                    <div className="column-5">
                      <div className="bzc-provider-name">
                        <small>Charge Amount ($)</small>
                        <div className="bzc-time-sec">
                          <Input
                            type="number"
                            id={`charge`}
                            name={`charge`}
                            min={0}
                            // format={"c2"}
                            step={"any"}
                            disabled={props?.isClient}
                            value={CancelDetail?.charge}
                            onChange={(e) =>{
                              let val:any=e.target.value
                              if(val <=0){
                                val=0
                              }
                              handleChange(val, "charge")
                            }
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {CancelDetail?.chargeType?.id === 2 && (
                    <div className="column-6">
                      <div className="bzc-provider-name">
                        <small>Refund Amount($)</small>
                        <div className="bzc-time-sec">
                          <Input
                            type="number"
                            id={`refund`}
                            name={`refund`}
                            min={0}
                            step={"any"}
                            disabled={props?.isClient}
                            value={CancelDetail?.refund}
                            onChange={(e:any) => {
                              let val:any = parseFloat(e.target.value).toFixed(2);
                              val = Math.max(0, val);
                              if(val <= 0){
                                val=0
                              }else if(val > CancelDetail?.Rate - CancelDetail?.CancellationFee){
                                val=CancelDetail?.Rate - CancelDetail?.CancellationFee
                              }
                              handleChange(val, "refund");
                              handleCalculateSurcharges(
                                val,
                                CancelDetail?.Taxes
                              );
                            }}
                          />
                        </div>
                        <Hint style={{fontSize:'12px'}}>
                          {`Available Amount to Refund :${CurrencyFormat(
                            CancelDetail?.Rate - CancelDetail?.CancellationFee
                          )}`}
                        </Hint>
                      </div>
                    </div>
                  )}
                  {CancelDetail?.chargeType?.id === 2 &&
                    CancelDetail?.refund > 0 &&
                    props?.appointment?.CancollectTaxes &&
                    TotalTaxAmount > 0 && (
                      <>
                        <div className="column-7">
                          <div className="bzc-time-sec">
                            <small>
                              <Tooltip
                                content={(tooltipProps: any) => {
                                  return (
                                    <TooltipContentRefundTax
                                      title={tooltipProps.title}
                                      data={taxes}
                                      TotalTaxAmount={TotalTaxAmount}
                                    />
                                  );
                                }}
                                tooltipClassName="bz-tooltip-table"
                              >
                                <a
                                  title="Surcharges"
                                  style={{
                                    borderBottom: "1px dotted #0d6efd",
                                    cursor: "pointer",
                                  }}
                                >
                                  {"Surcharges to be refunded"}
                                </a>
                              </Tooltip>
                            </small>
                            <h5 className="text-capitalize">
                              {CurrencyFormat(TotalTaxAmount)}
                            </h5>
                          </div>
                        </div>
                        <div className="column-8">
                          <div className="bzc-time-sec">
                            <small>{"Total Refund Amount"}</small>
                            <h5 className="text-capitalize">
                              {CurrencyFormat(
                                Number(CancelDetail?.refund) +
                                  Number(TotalTaxAmount)
                              )}
                            </h5>
                          </div>
                        </div>
                      </>
                    )}
                  {CancelDetail?.chargeType?.id === 1 &&
                    CancelDetail?.charge > 0 && (<>
                      <div className="column-7 add-payment-profile">
                        <div className="bzc-provider-name">
                          <small>Payment Profile</small>
                          <div className="bzc-time-sec">
                            <DropDownList
                              id={`paymentProfile`}
                              name={`paymentProfile`}
                              data={paymentProfile}
                              textField="MaskedCCNumber"
                              // dataItemKey="PaymentProfileID"
                              valueRender={(
                                el: React.ReactElement<HTMLSpanElement>,
                                value: any
                              ) =>
                                PaymentValueRender(
                                  el,
                                  value,
                                  selectedPaymentProfile?.selectedPayment
                                )
                              }
                              itemRender={PaymentItemRender}
                              onChange={(e) => {
                                handleChangeSavedCard(e.value);
                              }}
                            />
                          </div>
                        </div>
                        {checkIfUserAddPaymentGateway() && <div className="addNewBtn">
                          <DropDownButton
                            text="Add New"
                            onItemClick={(e: any) => onCardOrBankAdd(e)}
                            items={paymentDropdownItems()?.filter(
                              (i: any) => i?.visible === true
                            )}
                            look="flat"
                            className="btn btn-link add-new-btn"
                            popupSettings={{
                              popupClass: "payNow-addCard-popup",
                              anchorAlign: {
                                horizontal: "right",
                                vertical: "bottom",
                              },
                              popupAlign: {
                                horizontal: "right",
                                vertical: "top",
                              },
                            }}
                          />
                        </div>}

                        
                      </div>
                        {selectedPaymentProfile?.selectedPayment?.CardTypeId === -2 && (
                        <>
                              <div className="column-6">
                      <div className="bzc-provider-name">
                        <small> Transaction Reference Number</small>
                        <div className="bzc-time-sec">
                                <Input
                                  onChange={(event) =>
                                    handleChange(
                                      event?.target?.value,
                                      "refNumber"
                                    )
                                  }
                                  value={CancelDetail.refNumber}
                                  type={"text"}
                                  style={{ width: "280px" }}
                                  id={"refNumber"}
                                />
                              </div>
                            </div>
                          </div>
                              <div className="column-6">
                      <div className="bzc-provider-name">
                        <small> Reference Document</small>
                        <div className="bzc-time-sec">
                                {/* <input type="file" /> */}
                                <Upload
                                  autoUpload={false}
                                  batch={false}
                                  restrictions={{
                                    // allowedExtensions: [".jpg", ".png", ".jpeg"],
                                    maxFileSize: 1000000,
                                  }}
                                  showActionButtons={false}
                                  multiple={false}
                                  onAdd={onAdd}
                                  withCredentials={false}
                                  onRemove={onRemove}
                                  files={originalFiles}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className={"float-right pt-2"}>
            <Button disabled={btnLoading || submit} onClick={() => props.handleWindow()}>
              Cancel
            </Button>
            <BuzopsButton
              loading={btnLoading}
              disabled={btnLoading || submit}
              label={"Apply"}
              onClick={handleSubmit}
            />
          </div>
        </CardBody>
      </Card>
      {showPaymentDialog && PaymethodDialog()}
    </div>
  );
};

export default withNotification(CancelAppointment);
