import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsButton from "generic-components/BuzopsButton";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus, CurrencyFormat, PaymentMadeFor } from "utils";
import PayNow from "components/Transactions/PayNow";
import ViewDetails from "components/Transactions/ViewDetails";
import Refund from "components/Transactions/Refund";
import Void from "components/Transactions/Void";
import withNotification from "components/Hoc/withNotification";
import CashImg from "../../cashIcon.png";
import ClientNameWithMemberType from "./ClientNameWithMemberType";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { ClassService } from "services/class/index.service";
import { Dialog } from "@progress/kendo-react-dialogs";
import { AmountCell, NameCell } from "components/ChargeBack";
import moment from "moment";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { FaExclamationTriangle } from "react-icons/fa";
import {  ChargeBackPermission, showRefund } from "utils/permission-utils";
import { useApiClient } from "services/axios-service-utils";
const DateCell = (props: any) => {
  return (
    <td>
      <div className="d-flex flex-column">
        <div>{moment(props?.dataItem?.TransactionDate).format("L")}</div>
        <div>
          {moment(props?.dataItem?.TransactionDate).format("HH:mm:ss A")}
        </div>
      </div>
    </td>
  );
};
const insertItems = [
  {
    text: "Charge Back (CC)",
    key: "chargeback",
  },
  {
    text: "Return (ACH)",
    key: "return",
  },
];
const Transactions = (props: any) => {
  const service = new ClassService();
  const [tableData, setTableData] = useState<any>([]);
  const [maxPageNum, setMaxPageNum] = useState<any>(0);
  const [transachisid, setTransachisid] = useState<any>("");
  const [viewdata, setViewdata] = useState<any>("");
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setloading] = useState(true);
  const [chargeBackLoading, setChargeBackLoading] = useState({
    insert: false,
    submit: false,
    TransactionId: 0,
  });
  const [viewdetail, setViewDetails] = React.useState({
    viewdetails: false,
    refund: false,
    paynow: false,
    void: false,
  });
  const showMoreRef = useRef<any>();
  const [page, setPage] = React.useState({
    skip: 0,
    take: 10,
  });
  const [transactionItems, setTransactionItems] = useState<any>(null);
  const [insertItem, setInsertItem] = useState<any>(null);
  const handleInsert = async (e: any, dataItem: any) => {
    setInsertItem({ ...dataItem, InsertType: e.item.key });
    let mode;
    if (e.item.key === "chargeback") {
      mode = 10;
    } else if (e.item.key === "return") {
      mode = 7;
    }
    const req = {
      TransactionId: dataItem?.TransactionId,
      PaymentMode: mode,
    };
    setChargeBackLoading({
      ...chargeBackLoading,
      insert: true,
      TransactionId: dataItem?.TransactionId,
    });
    const res = await service.GetByTransactionId(req);
    setChargeBackLoading({
      ...chargeBackLoading,
      insert: false,
      TransactionId: 0,
    });
    if (res?.ResponseCode === 100) {
      if (res?.Item?.Result?.length === 0) {
        props?.handleNotificationMessage("No Transactions Available", "error");
      } else {
        setTransactionItems(res?.Item);
        setShowDialog(!showDialog);
      }
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Transaction Id";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const actionsItems =(propsData: any)=> [
    {
      text: "View Details",
      key: "viewdetails",
      condition: () => true, 
    },
    {
      text: "Insert - Charge Back (CC)",
      key: "chargeback",
      condition: () =>
        propsData?.dataItem?.TransactionId &&
        propsData?.dataItem?.TransactionId !== "0" &&
        propsData?.dataItem?.PaymentTransactionStatus !== 7 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 6 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 12 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 11 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 13 &&
        propsData?.dataItem?.CardType !== -1 &&
        propsData?.dataItem?.CardType !== 8 &&
        ChargeBackPermission(),
    },
    {
      text: "Insert - Return (ACH)",
      key: "return",
      condition: () =>
        propsData?.dataItem?.TransactionId &&
        propsData?.dataItem?.TransactionId !== "0" &&
        propsData?.dataItem?.PaymentTransactionStatus !== 7 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 6 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 12 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 11 &&
        propsData?.dataItem?.PaymentTransactionStatus !== 13 &&
        propsData?.dataItem?.CardType !== -1 &&
        propsData?.dataItem?.CardType !== 8 &&
        ChargeBackPermission(),
    },
    {
      text: "Refund",
      key: "refund",
      condition: () =>
        propsData.dataItem.IsRefundAvailable && props?.staffPage && showRefund(propsData.dataItem),
    },
    {
      text: "Pay Now",
      key: "paynow",
      condition: () => propsData.dataItem.IsPaynowAvailable,
    },
    {
      text: "Share",
      key: "share",
      condition: () =>
        propsData?.dataItem?.TransactionId &&
        //propsData?.dataItem?.TransactionId !== "0" &&
        propsData?.dataItem?.PaymentTransactionStatus !== 7 &&
        // propsData?.dataItem?.PaymentTransactionStatus !== 6 &&
        // propsData?.dataItem?.PaymentTransactionStatus !== 12 &&
        // propsData?.dataItem?.PaymentTransactionStatus !== 11 &&
        // propsData?.dataItem?.PaymentTransactionStatus !== 13 &&
        // propsData?.dataItem?.CardType !== 8 &&
        ChargeBackPermission(),
    },
  ];

  const handleAction = async (e: any, dataItem: any) => {
    if(e.item.key=="viewdetails"){
      handelView(
        "viewdetail",
        dataItem.PaymentGatewayTransactionHistoryId,
        dataItem
      )
    }
    else if(e.item.key=="refund"){
      handelView(
        "refund",
        dataItem.PaymentGatewayTransactionHistoryId,
        dataItem
      )
    }
    else if(e.item.key=="paynow"){
      handelView(
        "paynow",
        dataItem.PaymentGatewayTransactionHistoryId,
        dataItem
      )
    }
    else if(e.item.key=="return"||e.item.key=="chargeback"){
      handleInsert(e, dataItem)
    }
    else if(e.item.key=="paynow"){
      handelView(
        "paynow",
        dataItem.PaymentGatewayTransactionHistoryId,
        dataItem
      )
    }
    else if(e.item.key=="share"){
      handleShareMail(
        dataItem
      )
    }
  }
  
  const { axiosRequest } = useApiClient();
  const [rowLoadingStates, setRowLoadingStates] = useState<{ [key: string]: boolean }>({});

  const handelView = async (key: any, transachis: any, viewdata: any) => {
    setTransachisid(transachis);
    setViewdata(viewdata);
    if (key == "viewdetail") {
      setViewDetails({
        ...viewdetail,
        viewdetails: true,
      });
    } else {
      if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", true, "client");
        return;
      } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
        props?.handleNotificationMessage("", "error", false, "staff");
        return;
      }
      if (key == "refund") {
        setViewDetails({
          ...viewdetail,
          refund: true,
        });
      } else if (key == "paynow") {
        setViewDetails({
          ...viewdetail,
          paynow: true,
        });
      } else if (key == "void") {
        setViewDetails({
          ...viewdetail,
          void: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchPaymentTransactions();
  }, [page.skip]);

  const handlePageChange = (status = "more") => {
    if (status === "less") {
      setPage({
        skip: 0,
        take: 10,
      });
    } else {
      if (maxPageNum > page.skip + 1) {
        setPage({
          ...page,
          skip: page.skip + 1,
        });
      }
    }

    if (showMoreRef.current) {
      showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchPaymentTransactions = async (fromBack = false) => {
    const { take, skip } = page;
    if (skip === 0) {
      setloading(true);
    }
    let condition = {};
    let Filters = [];
    if (props?.allItems) {
      condition = {
        EntityId: 0,
        FilterType: 0,
        UserMemberId: props?.userData?.UserMemberId,
      };
      Filters = [];
    } else {
      condition = {
        EntityId: props?.EntityId,
        FilterType: props?.FilterType,
        UserMemberId: props?.userData?.UserMemberId,
      };
      Filters = props?.filterValues || [];
    }
    const req = {
      FilterFields: [],
      Filters: Filters,
      PageNumber: fromBack ? 0 : skip,
      PageSize: fromBack ? (skip + 1) * take : take,
      SortBy: "PaymentGatewayTransactionHistoryId",
      SortDirection: "desc",
      Where: condition,
    };

    const transactiondata = new TenantService();
    const result = await transactiondata.getTransactions(req);
    if (skip === 0) {
      setloading(false);
    }
    if (result) {
      const maxPageNumber = Math.ceil(result.TotalItems / take);
      if (skip === 0 || fromBack) {
        setTableData(result.Items);
      } else {
        const data = tableData.concat(result.Items);
        setTableData(data);
      }
      setMaxPageNum(maxPageNumber);
    }
  };

  const amountCell = (props: any) => {
    return (
      <td className={"capitalize"}>
        {CurrencyFormat(props.dataItem.AmountPaid.toFixed(2))}
      </td>
    );
  };

  const actionscell = (propsData: any) => {
    const uniqueId = propsData.dataItem.PaymentGatewayTransactionHistoryId;
    const filteredActions = actionsItems(propsData).filter((item) => item.condition());
    return (
      <td

        className="trnasctiongaction"
      >
        <DropDownButton
          text={
            <BuzopsTooltip
              html={
                <div title="Action DropDown">
                  <i
                    className={`${
                      propsData?.dataItem?.TransactionId ===
                        chargeBackLoading?.TransactionId || rowLoadingStates[uniqueId]
                        ? "btnloading fa fa-spinner fa-spin"
                        : "btnNotloading"
                    }`}
                  ></i>
                  {rowLoadingStates[uniqueId]?"Sending...":"Action" }
                </div>
              }
            />
          }
          disabled={rowLoadingStates[uniqueId] || false} 
          onItemClick={(e: any) => handleAction(e, propsData.dataItem)}
          items={filteredActions}
          className="btn btn-link add-new-btn mininster92 my-1 mx-0 font12"
        />
        <div style={{ display: "flex", overflow: "visible", width: "auto" }}>
          <BuzopsButton
            label="view details"
            onClick={() =>
              handelView(
                "viewdetail",
                propsData.dataItem.PaymentGatewayTransactionHistoryId,
                propsData.dataItem
              )
            }
            style={{
              height: "20px",
              margin: "5px",
              fontSize: "12px !important",
              minWidth: "92px",
            }}
          />
          {propsData.dataItem.IsRefundAvailable &&
            props?.staffPage &&
            showRefund(propsData.dataItem) && (
              <>
                <BuzopsButton
                  label="Refund"
                  onClick={() =>
                    handelView(
                      "refund",
                      propsData.dataItem.PaymentGatewayTransactionHistoryId,
                      propsData.dataItem
                    )
                  }
                  style={{
                    height: "20px",
                    margin: "5px",
                    fontSize: "12px",
                    minWidth: "92px",
                  }}
                />
              </>
            )}
          {propsData.dataItem.IsPaynowAvailable && (
            <>
              <BuzopsButton
                label="Pay Now"
                onClick={() =>
                  handelView(
                    "paynow",
                    propsData.dataItem.PaymentGatewayTransactionHistoryId,
                    propsData.dataItem
                  )
                }
                style={{
                  height: "20px",
                  margin: "5px",
                  fontSize: "12px",
                  minWidth: "92px",
                }}
              />
            </>
          )}
          {propsData?.dataItem?.TransactionId &&
            propsData?.dataItem?.TransactionId !== "0" &&
            propsData?.dataItem?.PaymentTransactionStatus !== 7 &&
            propsData?.dataItem?.PaymentTransactionStatus !== 6 &&
            propsData?.dataItem?.PaymentTransactionStatus !== 12 &&
            propsData?.dataItem?.PaymentTransactionStatus !== 11 &&
            propsData?.dataItem?.PaymentTransactionStatus !== 13 &&
            propsData?.dataItem?.CardType !== -1 &&
            propsData?.dataItem?.CardType !== 8 &&
            ChargeBackPermission() && (
              <>
                <div>
                  <DropDownButton
                    text={
                      <BuzopsTooltip
                        html={
                          <div title="Chargeback or return">
                            <i
                              className={`${chargeBackLoading?.insert &&
                                  propsData?.dataItem?.TransactionId ===
                                  chargeBackLoading?.TransactionId
                                  ? "btnloading fa fa-spinner fa-spin"
                                  : "btnNotloading"
                                }`}
                            ></i>
                            Insert
                          </div>
                        }
                      />
                    }
                    onItemClick={(e: any) => handleInsert(e, propsData.dataItem)}
                    items={insertItems}
                    className="btn btn-link add-new-btn mininster92 my-1 mx-0 font12"
                  />
                </div>
              </>
            )}
        </div>
      </td>
    );
  };
  const capitalizeFirstLetter = (str: string) => {
    // converting first letter to uppercase
    const strLowerCase = str.toLowerCase();
    const capitalized =
      strLowerCase.charAt(0).toUpperCase() + strLowerCase.slice(1);

    return capitalized;
  };
  const handleCloseModal = () => {
    setTransactionItems(null);
    setShowDialog(false);
    setInsertItem(null);
  };
  const handleChargeBack = async () => {
    if (transactionItems?.Result?.length === 0) {
      props?.handleNotificationMessage("No Transactions Available", "error");
      return;
    }
    const req = transactionItems;
    setChargeBackLoading({
      ...chargeBackLoading,
      submit: true,
    });
    const res = await service.UpdateTransactionAsChargeback(req);
    setChargeBackLoading({
      ...chargeBackLoading,
      submit: false,
    });
    if (res?.ResponseCode === 100) {
      const successMsg = res?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      setTransactionItems(null);
      setShowDialog(false);
      setInsertItem(null);
      await fetchPaymentTransactions(true);
      props?.onSuccessfullPayment();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleShareMail = async (dataItem: any) => {
    const uniqueId = dataItem.PaymentGatewayTransactionHistoryId; 
    setRowLoadingStates((prevState) => ({
      ...prevState,
      [uniqueId]: true,
    }));
    const req= {
        UserMemberId: dataItem.UserMemberId,
        PaymentGatewayTransactionHistoryId  : dataItem.PaymentGatewayTransactionHistoryId,
        EntityId:dataItem.EntityId ?? 0,
        IsDuplicateCopy:true
      };

    await axiosRequest.post("payment/SendReceipt",req,{
      successCallBack:async(response:any) => {
        props?.handleNotificationMessage(
          `Email sent Successfully`,
          "success"
        );
        setRowLoadingStates((prevState) => ({
          ...prevState,
          [uniqueId]: false,
        }));
      },
      errorCallBack: (error: any) => {
        console.log(error);
        const errorMsg = "Error in sending Email";
        props?.handleNotificationMessage(errorMsg, "error");
        setRowLoadingStates((prevState) => ({
          ...prevState,
          [uniqueId]: false,
        }));
      }
    })

  };
  const TransactionUI = () => {
    return (
      <>
        <Grid
          data={transactionItems?.Result}
          style={{ maxHeight: "300px", width: "auto" }}
          className="insert-charge-back-table"
        >
          <Column
            field="MemberName"
            title="Client Name"
            width={150}
            cell={(props) =>
              NameCell(props?.dataItem?.MemberName, props?.dataItem?.MemberType)
            }
          />
          <Column field="ServiceName" title="Service Name" width={150} />
          <Column field="TransactionId" title="Transaction Id" width={150} />
          <Column
            field="TransactionDate"
            title="Transaction Date"
            cell={DateCell}
            width={150}
          />
          <Column
            field="AmountPaid"
            title="Amount"
            width={"100px"}
            cell={(props: any) => AmountCell(props, "AmountPaid")}
          />
        </Grid>
        <div className="pt-2 float-right">
          <Button
            primary={false}
            onClick={() => handleCloseModal()}
            disabled={chargeBackLoading?.submit}
          >
            Cancel
          </Button>
          <BuzopsButton
            label={"Confirm"}
            disabled={
              transactionItems?.Result?.length === 0 ||
              chargeBackLoading?.submit
            }
            onClick={() => handleChargeBack()}
            loading={chargeBackLoading?.submit}
            className="mr-0"
          />
        </div>
      </>
    );
  };

  const ChargeBackModal = () => {
    return (
      <>
        <Dialog
          className="bz-insertChargeback-dialog bz-transaction-chargeback-dialog"
          title={`Insert ${insertItem?.InsertType === "chargeback"
              ? "Charge Back (CC)"
              : "Return (ACH)"
            }`}
          onClose={() => handleCloseModal()}
          style={{ zIndex: 999 }}
        >
          {TransactionUI()}
        </Dialog>
      </>
    );
  };
  const paymentModeCell = (props: any) => {
    // PaymentTransactionStatus
    return (
      <td
        className={"capitalize"}
        style={{
          color:
            props.dataItem.PaymentTransactionStatus === 7 ||
              props.dataItem.PaymentTransactionStatus === 12 ||
              props.dataItem.PaymentTransactionStatus === 13 ||
              props.dataItem.PaymentTransactionStatus === 5 ||
              props.dataItem.PaymentTransactionStatus === 10
              ? "red"
              : "green",
        }}
      >
        {capitalizeFirstLetter(props.dataItem.PaymentModeDescription)}
      </td>
    );
  };
  const PaymentMethodCell = (props: any) => {
    if (props.dataItem.CardType === -1 && !props.dataItem?.CardDescription) {
      return (
        <td>
          <div className="d-flex align-items-center">
            <img src={CashImg} className={"cash-img-icon"} alt="Logo" />
            {props.dataItem.CardDescriptionForIcon}
          </div>
        </td>
      );
    } else {
      return (
        <td>
          {props?.dataItem?.CardDescription ? (
            <div className="d-flex align-items-center">
              {props?.dataItem.CardDescription}{" "}
              {props?.dataItem?.PaymentProfileStatus === "ACL" ? (
                <BuzopsTooltip
                  html={
                    <span
                      className="pl-1"
                      title="The account has been closed, contact card holder for new card"
                    >
                      <FaExclamationTriangle
                        style={{ color: "red", marginTop: "-5px" }}
                      />
                    </span>
                  }
                />
              ) : null}
            </div>
          ) : props.dataItem?.PaymentMadeFor === PaymentMadeFor["Package"] &&
            props?.dataItem?.AmountPaid === 0 ? (
            "N/A since it is $0 package"
          ) : (
            "N/A"
          )}
        </td>
      );
    }
  };

  const handleCloseViewDetail = async () => {
    setViewDetails({
      viewdetails: false,
      refund: false,
      paynow: false,
      void: false,
    });
    fetchPaymentTransactions(true);
  };

  if (viewdetail.viewdetails) {
    return (
      <ViewDetails
        viewdata={viewdata}
        from={viewdata?.PaymentModeDescription}
        UserMemberId={props?.userData?.UserMemberId}
        handleCloseViewDetail={handleCloseViewDetail}
      />
    );
  }
  if (viewdetail.refund) {
    return (
      <Refund
        transachisid={transachisid}
        staffPage={props?.staffPage}
        handleCloseViewDetail={handleCloseViewDetail}
      />
    );
  }
  if (viewdetail.paynow) {
    return (
      <PayNow
        staffPage={props?.staffPage}
        canAddCardOrBank={true}
        transachisid={transachisid}
        TransactionDate={viewdata?.TransactionDate}
        transid={viewdata?.TransactionId}
        UserMemberId={
          props?.userData?.MainUserId || props?.userData?.UserMemberId
        }
        handleCloseViewDetail={handleCloseViewDetail}
      />
    );
  }
  if (viewdetail.void) {
    return (
      <Void
        UserMemberId={props?.userData?.UserMemberId}
        transachisid={transachisid}
        staffPage={props?.staffPage}
        viewdata={viewdata}
        handleCloseViewDetail={handleCloseViewDetail}
      />
    );
  }

  return (
    <>
      <div className="payment-transaction-container">
        <h5 className="mb-3 bz_fs_1_5">
          {props?.fromCancel && (
            <button
              className="btn btn-primary bz-back-btn btn-sm"
              onClick={() => props?.handleClose()}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
          )}
          Payment Transactions
        </h5>
        {loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <>
            {tableData.length > 0 ? (
              <>
                <div className="bz-payment-transanction-grid-blk transactionspayments">
                  <Grid data={tableData} className="bzc-grid">
                    <Column
                      title="Client Name"
                      cell={(props) => {
                        return (
                          <td>
                            <div className="d-flex align-items-center justify-content-start">
                              <ClientNameWithMemberType
                                memberType={props?.dataItem?.MemberType}
                                clientName={props?.dataItem?.FullName}
                              />
                            </div>
                          </td>
                        );
                      }}
                      width={150}
                    />
                    <Column
                      field="TransactionId"
                      title="Transaction ID"
                      width={130}
                    />
                    <Column field="ServiceType" title="Name" width={150} />
                    <Column
                      field="TransactionDate"
                      title="Date"
                      width={100}
                      cell={DateCell}
                    />
                    <Column
                      field="AmountPaid"
                      title="Total"
                      cell={amountCell}
                      width={90}
                    />
                    <Column
                      field="PaymentModeDescription"
                      title="Status"
                      cell={paymentModeCell}
                      width={120}
                    />
                    <Column
                      field="CardDescription"
                      title="Payment Method"
                      cell={PaymentMethodCell}
                      width={140}
                    />
                    <Column
                      field="PaymentExeModeDescription"
                      title="Mode"
                      width={140}
                    />
                    <Column
                      field="Actions"
                      title="Actions"
                      cell={actionscell}
                      width={150}
                    />
                  </Grid>
                  <div className="row float-right mt-2" ref={showMoreRef}>
                    <div className="col-12 bz-services-showMore">
                      {maxPageNum > page.skip + 1 && (
                        <span
                          className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                          onClick={() => handlePageChange()}
                        >
                          Show More <i className="fas fa-caret-down"></i>
                        </span>
                      )}
                      {page.skip !== 0 && maxPageNum <= page.skip + 1 && (
                        <span
                          className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                          onClick={() => handlePageChange("less")}
                        >
                          Show less <i className="fas fa-caret-down"></i>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className={"bz-no-upcoming-appoinments text-muted"}>
                No Transactions{" "}
              </div>
            )}
          </>
        )}
      </div>
      {showDialog && ChargeBackModal()}
    </>
  );
};

export default withNotification(Transactions);
