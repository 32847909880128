import React, { useEffect, useRef } from "react";
import useState from 'react-usestateref';
import { useHistory } from "react-router-dom";
import { GetLocalStore, LocalStore } from "utils/storage";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import { CheckUserMemberStatus, CurrencyFormat } from "utils";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import Auth from "components/authcomponent/Auth";
import withNotification from "components/Hoc/withNotification";
import { Error, Hint } from "@progress/kendo-react-labels";
import { TenantService } from "services/tenant/index.service";
import Location, { LocationForBooking } from "components/Location/Location";
import { useSelector } from "react-redux";
import { CalculateOffer } from "utils/utils";
import { GenerateOfferLabel } from "components/AffiliateSubscriptions/AffiliateSubscriptions.view";
import { ComboBox, ComboBoxChangeEvent, DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import { PrivateServiceTypeEnum } from "utils/form-utils";
import moment from "moment";
import { PackageService } from "services/package/index.service";
import MemberSelection from "components/submemberseletion/MemberSelection";
import { useApiClient } from "services/axios-service-utils";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import copy from "copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";

const Service = (props: any) => {
  const showMoreRef = useRef<any>();
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const userConfiguration = useSelector((state: any) => state?.userConfiguration);
  const history = useHistory();
  const userDetails = GetLocalStore("userDetails");
  const [filterValue, setFilterval] = useState<any>(null);
  const isLoggedIn = userDetails ? true : false;
  const [mobileFilterDialog, setmobileFilterDialog] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [customDialog, setCustomDialog] = useState(false);
  const initialCustomService = {
    Name: "",
    NumberOfSessions: 0,
    DurationInMinutes: 0,
    Rate: 0,
    OriginalRate: 0,
    ServiceRateId: 0,
  };
  const [customService, setcustomService] = useState(initialCustomService);
  const [expandedItem, setExpandItem] = useState(null);
  const [customLoading, setCustomLoading] = useState(false);
  const [servicesData, setServicesData] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [orgservicesData, setorgServicesData, orgservicesDataRef] = useState<any>([]);
  const [checkedValues, setCheckedValues] = useState([0]);
  const [sortval, setSortVal] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [checked, setChecked] = useState({
    All: true,
    Paid: false,
    Unpaid: false,
    Complimentary: false,
  });
  const isStaffPage = props?.staffPage;
  const filterValues = [
    { text: "All", id: 0 },
    { text: "Paid", id: 1 },
    { text: "Unpaid", id: 2 },
    { text: "Complimentary", id: 3 },
  ];
  const domainName = process.env.REACT_APP_DOMAIN;
  const tenantDomainName = GetLocalStore("tenantUserSession")?.DomainName;
  const [PackagesData, setPackagesData, packagesDataRef] = useState<any>([]);
  const [selectedPackage, setSelectedPackage, selectedPackageRef] = useState<any>(null);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  //client details
  const [clientFormValues, setClientFormValues] = useState<any>(props?.userData || {});
  const [clientSubmitDetails, setClientSubmitDetails, clientSubmitDetailsRef] = useState<any>(props?.userData || {});
  const [ispackageLoading, setispackageLoading] = useState<any>(false);
  const [pagesize, setPagesize] = useState({
    pageNumber: 0,
    maxpageNumber: 0,
  })
  const { axiosRequest } = useApiClient();

  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [searchText, setSearchText, searchTextRef] = useState("");
  const [confirmClientDialog, setConfirmClientDialog] = useState(false);


  const service = new TenantService();
  useEffect(() => {
    fetchServices();
    if (props?.userData?.UserMemberId || isLoggedIn) {
      fetchAllSubmembers();
    }

  }, [props?.serviceShortName, props?.PromotionId]);



  async function fetchServices(PageNumber: Number = 0) {
    let userInfo = clientSubmitDetails
    if (
      Object.keys(clientSubmitDetails || {})
        .length === 0
    ) {
      if (props?.userData?.UserMemberId) {
        userInfo = props?.userData
      } else if (userConfiguration) {
        userInfo = {
          UserMemberId: userConfiguration?.userDetails?.UserSession?.UserMemberId,
          FirstName: userConfiguration?.userDetails?.UserSession?.FirstName,
          LastName: userConfiguration?.userDetails?.UserSession?.LastName,
          FullName: userConfiguration?.userDetails?.UserSession?.FullName,
        }
      }
    }

    const pageSize = 100;
    let req: any = { IncludeHostOnlineOnly: props?.staffPage ? false : true, PageNumber: PageNumber, PageSize: pageSize, Name: searchTextRef?.current };
    if (privateServiceTypeRef.current !== 0) {
      if (privateServiceTypeRef.current === 1) {
        req.PrivateServiceType = PrivateServiceTypeEnum.PrivatePaid
      } else if (privateServiceTypeRef.current === 2) {
        req.PrivateServiceType = PrivateServiceTypeEnum.SemiPrivatePaid
      }
    }
    if (props?.serviceShortName) {
      req.ShortCode = props?.serviceShortName;
      req.IncludeHostOnlineOnly = false;
    }
    if (props?.PromotionId) {
      req.PromotionId = props?.PromotionId;
    }
    if (props?.providerName) {
      req.ProviderId = props?.provider?.AssociateId
    }
    setIsServiceLoading(true);
    const servicedet = await axiosRequest.post('Appointments/Inventory', req);
    const result: any = servicedet.data;
    if (result) {
      if (PageNumber === 0) {
        // const test = orgservicesData.concat(servicedet.data.Items);
        setServicesData(servicedet.data.Items);
        setorgServicesData(servicedet.data.Items);
        setPagesize({
          pageNumber: 0,
          maxpageNumber: result?.TotalItems / pageSize,
        })

      }

    }
    const item = result?.Items?.[0]
    const serviceItem = {
      ...item,
      AffiliateId: props?.AffiliateDetails?.UserAssociateId || null,
      AffiliateUserId: props?.AffiliateDetails?.UserId || null,
      PromotionId: props?.PromotionId || null,
      CouponId: props?.offerObj?.CouponId || null,
      CouponDescription: props?.offerObj?.CouponCode || null,
    };
    setIsServiceLoading(false);
    if (result?.Items?.length === 1 && props?.serviceShortName) {
      if (isLoggedIn) {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/scheduleappointment`, {
            selectedService: serviceItem,
            serviceShortName: props?.serviceShortName,
            userData: userInfo,
            provider: props?.provider ? props?.provider : undefined
          });
        } else {
          history.push(`/scheduleappointment`, {
            selectedService: serviceItem,
            serviceShortName: props?.serviceShortName,
            userData: userInfo,
            provider: props?.provider ? props?.provider : undefined
          })
        }
      } else if (serviceItem?.PrivateServiceType !== PrivateServiceTypeEnum.PrivatePaid && serviceItem?.PrivateServiceType !== PrivateServiceTypeEnum.SemiPrivatePaid) {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/scheduleappointment`, {
            selectedService: serviceItem,
            serviceShortName: props?.serviceShortName,
            welcomePage: true,
            provider: props?.provider ? props?.provider : undefined
          });
        } else {
          history.push(`/scheduleappointment`, {
            selectedService: serviceItem,
            serviceShortName: props?.serviceShortName,
            welcomePage: true,
            provider: props?.provider ? props?.provider : undefined
          })
        }
      }

      return
    }
    return result?.Items || [];
  }



  const handleSearchAppt = (val: any) => {
    setSearchText(val);
    fetchServices();
  }

  const handleSelectService = (item: any) => {
    setConfirmClientDialog(false);
    let userInfo = clientSubmitDetails
    if (
      Object.keys(clientSubmitDetails || {})
        .length === 0
    ) {
      if (props?.userData) {
        userInfo = props?.userData
      } else if (userConfiguration) {
        userInfo = {
          UserMemberId: userConfiguration?.userDetails?.UserSession?.UserMemberId,
          FirstName: userConfiguration?.userDetails?.UserSession?.FirstName,
          LastName: userConfiguration?.userDetails?.UserSession?.LastName,
          FullName: userConfiguration?.userDetails?.UserSession?.FullName,
        }
      }
    }
    if (!props?.staffPage && !CheckUserMemberStatus(userInfo)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(userInfo)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    const serviceId = item.InternalDataId;
    const serviceItem = {
      ...item,
      AffiliateId: props?.AffiliateDetails?.UserAssociateId || null,
      AffiliateUserId: props?.AffiliateDetails?.UserId || null,
      PromotionId: props?.PromotionId || null,
      CouponId: props?.offerObj?.CouponId || null,
      CouponDescription: props?.offerObj?.CouponCode || null,
    };
    if (isLoggedIn) {
      if (props?.staffPage) {
        history.push(
          `/member/${props?.uuid}/${userInfo?.UserMemberId}/scheduleappointment`,
          {
            selectedService: serviceItem,
            staffPage: true,
            userData: userInfo,
            uuid: props?.uuid,
            selectedPackageInstanceId: selectedPackageRef?.current?.PackageInstanceId,
          }
        );
      } else {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/scheduleappointment`, {
            selectedService: serviceItem,
            userData: userInfo,
          });
        } else {
          history.push(`/scheduleappointment`, {
            selectedService: serviceItem,
            selectedPackageInstanceId: selectedPackageRef?.current?.PackageInstanceId,
            userData: userInfo,
          });
        }
      }
    } else {
      if (item.PrivateServiceType !== PrivateServiceTypeEnum.PrivatePaid && item.PrivateServiceType !== PrivateServiceTypeEnum.SemiPrivatePaid) {
        if (redirectionObj?.firstPathValue === "external") {
          history.push(`/${redirectionObj?.firstPathValue}/scheduleappointment`, {
            selectedService: serviceItem,
            userData: userInfo,
            welcomePage: true,
          });
        } else {
          history.push(`/scheduleappointment`, {
            selectedService: serviceItem,
            welcomePage: true,
            userData: userInfo
          });
        }
      } else {
        LocalStore("serviceItem", serviceItem);
        setLoginStatus(true);
      }
    }
  };




  const handleCloseLogin = () => {
    setLoginStatus(false);
  };


  useEffect(() => {
    sortFilter();
  }, [sortval]);

  useEffect(() => {
    filterServices();
  }, [checkedValues]);




  const sortFilter = () => {
    if (sortval) {
      //descending
      const sortData = servicesData.sort((a: any, b: any) =>
        a.Name.toLowerCase() > b.Name.toLowerCase() ? -1 : 1
      );
      setServicesData(sortData);
    } else {
      //ascending
      const sortData = servicesData.sort((a: any, b: any) =>
        a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1
      );
      setServicesData(sortData);
    }
    setPage({ skip: 0, take: 5 });
  };



  const filterServices = (serviceData = props.servicesData, flag = true) => {
    const filter = checkedValues.findIndex((item) => item === 0);
    if (filter !== -1) {
      if (flag) {
        handleSearch(filterValue, serviceData, false);
      } else {
        setServicesData(serviceData);
      }
    } else {
      const data = serviceData.filter((item: any) =>
        checkedValues.includes(item.PrivateServiceType)
      );
      if (flag) {
        handleSearch(filterValue, data, false);
      } else {
        setServicesData(data);
      }
    }
    setPage({ skip: 0, take: 5 });
  };


  const handleChangeCustomService = async (value: any, name: string) => {
    let val = value;
    if (
      name === "NumberOfSessions" ||
      name === "DurationInMinutes" ||
      name === "Rate"
    ) {
      val = Math.abs(value);
    }

    if (name === "NumberOfSessions" && val > 0) {
      const req = {
        ServiceTypeId: 2,
        NoOfServices: val,
        IsActive: true,
      };
      const service = new TenantService();
      setCustomLoading(true);
      const result = await service?.GetSessionCost(req);
      setCustomLoading(false);
      if (result?.ResponseCode === 100) {
        const dataItem = result?.Item;
        const rateOfAppt = dataItem?.Rate / val;
        setcustomService({
          ...customService,
          [name]: val,
          Rate: rateOfAppt,
          OriginalRate: rateOfAppt,
          ServiceRateId: dataItem?.ServiceRateId
        });
      }
    } else {
      setcustomService({
        ...customService,
        [name]: val,
      });
    }
  };

  const handleContinue = async () => {
    setSubmit(true);
    if (
      customService?.DurationInMinutes > 120 ||
      customService?.DurationInMinutes <= 0 ||
      customService?.NumberOfSessions <= 0 ||
      customService?.Rate <= 0 ||
      customService?.Name === ""
    ) {
      return;
    }
    const obj = {
      ...customService,
    };

    history.push(
      `/member/${props?.uuid}/${props?.userData?.UserMemberId}/checkout/${obj?.ServiceRateId}`,
      {
        staffPage: true,
        userData: props?.userData,
        uuid: props?.uuid,
        customService: obj,
      }
    );
    handleCustomEvent();
  };

  const [page, setPage] = React.useState({
    skip: 0,
    take: 5,
  });
  const handlePageChange = (status = "more") => {
    if (status === "less") {
      setPage({
        skip: 0,
        take: 5,
      });
    } else {
      setPage({
        take: page.take + 5,
        skip: 0,
      });
    }
    if (showMoreRef.current) {
      showMoreRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { take } = page;
  const handleChange = (e: any, name: number, value: string) => {
    setChecked({ ...checked, [value]: !e });
    const data = [...checkedValues];
    const test = checkedValues.findIndex((item) => item === name);
    if (name !== 0) {
      const filter = checkedValues.findIndex((item) => item === 0);
      if (filter !== -1) {
        data.splice(filter, 1);
      }
      if (e) {
        if (test === -1) {
          data.push(name);
        }
      } else {
        if (test !== -1) {
          data.splice(test, 1);
        }
      }
      if (data.length === 0) {
        setCheckedValues([0]);
      } else {
        setCheckedValues(data);
      }
    } else {
      setCheckedValues([0]);
    }
  };

  const handleExpand = (item: any) => {
    setExpandItem(item.PrivateServiceId);
  };
  const handleCollapse = () => {
    setExpandItem(null);
  };
  const handleCopyClick = (e: any, item: any) => {
    e.stopPropagation()
    const text = `https://${tenantDomainName}.${domainName}.com/${item?.ShortCode}`;
    copy(text);
    props?.handleNotificationMessage("Copied!", "success");
  };

  const MyItemRender = (props: any, data: any) => {
    let item = props.dataItem;
    let collpaseStatus = false;
    if (expandedItem === item.PrivateServiceId) {
      collpaseStatus = true;
    }

    return (
      <div>
        <div className="card mb-3 pointer appthover" onClick={() => {
          if (AllClientsList?.length > 1) {
            setSelectedItem(item)
            toggleClientConfirmDialog()
          } else {
            setSelectedItem(null)
            handleSelectService(item)
          }
        }}>
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <div>
                  <h5 className="card-title">{item.Name}{" "}
                    {isStaffPage && <MdContentCopy
                      color="#008db9"
                      className="cursor-pointer ml-1"
                      onClick={(e: any) => handleCopyClick(e, item)}
                      title={"Copy"}
                    />}
                  </h5>


                </div>


                <div className="bz-name appointments d-flex align-items-center">
                  {(item.PrivateServiceType === PrivateServiceTypeEnum.PrivatePaid || item.PrivateServiceType === PrivateServiceTypeEnum.PrivateUnpaid) && "Private"}
                  {(item.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivatePaid || item.PrivateServiceType === PrivateServiceTypeEnum.SemiPrivateUnpaid) && "Semi-Private"}

                  <span className="location-icon">
                    {item?.Attributes !== "" && (

                      <Location

                        Attributes={item?.Attributes}
                        showDetails={isStaffPage}

                        VirtualMeetingLink={item?.VirtualMeetingLink}

                        userDetails={props?.userDetails}

                        clientPhnNum={props?.userDetails?.Phone || ""}

                      />
                    )}


                  </span>

                </div>

                {/* <div className="text-muted d-flex align-items-center location-blk-text calendardesktop">
                  {item?.Attributes !== "" && (
                    <LocationForBooking
                      Attributes={item?.Attributes}
                      showDetails={isStaffPage}
                      showToolTip={isStaffPage}
                      VirtualMeetingLink={""}
                      userDetails={props?.userDetails}
                      clientPhnNum={props?.userDetails?.Phone}
                    />
                  )}
                </div> */}

              </div>
              <div>
                <div className="float-right text-right">
                  {item.DurationInMinutes} mins
                  <br />
                  {/* <span className="rateappts">{CurrencyFormat(item.Rate)}</span> */}
                  <div className="bz-price">
                    <span className="mb-0 rateappts"><span className="ratelabel"></span>
                      {data?.AffiliateDetails && data?.offerObj ? (<>
                        <del>
                          {CurrencyFormat(item?.Rate)}
                        </del>
                        <span className="rateappts" style={{ color: 'green' }}>{` ${CurrencyFormat(CalculateOffer(item?.Rate, data?.offerObj))}`}</span>
                      </>) : (<>
                        {CurrencyFormat(item?.Rate)}
                      </>)}
                    </span>
                    {data?.offerObj && <div> <GenerateOfferLabel offerObj={data?.offerObj} /></div>}

                  </div>
                </div>
              </div>
            </div>
            <div className="typeapptslocation d-none">

              <div className="text-muted d-flex align-items-center location-blk-text calendardesktop">

                <div className="bz-name">
                  <span className="bz-availability-appointments">
                    {item.Description !== "" && (
                      <>
                        {collpaseStatus ? (
                          <span
                            className="align-baseline bz-cursor-pointer p-0"
                            onClick={(e) => handleCollapse()}
                          >
                            View Details <i className="fas fa-angle-up"></i>
                          </span>
                        ) : (
                          <span
                            className="bz-cursor-pointer  p-0"
                            onClick={(e) => handleExpand(item)}
                          >
                            View details <i className="fas fa-angle-down"></i>
                          </span>
                        )}
                      </>
                    )}
                  </span>
                </div>

              </div>
              <h6 className="mb-0">  </h6>

            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderServiceSearchMobile = () => {
    return (
      <>
        <div className="bzo-leftside-filter-container">
          <div>
            <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(0)}
                name="all"
                label={"All"}
                onChange={(e) => handleChange(e.value, 0, "")}
              />
            </div>
            <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(1)}
                label={"Paid"}
                name={"paid"}
                onChange={(e) => handleChange(e.value, 1, "")}
              />
            </div>

            <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(2)}
                label={"Unpaid"}
                name={"Unpaid"}
                onChange={(e) => handleChange(e.value, 2, "")}
              />
            </div>
            {/* <div className="form-check">
              <Checkbox
                checked={checkedValues.includes(3)}
                label={"Complimentary"}
                name={"complimentary"}
                onChange={(e) => handleChange(e.value, 3, "")}
              />
            </div> */}
          </div>
          <Button
            primary={true}
            className={"float-right mt-3"}
            onClick={() => setmobileFilterDialog(!mobileFilterDialog)}
          >
            Okay
          </Button>
        </div>

      </>

    );
  };

  const renderCustomService = () => {
    return (
      <div className="">
        <div style={{ width: "100%" }} className="bz-add-bank">
          <div className="">
            <div className="row pb-2">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Input
                  id={"Name"}
                  name={"Name"}
                  value={customService?.Name}
                  placeholder={"Name of the Custom Service"}
                  label={"Name of the Custom Service"}
                  style={{ width: "100%" }}
                  onChange={(e: any) =>
                    handleChangeCustomService(e.value, "Name")
                  }
                />
                {submit && customService?.Name === "" && (
                  <Error>{"Please enter One-on-one name"}</Error>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <NumericTextBox
                  min={0}
                  id={"NumberOfSessions"}
                  name={"NumberOfSessions"}
                  placeholder={"No. of Appointments"}
                  className="k-dropdown-width-100"
                  label={"No. of Appointments"}
                  value={customService?.NumberOfSessions}
                  width={"100%"}
                  onChange={(e: any) =>
                    handleChangeCustomService(
                      e.target.value,
                      "NumberOfSessions"
                    )
                  }
                />
                {submit && customService?.NumberOfSessions <= 0 && (
                  <Error>{"Please enter minimum one Appointment"}</Error>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <NumericTextBox
                  min={0}
                  max={120}
                  width={"100%"}
                  value={customService?.DurationInMinutes}
                  id={"DurationInMinutes"}
                  name={"DurationInMinutes"}
                  placeholder={"Duration"}
                  className="k-dropdown-width-100"
                  label={"Duration"}
                  onChange={(e: any) =>
                    handleChangeCustomService(
                      e.target.value,
                      "DurationInMinutes"
                    )
                  }
                />
                <Hint>
                  <span className="form-text text-muted">
                    Max duration is 120 minutes
                  </span>
                </Hint>
                {submit &&
                  (customService?.DurationInMinutes <= 0 ||
                    customService?.DurationInMinutes > 120) && (
                    <Error>{"Please enter valid Duration"}</Error>
                  )}
              </div>
            </div>
            <div className="row">
              {customLoading ? (
                <BuzopsLoader type="list" />
              ) : (
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <NumericTextBox
                    min={0}
                    id={"Rate"}
                    name={"Rate"}
                    placeholder={"Per Appointment Rate"}
                    className="k-dropdown-width-100"
                    label={"Per Appointment Rate"}
                    value={customService?.Rate}
                    width={"100%"}
                    format={"c2"}
                    onChange={(e: any) =>
                      handleChangeCustomService(e.target.value, "Rate")
                    }
                  />
                  {customService?.NumberOfSessions > 0 ? (
                    <Hint>
                      <span className="form-text text-muted">
                        {`Estimated Per Appointment Rate is ${CurrencyFormat(
                          customService?.OriginalRate
                        )}`}
                      </span>
                    </Hint>
                  ) : null}
                  {submit && customService?.Rate <= 0 && (
                    <Error>{"Please enter Per Appointment Rate"}</Error>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex pt-2">
            <Button
              type={"reset"}
              onClick={() => handleCustomEvent()}
              className="ml-0"
            >
              Cancel
            </Button>
            <BuzopsButton
              primary
              label={"Continue"}
              type={"submit"}
              onClick={() => handleContinue()}
            />
          </div>
        </div>
      </div>
    );
  };
  const handleCustomEvent = () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setCustomDialog(!customDialog);
    setSubmit(false);
    setcustomService(initialCustomService);
  };

  const filterServiceComponent = () => {
    return (
      <div className="bzo-leftside-filter-container-new py-3 px-0 card">
        <div className="bz-check-box-filter-new">
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.All ? "#000" : "#ff7a0e"}` }}
            onClick={(e) => handleChange(checked.All, 0, "All")}
          >
            All
          </button>
          <br />
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.Paid ? "#000" : "#ff7a0e"}` }}
            onClick={(e) => handleChange(checked.Paid, 1, "Paid")}
          >
            Paid
          </button>
          <br />
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.Unpaid ? "#000" : "#ff7a0e"}` }}
            onClick={(e) => handleChange(checked.Unpaid, 2, "Unpaid")}
          >
            Unpaid
          </button>
          <br />
          <button
            className="btn btn-link px-3"
            style={{ color: `${checked.Complimentary ? "#000" : "#ff7a0e"}` }}
            onClick={(e) =>
              handleChange(checked.Complimentary, 3, "Complimentary")
            }
          >
            Complimentary
          </button>
        </div>
      </div>
    );
  };
  const requestIfNeeded = () => {
    const nextPage = pagesize.pageNumber + 1;
    const maxPageNum = pagesize.maxpageNumber;

    if (nextPage < maxPageNum) {
      return {
        status: true,
        page: nextPage,
      };
    } else {
      return {
        status: false,
        page: nextPage,
      };
    }
  };

  const scrollHandler = async (event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const check = await requestIfNeeded();
      if (check.status) {
        const moreData: any = await fetchServices(check.page);
        if (moreData.length > 0) {
          const test = orgservicesData.concat(moreData);
          setServicesData(test);
          setorgServicesData(test);
          setPagesize({
            ...pagesize,
            pageNumber: check.page,
          })
        }
      }
    }
  };

  const footerAdjustmentClass = servicesData?.length <= 8 ? "bz-footer-adjustment" : "";
  const renderServiceList = () => {


    if (isServiceLoading) {
      return <BuzopsLoader type={"list"} />;
    } else if (servicesData?.length === 0) {
      return (
        <>
          <div className={"bz-no-upcoming-appoinments text-muted"}>
            No Appointments Available{" "}
          </div>
        </>
      );
    }
    return (<>

      <ListView
        //className="serviceListclass"
        data={servicesData}
        item={(e) => MyItemRender(e, props)}
        onScroll={(e) => scrollHandler(e)}
      />
      {/* <div className="d-flex justify-content-center align-items-center bz-services-showMore">
              <div ref={showMoreRef}>
                {servicesData.length > take && (
                  <span
                    className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                    onClick={() => handlePageChange()}
                  >
                    View More <i className="fas fa-caret-down"></i>
                  </span>
                )}
                {servicesData.length <= take && servicesData.length > 5 && (
                  <span
                    className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                    onClick={() => handlePageChange("less")}
                  >
                    View Less <i className="fas fa-caret-up"></i>
                  </span>
                )}
              </div>
            </div> */}
    </>
    );
  };

  const [privateServiceType, setPrivateServiceType, privateServiceTypeRef] = useState(0);

  const showServicesComponent = () => {
    const serviceList = [
      { Name: "Private", Id: 1 },
      { Name: "Semi-Private", Id: 2 },
    ];

    const handleFilter = (value: any) => {
      setPrivateServiceType(value.Id);
      fetchServices();
    }



    return (
      <div className={`bzo-services-container mt-0 ${footerAdjustmentClass}`}>
        <div className="d-flex justify-content-between w-100">
          <div className="w-100">
            <div className="d-flex bz-purchase-class-filter mb-3 mt-2 clientpurchase">
              {isLoggedIn && clientSubmitDetails?.UserMemberId && (
                <>
                  {!props?.selectedPackage && !props?.PromotionId && (
                    <div className="row packeandclient" >
                      <div className="col-sm-6 col-xl-6 order-2 order-xl-1 mb-0  packageselection">
                        {/* <label>Package:</label> */}
                        {PackageListDropDown()}
                      </div>
                      {/* {
                selectedPackage &&
                <div className="col-sm-12 col-xl-4 order-1 order-xl-2 text-left text-xl-center" style={{alignSelf: "center"}}>
                  <br className="d-none d-xl-inline"/>
                  <div>
                  <label>Enrollment Indicator:</label> &nbsp;
                    {`${selectedPackage?.SessionType === 2 ? "Unlimited" : `${(selectedPackage?.SchedulesToUse)}/${selectedPackage?.SchedulesToUse + selectedPackage?.SchedulesUsed} left`} `}
                  </div>
                </div>
              } */}
                      <div className="col-sm-6 col-xl-6 order-3 order-xl-3 mb-0 mt-3 mt-sm-0">
                        {/* <label>Purchase/Enrollment For:</label> */}
                        <div className="mr-sm-3">
                          <MemberSelection
                            allClientsList={AllClientsList}
                            selectedUsermemberId={clientSubmitDetails?.UserMemberId}
                            handleUpdateClient={(val: any) => handleClientSelection(val)}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                </>
              )}
              {!props?.serviceShortName &&
                !selectedPackage &&
                (
                  <div className="d-flex appttypesearchclient">

                    <div className="mb-3 mb-sm-0 mr-sm-3">
                      <DropDownList
                        data={serviceList}
                        textField="Name"
                        dataItemKey="Id"
                        id="class"
                        name="class"
                        onChange={(e) => handleFilter(e.target.value)}
                        defaultItem={{ Name: "All", Id: 0 }}
                      />
                    </div>
                    <div className="form-group bz-packages-search bzo-search-service">
                      <span className="fa fa-search form-control-feedback"></span>
                      <input
                        type={"search"}
                        className="form-control"
                        placeholder={"Find"}
                        onChange={(e) => handleSearchAppt(e.target.value)}
                        value={searchText}
                      />
                    </div>
                  </div>
                )}
            </div>

          </div>

        </div>
        {
          selectedPackage && !props?.PromotionId &&
          <div>
            {showPackageMessage()}

          </div>
        }


        {renderServiceList()}
      </div>
    );
  };

  const handleSearch = (
    value: string,
    serviceData = props.servicesData,
    flag = true
  ) => {
    setFilterval(value);
    if (value) {
      const results = serviceData.filter((item: any) =>
        item.Name.toLowerCase().includes(value.toLowerCase())
      );

      if (flag) {
        filterServices(results, false);
      } else {
        setServicesData(results);
      }
    } else {
      if (flag) {
        filterServices(serviceData, false);
      } else {
        setServicesData(serviceData);
      }
    }
  };

  ;

  const fetchAllSubmembers = () => {
    let memberId;
    memberId = props?.userData?.MainUserId || props?.userData?.UserMemberId || userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId;
    if (memberId) {
      getAllSubMembers(memberId);
    }
  };

  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return (
      allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
    );
  };

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllClientsList(res);
    let Id = props?.userData?.UserMemberId || memberId;
    if (!props.staffPage && userDetails) {
      Id = parseInt(
        userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId ||
        memberId
      );
    }

    updateSelectedClientData(getCurrentClientData(res, Id));

  };

  async function updateSelectedClientData(userData: any) {
    const result = userData;
    if (result) {
      const clientIntialValues = {
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PhoneNumber: result?.Phone,
        Email: result?.Email,
        UserMemberId: result?.UserMemberId,
      };
      const clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        Photo: result?.Photo,
        LastName: result?.LastName,
        FullName: result?.FullName,
        PrimaryMemberId: result?.ParentId || result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        MemberType: result?.MemberType,
        Status: result?.UserMemberStatus || result?.Status,
      };
      setClientFormValues(clientIntialValues);
      setClientSubmitDetails(clientSubmitDetails);
      if (!props?.PromotionId) {
        fetchPackageSummary(true);
      }
    }
  }

  const fetchPackageSummary = async (init = false) => {
    let memberId;
    if (clientSubmitDetailsRef?.current?.UserMemberId) {
      memberId = clientSubmitDetailsRef?.current?.UserMemberId;
    } else {
      memberId = props?.userData?.UserMemberId;
    }
    let startDate = moment().format("YYYY-MM-DD") + "T00:00:00";
    let ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";

    const req = {
      UserMemberId: memberId,
      FromDate: startDate,
      ToDate,
      PackageType: 2 | 4,

    };
    if (memberId) {
      setispackageLoading(true);
      const service = new PackageService();
      const res = await service.packageInstanceToUse(req);
      const result = res?.filter((i: any) => i?.SessionType !== 3)
      setPackagesData(result || []);
      if (init) {
        setSelectedPackageData();
        fetchPackagerelatedservices();
      }
      setispackageLoading(false);
    }
  };

  async function fetchPackagerelatedservices(SelectedPackageData = selectedPackageRef?.current) {
    const filteredData = orgservicesDataRef?.current.filter((service: any) =>
      SelectedPackageData?.ClassIds.includes(service.PrivateServiceId)
    );
    if (filteredData.length > 0) {
      setServicesData(filteredData);
    } else if (selectedPackageRef?.current !== null) {
      setServicesData([]);
    } else {
      setServicesData(orgservicesDataRef?.current);
    }

  }

  const packageItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const item = itemProps.dataItem;
    const schedulesToUse =
      item.SessionType === 2
        ? "Unlimited"
        : `${item?.SchedulesUsed} of ${item?.SchedulesToUse + item?.SchedulesUsed
        }`;
    const itemChildren = (
      <span>
        <div className="package-item">
          <div className="package-details bz-payment-options">
            <div className="package-name">
              <span className="primary-heading">{item?.Name}</span>
            </div>
            <div className="package-expiry-schedules">
              <small className="package-schedules ">Redeemed: {`${schedulesToUse}`}</small>
              <br />
              {/* <span> {"|"}</span> */}
              <small className="package-expiry">
                {`${item?.ExpireFrom === null
                  ? "N/A"
                  : moment(item?.ExpireFrom).format("L")
                  } to ${item?.ExpireOn === null
                    ? "N/A"
                    : moment(item?.ExpireOn).format("L")
                  }`}
              </small>
            </div>
          </div>
        </div>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };
  const handlePackageChange = (e: ComboBoxChangeEvent) => {
    if (!e.target.value) {
      setSelectedPackage(undefined);
      setServicesData(orgservicesData);

    } else {
      setSelectedPackage(e.target.value);
      fetchPackagerelatedservices(e.target.value);
    }
  };

  const PackageListDropDown = () => {
    return (
      <ComboBox
        data={PackagesData}
        dataItemKey="PackageInstanceId"
        textField="Name"
        filterable={true}
        placeholder="Filter by package"
        itemRender={packageItemRender}
        style={{ width: "100%" }}
        onChange={handlePackageChange}
        value={selectedPackage}
      />
    );
  };

  const handleClientSelection = (SelectedUser: any) => {
    updateSelectedClientData(SelectedUser);
  };

  const setSelectedPackageData = () => {
    if (selectedPackageRef?.current) {
      const data = packagesDataRef?.current.find((p: any) => p.PackageInstanceId === selectedPackageRef?.current?.PackageInstanceId);
      setSelectedPackage(data);
    } else {
      const data = CheckExactPackageItem(packagesDataRef?.current);
      setSelectedPackage(data);
    }


  }

  const CheckExactPackageItem = (
    packageList: any
  ) => {
    const UnlimitedPackages = packageList.filter((i: any) => i?.SessionType === 2);
    const LimitedPackages = packageList.filter((i: any) => i?.SessionType === 1 && i?.SchedulesToUse > 0);

    if (UnlimitedPackages?.length === 1) {
      return UnlimitedPackages[0];
    }
    if (UnlimitedPackages?.length > 1) {
      const selectedMinExpireOnPackage = UnlimitedPackages.reduce(
        (min: any, item: any) => {
          return new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min;
        },
        UnlimitedPackages[0]
      );
      return selectedMinExpireOnPackage;
    }

    if (LimitedPackages?.length > 0) {
      const selectedMinExpireOnPackage = LimitedPackages.reduce(
        (min: any, item: any) => {
          return new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min;
        },
        LimitedPackages[0]
      );
      return selectedMinExpireOnPackage;
    }
    return null;
  };

  const showPackageMessage = () => {
    return <div className="alert alert-warning fade show mt-0 purshasenotifcation"
      role="alert">
      {props?.selectedPackage ? <strong>Note: Only appointments from your purchased package are shown.</strong> : <strong>Note: Only appointments from your purchased package are shown. Deselect the package to view all.</strong>}
    </div>
  }

  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };

  const renderClientConfirmationDialog = () => {
    const selectedClientInfo = AllClientsList?.filter(
      (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
    )[0]
    return (
      <Dialog
        className="bz-book-Appoinment-dialog"
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}
      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
            <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton
              label={"Yes"}
              onClick={() =>
                handleSelectService(selectedItem)
              }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      {props?.serviceShortName && props?.serviceShortName !== null && (
        <>
          <div className="row">
            <div className={"d-flex justify-content-start mt-3"}>
              <h5 className="card-title bzo-primary-heading mb-0">
                Appointments
              </h5>
            </div>
          </div>

        </>
      )}

      {/* {!props?.serviceShortName && (
        <div className="row">
          <div className="col-xl-12 mb-2 mb-lg-0">
            {props?.staffPage && (
              <Button primary onClick={() => handleCustomEvent()}>
                Select Custom
              </Button>
            )}
          </div>
        </div>
      )} */}
      {/* {isServiceLoading  ? (
        <BuzopsLoader type={"list"} />
      ) : servicesData.length === 0 ? (
        <div className={"bzo-services-container border-0 text-muted"}>
          No Available Appointments{" "}
        </div>
      ) : (
        <> */}
      {/* <div className="row">
            <div className="form-group bzo-search-service bzo-hide-service-search col-12">
              <div>
              <span className="fa fa-search form-control-feedback"></span>
                <input
                  className="form-control"
                  placeholder={'search service'}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
          </div> */}
      <div className="row">
        {!props?.serviceShortName && (
          <>
            {/* <div className="col-xl-3">{filterServiceComponent()}</div> */}
            <div className="col-xl-12 mb-2 mb-lg-0"></div>
            <div className="col-xl-12">{showServicesComponent()}</div>
          </>
        )}
        {props?.serviceShortName &&
          (props?.ShortCodeStatus || props?.PromotionId) ? (
          <div className="col-xl-12 col-xxl-12">
            {showServicesComponent()}
          </div>
        ) : null}
        {props?.serviceShortName &&
          !props?.ShortCodeStatus &&
          !props?.PromotionId ? (
          <div className={"bzo-services-container border-0 text-muted"}>
            The requested subscription is no longer accessible to use.
          </div>
        ) : null}
        {/* {!props?.serviceShortName && (
              <div className="bzo-services-filter-mobile">
                <div className="bzo-sort" onClick={() => setSortVal(!sortval)}>
                  <i className="fas fa-sort"></i>
                  Sort&nbsp;&nbsp;
                </div>
                <div
                  className="bzo-filters"
                  onClick={() => setmobileFilterDialog(!mobileFilterDialog)}
                >
                  <i className="fas fa-filter"></i>
                  Filter
                </div>
              </div>
            )} */}

        {mobileFilterDialog && (
          <Dialog
            className="bzo-filter-dialog"
            title={"Filter Services"}
            width={340}
            onClose={() => setmobileFilterDialog(!mobileFilterDialog)}
          >
            {renderServiceSearchMobile()}
          </Dialog>
        )}

        <Auth
          {...props}
          handleCloseLogin={() => handleCloseLogin()}
          showComponent={loginStatus}
          key={loginStatus.toString()}
        />
      </div>
      {/* </>
      )} */}
      {customDialog && (
        <Dialog
          className="bz-book-Appoinment-dialog"
          title={"Create Custom One-on-one"}
          onClose={() => handleCustomEvent()}
          width={"35%"}
        >
          {renderCustomService()}
        </Dialog>
      )}
      {confirmClientDialog && renderClientConfirmationDialog()}

    </>
  );
};

export default withNotification(Service);
