import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import withNotification from "components/Hoc/withNotification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { initialAdditionalPayment } from "components/checkout/Checkout";
import BuzopsButton from "generic-components/BuzopsButton";
import React, { useState } from "react";
import {  AdditionPaymentCreditMessage, checkIfUserAddCreditCard, paymentDropdownItems } from "utils";

const AdditionalPayment = (props: any) => {
  const {
    fetchPaymentProfiles,
    userMemberId,
    setShowAdditionalPayment,
    paymentProfile,
    showAdditionalPayment,
    handleChangeAdditionalPaymentProfile,
    handleAddAdditionalPayment,
    PurchaseAmount,
    CreditAmount
  } = props;
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };
  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    const res= await fetchPaymentProfiles(userMemberId,true);
    if(res[res?.length-1]){
      setShowAdditionalPayment(initialAdditionalPayment)
      setShowAdditionalPayment({
        ...showAdditionalPayment,selectedPaymentProfile:res[res?.length-1]
      })
    }
  };
  const checkPPExists=paymentProfile?.filter((i:any)=> i?.CardTypeId !== -1 && i.CardTypeId !==8)?.length > 0 ? true : false;
  return (
    <>
      <Dialog
        className="bz-addCard-dialog bz-plan-cancellation-dialog"
        // title={"Payment Profiles"}
        onClose={() => setShowAdditionalPayment(initialAdditionalPayment)}
        width={"30rem"}
      >
        {showPaymentDialog ? (
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={userMemberId}
          />
        ) : (
          <>
            <div
                className="alert alert-danger fade show"
                role="alert"
                style={{ width: "100%" }}
              >
                {AdditionPaymentCreditMessage(checkPPExists,CreditAmount,PurchaseAmount)}
              </div>
            <h6 className="bz-fs-14px bz-fw-6">Payment Profile</h6>
            <div className="d-flex bz-payment-profile-dropdown">
              <div>
                <DropDownList
                  id={`PaymentGatewayPaymentProfileId`}
                  name={`PaymentGatewayPaymentProfileId`}
                  data={paymentProfile?.filter((i: any) => i.CardTypeId !== 8)}
                  textField="MaskedCCNumber"
                  valueRender={(
                    el: React.ReactElement<HTMLSpanElement>,
                    value: any
                  ) =>
                    PaymentValueRender(
                      el,
                      value,
                      showAdditionalPayment.selectedPaymentProfile
                    )
                  }
                  itemRender={PaymentItemRender}
                  onChange={(e) => {
                    handleChangeAdditionalPaymentProfile(e.value);
                  }}
                />
              </div>
              {checkIfUserAddCreditCard() && <div className="addNewBtn">
                <DropDownButton
                  text="Add New"
                  onItemClick={(e: any) => onCardOrBankAdd(e)}
                  items={paymentDropdownItems()?.filter(
                    (i: any) => i?.visible === true && i?.key !== "addOther"
                  )}
                  look="flat"
                  className="btn btn-link add-new-btn"
                />
              </div>}
            </div>
            <div className="d-flex justify-content-end pt-3">
              <Button
                primary={false}
                onClick={() =>
                  setShowAdditionalPayment(initialAdditionalPayment)
                }
              >
                Cancel
              </Button>
              <BuzopsButton
                label={"Continue"}
                onClick={() => handleAddAdditionalPayment()}
                className="mr-0"
              />
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

export default  withNotification(AdditionalPayment)
